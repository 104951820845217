import SyncIcon from "@mui/icons-material/Sync";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import React, { useState } from "react";
import { firebase } from "services/firebase";

export default function UserManagementDashboard() {
  const [loading, setLoading] = useState(false);

  function syncAzureADButton() {
    if (loading) {
      return <LinearProgress />;
    } else {
      return (
        <Button
          variant="contained"
          color="primary"
          startIcon={<SyncIcon />}
          onClick={() => callSyncUserClaims()}
        >
          Sync Azure AD Permission
        </Button>
      );
    }
  }

  return (
    <React.Fragment>
      <Paper style={{ padding: 20 }}>
        <Box p={2}>{syncAzureADButton()}</Box>
      </Paper>
    </React.Fragment>
  );

  async function callSyncUserClaims() {
    setLoading(true);

    var callSyncUserClaims = firebase
      .functions()
      .httpsCallable("syncUserClaims");

    await callSyncUserClaims();

    setLoading(false);
  }
}
