import ComputerIcon from "@mui/icons-material/Computer";
import EmailIcon from "@mui/icons-material/Email";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListIcon from "@mui/icons-material/List";
import PeopleIcon from "@mui/icons-material/People";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import UpdateIcon from "@mui/icons-material/Update";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import r from "routes";
import { storage } from "services/firebase";
import { isOfficeIP } from "services/ip";
import { versions } from "versions";

export default function GeneralTools({ handleRedirect, handleAlert, classes }) {
  const history = useHistory();
  const [useIsAtOffice, setUseIsAtOffice] = useState();

  useEffect(() => {
    async function fetchRDP() {
      const isOffice = await isOfficeIP();
      setUseIsAtOffice(isOffice);
    }

    fetchRDP();
  }, []);

  function handleStaffListClick() {
    window.location.href =
      "https://docs.google.com/spreadsheets/d/1AzCh5K7pLvcdsx4PVFckpS_sJEGyDKH7CRUytGIbDwY/edit#gid=0";
  }

  function handleEmailSignatureGeneratorClick() {
    history.push(r.emailSignatureGenerator.path);
    handleRedirect();
  }

  function handleQuickUpdate() {
    history.push(r.defaultQuickUpdate.path);
    handleRedirect();
  }

  async function handlePDFProcedureOpen() {
    var storageRef = storage.ref();
    var pdfRef = storageRef.child("pdf/How to connect to DMS System.pdf");

    const url = await pdfRef.getDownloadURL();
    window.open(url);
  }

  function getRDP() {
    if (useIsAtOffice) {
      return (
        <ListItem
          button
          name={"RDP"}
          onClick={() => (window.location.href = "https://10.203.17.26/rdweb")}
          className={classes.nested}
        >
          <ListItemIcon>
            <ComputerIcon />
          </ListItemIcon>
          <ListItemText
            primary={"RDP - Internal Access"}
            secondary={"Remote Desktop Protocol"}
          />
        </ListItem>
      );
    } else {
      return (
        <ListItem
          button
          name={"RDP"}
          onClick={() =>
            (window.location.href = "https://mail.torontoautogroup.ca/rdweb")
          }
          className={classes.nested}
        >
          <ListItemIcon>
            <ComputerIcon />
          </ListItemIcon>
          <ListItemText
            primary={"RDP - Remote Access"}
            secondary={"Remote Desktop Protocol"}
          />
        </ListItem>
      );
    }
  }

  return (
    <Grid item align="center" xs={12}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <PeopleIcon />
          <Typography className={classes.heading}>General</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List component="div" disablePadding className={classes.list}>
            <ListItem
              button
              onClick={handleQuickUpdate}
              name={"Quick update (" + versions["quick-update"].version + ")"}
              className={classes.nested}
            >
              <ListItemIcon>
                <UpdateIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Quick update"}
                secondary={versions["quick-update"].version}
              />
            </ListItem>

            <ListItem
              button
              name={"Staff List (1.0.0)"}
              onClick={handleStaffListClick}
              className={classes.nested}
            >
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary={"Staff List"} secondary={"1.0.0"} />
            </ListItem>

            <ListItem
              button
              name={
                "Email Signature Generator (" +
                versions["email-generator"].version +
                ")"
              }
              onClick={handleEmailSignatureGeneratorClick}
              className={classes.nested}
            >
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Email Signature Generator "}
                secondary={versions["email-generator"].version}
              />
            </ListItem>

            {getRDP()}

            <ListItem
              button
              name={"RDP - Complete connection procedure"}
              onClick={handlePDFProcedureOpen}
              className={classes.nested}
            >
              <ListItemIcon>
                <PictureAsPdfIcon />
              </ListItemIcon>
              <ListItemText
                primary={"RDP - Complete connection procedure"}
                secondary={"Open PDF"}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
