import {
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  Typography,
} from "@mui/material";
import ModalButton from "common/ModalButton";
import { FastField } from "formik";
import { RadioGroup, TextField } from "formik-mui";
import React from "react";

import {
  destinationConstants,
  timelineConstants,
  unitConditionConstants,
} from "../constants.js";
import InventoryMovementRequestDashboard from "../InventoryMovementRequestDashboard/";

export default function InventoryMovementRequestForm({
  setFieldValue,
  isSubmitting,
}) {
  return (
    <React.Fragment>
      <Grid item align="left" xs={12}>
        <Typography variant="h6">Inventory</Typography>
        <Divider />
      </Grid>

      <Grid item align="center" sm={12} xs={12}>
        <ModalButton
          buttonText="Pre-fill with available data"
          title="Inventory Request Form"
        >
          {(handleClose) => (
            <InventoryMovementRequestDashboard
              handleClose={handleClose}
              setFieldValue={setFieldValue}
            />
          )}
        </ModalButton>
      </Grid>

      <Grid item xs={12} sm={12}>
        <FastField
          fullWidth
          required
          component={TextField}
          name="vin"
          label="VIN"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          component={TextField}
          name="stock"
          label="Stock"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          component={TextField}
          name="make"
          label="Make"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          component={TextField}
          name="model"
          label="Model"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          component={TextField}
          name="color"
          label="Color"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          component={TextField}
          name="year"
          label="Year"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          component={TextField}
          name="status"
          label="Status"
          variant="outlined"
        />
      </Grid>

      <Grid item align="left" xs={12}>
        <Typography variant="h6">Request</Typography>
        <Divider />
      </Grid>

      <Grid item xs={12} sm={12}>
        <FastField
          fullWidth
          component={TextField}
          name="location"
          label="Location"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={6} align="left">
        <InputLabel>Destination</InputLabel>
        <FastField
          required
          component={RadioGroup}
          name="destination"
          label="Destination"
          variant="outlined"
        >
          {destinationConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.value}
              disabled={isSubmitting}
              control={<Radio />}
            />
          ))}
        </FastField>
      </Grid>

      <Grid item xs={12} sm={6} align="left">
        <InputLabel>Timeline</InputLabel>
        <FastField
          required
          component={RadioGroup}
          name="timeline"
          label="Timeline"
          variant="outlined"
        >
          {timelineConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.value}
              disabled={isSubmitting}
              control={<Radio />}
            />
          ))}
        </FastField>
      </Grid>

      <Grid item xs={12} sm={6} align="left">
        <InputLabel>Unit condition</InputLabel>
        <FastField
          required
          component={RadioGroup}
          name="unitCondition"
          label="Unit condition"
          variant="outlined"
        >
          {unitConditionConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.value}
              disabled={isSubmitting}
              control={<Radio />}
            />
          ))}
        </FastField>
      </Grid>

      <Grid item xs={12} sm={12}>
        <FastField
          fullWidth
          required
          component={TextField}
          name="entryBy"
          label="Entry By:"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <FastField
          fullWidth
          multiline
          rows={4}
          component={TextField}
          name="comments"
          label="Comments"
          variant="outlined"
        />
      </Grid>
    </React.Fragment>
  );
}
