const interiorTypeConstants = [
  {
    value: "LEATHER",
    label: "LEATHER",
  },
  {
    value: "CLOTH",
    label: "CLOTH",
  },
];

const yesNoConstants = [
  {
    value: "YES",
    label: "YES",
  },
  {
    value: "NO",
    label: "NO",
  },
];

const climateControleConstants = [
  {
    value: "REGULAR",
    label: "REGULAR",
  },
  {
    value: "AUTO",
    label: "AUTO",
  },
  {
    value: "2 ZONE",
    label: "2 ZONE",
  },
  {
    value: "2 ZONE AUTO",
    label: "2 ZONE AUTO",
  },
  {
    value: "3 ZONE",
    label: "3 ZONE",
  },
  {
    value: "3 ZONE AUTO",
    label: "3 ZONE AUTO",
  },
];

const heatedSeatsConstants = [
  {
    value: "NO",
    label: "NO",
  },
  {
    value: "FRONT",
    label: "FRONT",
  },
  {
    value: "FRONT & BACK",
    label: "FRONT & BACK",
  },
];

const passengersConstants = [
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
  {
    value: "7",
    label: "7",
  },
  {
    value: "8",
    label: "8",
  },
  {
    value: "9",
    label: "9",
  },
];

export {
  interiorTypeConstants,
  yesNoConstants,
  climateControleConstants,
  heatedSeatsConstants,
  passengersConstants,
};
