/* eslint-disable max-len */
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ChipFiltersResponsive from "common/ChipFiltersResponsive";
import { useDebounce } from "common/hooks/useDebounce.js";
import { useWindowResize } from "common/hooks/useWindowResize";
import ModalButton from "common/ModalButton";
import PermissionWrapper from "common/PermissionWrapper";
import { RegisterALeadMode } from "pages/RegisterALead/RegisterALeadDashboard";
import React, { useCallback, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";

import styles from "./MaterialTableResponsive.css.js";

const useStyles = makeStyles(styles);
const useListTextStyles = makeStyles(() => ({
  root: {
    overflow: "hidden",
  },
  primary: {
    fontSize: "14px !important",
  },
}));

export const MaterialTableTypesEnum = {
  INVENTORY: "inventory",
  QUICK_UPDATE: "quick-update",
  NEW_STOCK_INTAKE: "new-stock-intake",
  REGISTER_A_LEAD: "register-a-lead",
};

export default function MaterialTableResponsive({
  data,
  columns,
  type,
  handleOnClick,
}) {
  const classes = useStyles();
  const classesListText = useListTextStyles();
  const { height } = useWindowResize();
  const [searchValue, setSearchValue] = useState("");
  const [numberOfElementRender, setNumberOfElementRender] = useState(12);
  const [elementToShow, setElementToShow] = useState();
  const [open, setOpen] = useState({});
  const [finishInfinityScroll, setFinishInfinityScroll] = useState(false);
  const infinityScrollContainer = useRef(null);
  const [filtersList, setFiltersList] = useState([]);
  const [fieldFilterToAdd, setFieldFilterToAdd] = useState("");
  const [valueFilterToAdd, setValueFilterToAdd] = useState("");
  const debouncedSearchTerm = useDebounce(searchValue, 750);

  const getTitle = useCallback(
    (row) => {
      switch (type) {
        case MaterialTableTypesEnum.INVENTORY:
        case MaterialTableTypesEnum.QUICK_UPDATE:
        case MaterialTableTypesEnum.NEW_STOCK_INTAKE:
          return `${row.vehicleMake} ${row.vehicleModel} ${row.vehicleYear} ${row.stock} ${row.vin} ${row.color}`;
        case MaterialTableTypesEnum.REGISTER_A_LEAD:
          return `${row.firstName} ${row.lastName} ${row.email}`;
        default:
          return "";
      }
    },
    [type]
  );

  const filterDataBySearchAndFilters = useCallback(
    (data, searchKeyword, filters = []) => {
      return data.filter((entry) => {
        const title = getTitle(entry).toLowerCase();
        const isIncludesKeyword = title.includes(searchKeyword.toLowerCase());

        if (isIncludesKeyword && filters.length > 0) {
          return (
            filters.find(
              (filter) =>
                String(entry[filter.field]).toLowerCase() !==
                String(filter.value).toLowerCase()
            ) === undefined
          );
        }
        return isIncludesKeyword;
      });
    },
    [getTitle]
  );

  const performSearchAndFiltering = useCallback(
    (keyword, filters = []) => {
      const filtered = filterDataBySearchAndFilters(data, keyword, filters);
      const element = filtered.filter((_, idx) => idx < 12);

      setFinishInfinityScroll(false);
      setNumberOfElementRender(12);
      setElementToShow(element);
    },
    [filterDataBySearchAndFilters, data]
  );

  // Update when data change
  useEffect(() => {
    if (debouncedSearchTerm) {
      performSearchAndFiltering(debouncedSearchTerm, filtersList);
    } else {
      performSearchAndFiltering("", filtersList);
    }
  }, [performSearchAndFiltering, debouncedSearchTerm, filtersList, data]);

  const handleOpenRow = (row) => {
    if (open[row.vin]) {
      setOpen({ ...open, [row.vin]: false });
    } else {
      setOpen({ ...open, [row.vin]: true });
    }
  };

  function handleChangeSearchValue(event) {
    setSearchValue(event.target.value);
  }

  function handleOnClickButton(row, event) {
    handleOnClick(row);
    event.stopPropagation();
  }

  function handleOnClickButtonRegister(row, type, event) {
    handleOnClick(row, type);
    event.stopPropagation();
  }

  function MaterialTableResponsiveColumns({ item, row }) {
    const columnsTitle = columnsNameMapping(item);
    if (columnsTitle) {
      return (
        <React.Fragment key={`item-${item}`}>
          <Collapse in timeout="auto" unmountOnExit>
            <ListItem className={classes.rowElement}>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <ListItemText
                    classes={classesListText}
                    primary={`${columnsTitle}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ListItemText
                    classes={classesListText}
                    primary={`${row[item]}`}
                  />
                </Grid>
              </Grid>
            </ListItem>
          </Collapse>
          <Divider />
        </React.Fragment>
      );
    } else {
      return <React.Fragment />;
    }
  }

  const MaterialTableResponsiveRow = ({ row, classes }) => (
    <li key={`${row.vin}`} className={classes.listSection}>
      <ul className={classes.ul}>
        {getListSubHeader(row, classes)}
        {open[row.vin]
          ? Object.keys(row).map((item, index) => (
              <MaterialTableResponsiveColumns
                key={index}
                item={item}
                row={row}
              />
            ))
          : null}
      </ul>
    </li>
  );

  function getListSubHeader(row, classes) {
    let color = "(" + row.color + ")";
    if (row.color === "") {
      color = "";
    }

    switch (type) {
      case MaterialTableTypesEnum.INVENTORY:
        return (
          <Grid
            container
            spacing={0}
            style={{
              backgroundColor: "YES" === row.pending ? "yellow" : "#dadddf",
            }}
            onClick={() => handleOpenRow(row)}
          >
            <Grid item xs={7}>
              <ListSubheader
                style={{
                  color: "black",
                }}
                className={classes.subtitle}
              >
                <span>{`${row.stock} / ${row.vin.slice(
                  row.vin.length - 7
                )}`}</span>
                <br />
                <span>{`${row.vehicleMake} ${row.vehicleModel}`}</span>
                <br />
                <span>{`${row.vehicleYear}`}</span>
              </ListSubheader>
            </Grid>

            <Grid item xs={1}>
              <ListSubheader
                style={{
                  color: "black",
                  textAlign: "center",
                }}
                className={classes.subtitle}
              >
                <br />
                <span>{`${row.lotNo}`}</span>
                <br />
              </ListSubheader>
            </Grid>

            <Grid item xs={4}>
              <ListSubheader
                style={{
                  float: "right",
                  textAlign: "right",
                  color: "black",
                }}
                className={classes.subtitle}
              >
                <span>{`${row.color}`}</span>
                <br />
                <span>{`${row.kmReading}`}</span>
                <br />
                <span>{`${row.price} $`}</span>
              </ListSubheader>
            </Grid>
          </Grid>
        );
      case MaterialTableTypesEnum.QUICK_UPDATE:
        return (
          <Grid
            container
            style={{
              backgroundColor: "#dadddf",
            }}
            onClick={() => handleOpenRow(row)}
          >
            <Grid item xs={7}>
              <ListSubheader
                style={{
                  color: "black",
                }}
                className={classes.subtitle}
              >
                <span>{`${row.stock} / ${row.vin.slice(
                  row.vin.length - 7
                )}`}</span>
                <br />
                <span>{`${row.vehicleMake} ${row.vehicleModel} ${color}`}</span>
                <br />
                <span>{`${row.vehicleYear}`}</span>
              </ListSubheader>
            </Grid>

            <Grid item xs={1}>
              <ListSubheader
                style={{
                  color: "black",
                  textAlign: "center",
                }}
                className={classes.subtitle}
              >
                <br />
                <span>{`${row.lotNo}`}</span>
                <br />
              </ListSubheader>
            </Grid>

            <Grid item xs={4}>
              <br />
              <Button
                size="small"
                color="secondary"
                variant="contained"
                style={{ float: "right", marginRight: 12, zIndex: 10 }}
                onClick={(event) => handleOnClickButton(row, event)}
              >
                <AddCircleIcon fontSize="small" />
              </Button>
              <br />
            </Grid>
          </Grid>
        );
      case MaterialTableTypesEnum.NEW_STOCK_INTAKE:
        return (
          <Grid
            container
            style={{
              backgroundColor: "#dadddf",
            }}
            onClick={() => handleOpenRow(row)}
          >
            <Grid item xs={7}>
              <ListSubheader
                style={{
                  color: "black",
                }}
                className={classes.subtitle}
              >
                <span>{`${row.stock} / ${row.vin.slice(
                  row.vin.length - 7
                )}`}</span>
                <br />
                <span>{`${row.vehicleMake} ${row.vehicleModel} ${color}`}</span>
                <br />
                <span>{`${row.vehicleYear}`}</span>
              </ListSubheader>
            </Grid>

            <Grid item xs={1}>
              <ListSubheader
                style={{
                  color: "black",
                  textAlign: "center",
                }}
                className={classes.subtitle}
              >
                <br />
                <span>{`${row.lotNo}`}</span>
                <br />
              </ListSubheader>
            </Grid>

            <Grid item xs={4}>
              <br />
              <Button
                size="small"
                color="secondary"
                variant="contained"
                style={{ float: "right", marginRight: 10 }}
                onClick={(event) => handleOnClickButton(row, event)}
              >
                <AddCircleIcon fontSize="small" />
              </Button>
              <br />
            </Grid>
          </Grid>
        );
      case MaterialTableTypesEnum.REGISTER_A_LEAD:
        return (
          <Grid
            container
            className={classes.subtitle}
            style={{
              backgroundColor: "#dadddf",
            }}
          >
            <Grid item xs={5}>
              <ListSubheader
                style={{ color: "black" }}
                className={classes.subtitle}
                // eslint-disable-next-line max-len
              >
                <span>{`${row.firstName} ${row.lastName}`}</span>
                <br />
                <span>{`${row.email}`}</span>
                <br />
              </ListSubheader>
            </Grid>

            <Grid item xs={7}>
              <PermissionWrapper permission="admin">
                <Button
                  size="small"
                  color="secondary"
                  variant="contained"
                  style={{ float: "right", marginRight: 10, marginTop: 12 }}
                  onClick={(event) =>
                    handleOnClickButtonRegister(
                      row,
                      RegisterALeadMode.EDIT,
                      event
                    )
                  }
                >
                  <EditIcon fontSize="small" />
                </Button>
              </PermissionWrapper>
              <Button
                size="small"
                color="secondary"
                variant="contained"
                style={{ float: "right", marginRight: 10, marginTop: 12 }}
                onClick={(event) =>
                  handleOnClickButtonRegister(row, RegisterALeadMode.SEE, event)
                }
              >
                <VisibilityIcon fontSize="small" />
              </Button>
            </Grid>
          </Grid>
        );
      default:
        return <React.Fragment />;
    }
  }

  // TODO That will be slow
  function columnsNameMapping(key) {
    const columnsTitle = columns.filter((element) => element.field === key);
    if (columnsTitle[0]) {
      return columnsTitle[0].title;
    } else {
      return null;
    }
  }

  const infiniteScrollFetchMoreData = () => {
    setTimeout(() => {
      const filtered = filterDataBySearchAndFilters(
        data,
        searchValue,
        filtersList
      );

      let adding = 20;
      if (filtered.length <= numberOfElementRender) {
        adding = 0;
        setFinishInfinityScroll(true);
      } else if (filtered.length < numberOfElementRender + adding) {
        adding = filtered.length - numberOfElementRender;
        setFinishInfinityScroll(false);
      } else {
        setFinishInfinityScroll(false);
      }

      const element = filtered.filter(
        (row, idx) => idx < numberOfElementRender + adding
      );

      setElementToShow(element);
      setNumberOfElementRender(numberOfElementRender + adding);
    }, 500);
  };

  function getMaterialTableResponsiveList() {
    if (elementToShow.length === 0) {
      return (
        <Paper elevation={3}>
          <Box textAlign="center">
            <SentimentDissatisfiedIcon fontSize="large" />
            <Typography variant="h6">There is no data to show</Typography>
          </Box>
        </Paper>
      );
    }

    return (
      <Paper elevation={3}>
        <List
          className={classes.root}
          style={{ maxHeight: height - 280 }}
          subheader={<li />}
          ref={infinityScrollContainer}
        >
          <InfiniteScroll
            pageStart={0}
            initialLoad
            loadMore={infiniteScrollFetchMoreData}
            hasMore={!finishInfinityScroll}
            loader={<CircularProgress key={0} />} // Pour une raison inconnu il est impossible de centrer avec autre chose que ça
            useWindow={false}
            getScrollParent={() => infinityScrollContainer.current}
          >
            {elementToShow.map((inventoryRow) => (
              <MaterialTableResponsiveRow
                key={inventoryRow.vin}
                classes={classes}
                row={inventoryRow}
              />
            ))}
          </InfiniteScroll>
        </List>
      </Paper>
    );
  }

  if (!elementToShow) {
    return (
      <React.Fragment>
        <MaterialTableResponsiveSearch
          classes={classes}
          handleChangeSearchValue={handleChangeSearchValue}
          searchValue={searchValue}
        />
        <CircularProgress />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <MaterialTableResponsiveSearch
        classes={classes}
        handleChangeSearchValue={handleChangeSearchValue}
        searchValue={searchValue}
      />
      <Badge
        badgeContent={filtersList.length}
        color="secondary"
        className={classes.margin}
      >
        <ModalButton
          buttonText="Filters"
          title="Set filters"
          name="filters-button"
          startIcon={<FilterListIcon fontSize={"large"} />}
        >
          {() => (
            <Box m={1}>
              {filtersList.length > 0 && (
                <Typography variant="h6" className={classes.title}>
                  Results:{" "}
                  {
                    filterDataBySearchAndFilters(data, searchValue, filtersList)
                      .length
                  }
                </Typography>
              )}
              <ChipFiltersResponsive
                filtersList={filtersList}
                setFiltersList={setFiltersList}
                filterFields={columns}
                fieldFilterToAdd={fieldFilterToAdd}
                valueFilterToAdd={valueFilterToAdd}
                setValueFilterToAdd={setValueFilterToAdd}
                setFieldFilterToAdd={setFieldFilterToAdd}
              />
            </Box>
          )}
        </ModalButton>
      </Badge>
      {getMaterialTableResponsiveList()}
    </React.Fragment>
  );
}

const MaterialTableResponsiveSearch = ({
  classes,
  handleChangeSearchValue,
  searchValue,
}) => (
  <TextField
    fullWidth
    key="search"
    className={classes.margin}
    label="Search"
    value={searchValue}
    variant="outlined"
    onChange={handleChangeSearchValue}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            onClick={() =>
              handleChangeSearchValue({
                target: {
                  value: "",
                },
              })
            }
            size="large"
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);
