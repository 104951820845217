const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },

  paper: {
    marginTop: "2em",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  error: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    minHeight: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  link: {
    textDecoration: "none",
  },

  imageIcon: {
    height: "100%",
  },

  iconPlacement: {
    marginRight: theme.spacing(3),
  },
});
export default styles;
