const driveTrainConstants = [
  {
    value: "4x4",
    label: "4x4",
  },
  {
    value: "4x4 (auto)",
    label: "4x4 (auto)",
  },
  {
    value: "FWD",
    label: "FWD",
  },
  {
    value: "RWD",
    label: "RWD",
  },
  {
    value: "AWD",
    label: "AWD",
  },
  {
    value: "AWD with LOCK",
    label: "AWD with LOCK",
  },
];

const yesNoConstants = [
  {
    value: "YES",
    label: "YES",
  },
  {
    value: "NO",
    label: "NO",
  },
];

const transConstants = [
  {
    value: "AUTO",
    label: "AUTO",
  },
  {
    value: "MANUAL",
    label: "MANUAL",
  },
];

const fuelTypeConstants = [
  {
    value: "GAS",
    label: "GAS",
  },
  {
    value: "DIESEL",
    label: "DIESEL",
  },
  {
    value: "HYBRID",
    label: "HYBRID",
  },
  {
    value: "EV",
    label: "EV",
  },
  {
    value: "PHEV",
    label: "PHEV",
  },
];

const CYLConstants = [
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
  {
    value: "8",
    label: "8",
  },
  {
    value: "10",
    label: "10",
  },
  {
    value: "12",
    label: "12",
  },
];

export {
  driveTrainConstants,
  yesNoConstants,
  transConstants,
  fuelTypeConstants,
  CYLConstants,
};
