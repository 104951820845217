import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import FeedbackIcon from "@mui/icons-material/Feedback";
import TodayIcon from "@mui/icons-material/Today";
import UpdateIcon from "@mui/icons-material/Update";
import { Button } from "@mui/material";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import BackDropLoading from "common/BackDropLoading";
import SnackbarAlert from "common/SnackbarAlert";
import { FastField, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { emailManagementConfig } from "services/firebase";

import styles from "./AdminEmailManagement.css";

const useStyles = makeStyles(styles);

const documentName = "config";

export default function AdminEmailManagement() {
  const classes = useStyles();
  const [data, setData] = useState(undefined);
  const [snackbarMessage, setSnackbarMessage] = useState({});

  useEffect(() => {
    const docRef = emailManagementConfig.doc(documentName);

    docRef.get().then((doc) => {
      if (doc.exists) {
        const d = doc.data();
        setData(d);
      }
    });
  }, []);

  async function saveInDataBase(values) {
    const docRef = emailManagementConfig.doc(documentName);

    try {
      await docRef.set(values);
      setSnackbarMessage({
        message: "Saved",
        severity: SnackbarAlert.success,
      });
    } catch (error) {
      setSnackbarMessage({
        message: error.message,
        severity: SnackbarAlert.error,
      });
    }
  }

  if (data === undefined) {
    return <BackDropLoading open={true} />;
  }

  return (
    <Formik initialValues={data} onSubmit={saveInDataBase}>
      {({ values, setFieldValue, isSubmitting }) => (
        <Form autoComplete="on">
          <Container className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" component="div" gutterBottom>
                  <TodayIcon /> Daily Activity Reports
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <FastField
                  fullWidth
                  component={TextField}
                  name="dailyActivityReportEmail"
                  label="Default Email"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <FastField
                  fullWidth
                  component={TextField}
                  name="dailyActivityITReportEmail"
                  label="IN TRANSIT - PICKED UP BY TOTAL AUTO TRANSPORT Email"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5" component="div" gutterBottom>
                  <FeedbackIcon /> Give a feedback Email
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <FastField
                  fullWidth
                  component={TextField}
                  name="giveFeedbackEmail"
                  label="Default Email"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5" component="div" gutterBottom>
                  <UpdateIcon /> Quick Update and Checkin Email
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <FastField
                  fullWidth
                  component={TextField}
                  name="quickUpdateAndCheckInEmail"
                  label="Default Email"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FastField
                  fullWidth
                  component={TextField}
                  name="quickUpdateAndCheckInTOEmail"
                  label="TORONTO Email"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FastField
                  fullWidth
                  component={TextField}
                  name="quickUpdateAndCheckInàEmail"
                  label="SUDBURY Email"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FastField
                  fullWidth
                  component={TextField}
                  name="quickUpdateAndCheckInOSEmail"
                  label="OSHAWA Email"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FastField
                  fullWidth
                  component={TextField}
                  name="quickUpdateAndCheckInTREmail"
                  label="TRENTON Email"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FastField
                  fullWidth
                  component={TextField}
                  name="quickUpdateAndCheckInTBEmail"
                  label="THUNDER BAY Email"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FastField
                  fullWidth
                  component={TextField}
                  name="quickUpdateAndCheckInNLEmail"
                  label="NEW LISKEARD Email"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5" component="div" gutterBottom>
                  <TodayIcon /> Inventory Management Email
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <FastField
                  fullWidth
                  component={TextField}
                  name="inventoryManagementEmail"
                  label="Default Email"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5" component="div" gutterBottom>
                  <AssignmentTurnedInIcon /> Register A Lead Email
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <FastField
                  fullWidth
                  component={TextField}
                  name="registerALeadEmail"
                  label="Default Email"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  SAVE
                </Button>
              </Grid>

              <BackDropLoading open={isSubmitting} />
              <SnackbarAlert
                open={snackbarMessage.message !== undefined}
                message={snackbarMessage.message}
                severity={snackbarMessage.severity}
                onClose={() => setSnackbarMessage({})}
              />
            </Grid>
          </Container>
        </Form>
      )}
    </Formik>
  );
}
