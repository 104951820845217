import EmailIcon from "@mui/icons-material/Email";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MapIcon from "@mui/icons-material/Map";
import NotesIcon from "@mui/icons-material/Notes";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import r from "routes";
import { versions } from "versions";

export default function InventoryTools({ handleRedirect, classes }) {
  const history = useHistory();

  function handleUserManagement() {
    history.push(r.adminUserManagement.path);
    handleRedirect();
  }

  function handleUserActivity() {
    history.push(r.adminUserActivity.path);
    handleRedirect();
  }

  function handleHeatMap() {
    history.push(r.adminHeatMap.path);
    handleRedirect();
  }

  function handleEmailManagement() {
    history.push(r.adminEmailManagement.path);
    handleRedirect();
  }

  function handleReleaseNotes() {
    history.push(r.adminReleaseNotes.path);
    handleRedirect();
  }

  return (
    <Grid item align="center" xs={12}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <PermIdentityIcon />
          <Typography className={classes.heading}>Admin</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List component="div" disablePadding className={classes.list}>
            <ListItem
              button
              name={"User activity (" + versions["user-activity"].version + ")"}
              onClick={handleUserActivity}
              className={classes.nested}
            >
              <ListItemIcon>
                <SupervisorAccountIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Users activity"}
                secondary={versions["user-activity"].version}
              />
            </ListItem>
            <ListItem
              button
              name={
                "User management (" + versions["user-management"].version + ")"
              }
              onClick={handleUserManagement}
              className={classes.nested}
            >
              <ListItemIcon>
                <RecentActorsIcon />
              </ListItemIcon>
              <ListItemText
                primary={"User Management"}
                secondary={versions["user-management"].version}
              />
            </ListItem>

            <ListItem
              button
              name={
                "Email management (" +
                versions["admin-email-management"].version +
                ")"
              }
              onClick={handleEmailManagement}
              className={classes.nested}
            >
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Email Management"}
                secondary={versions["admin-email-management"].version}
              />
            </ListItem>

            <ListItem
              button
              name={"Heat Map (" + versions["admin-heat-map"].version + ")"}
              onClick={handleHeatMap}
              className={classes.nested}
            >
              <ListItemIcon>
                <MapIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Heat Map"}
                secondary={versions["admin-heat-map"].version}
              />
            </ListItem>

            <ListItem
              button
              name={"Release Notes"}
              onClick={handleReleaseNotes}
              className={classes.nested}
            >
              <ListItemIcon>
                <NotesIcon />
              </ListItemIcon>
              <ListItemText primary={"Release Notes"} />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
