const initialValues = {
  isFromCSV: false,
  stock: "",
  vehicle: "",
  color: "",
  vin: "",
  realColor: "",
  packages: "",
  interiorType: "",
  interiorColor: "",
  powerSeat: "",

  kmReading: "",
  driveTrain: "",
  trans: "",
  fuelType: "",
  cyl: "",
  engineType: "",

  backUpCamera: "",
  xmRadio: "",
  nav: "",
  wifiHotSpot: "",
  blueToothAudio: "",
  appleCarPlay: "",
  androidAuto: "",
  climateControle: "",
  pass: "",

  blockHeater: "",
  heatedSeats: "",
  heatedSteeringWheel: "",
  cooledSeats: "",
  trailerHitch: "",
  pushToStart: "",
  keyless: "",
  passengers: "",
  vehicleRealColor: "",
  lastBatteryCharge: null,
  lastDriven: null,
  pictureDoneDate: null,
  currentKM: "",
  vehicleCurrentLocation: "",
  start: "",

  fogLights: "",
  remStrt: "",
  sunRoof: "",
  rearDiffLock: "",
  cab: "",
  box: "",
  bedOptions: "",
  trailerBrakeControle: "",
  other: "",
  notes: "",
};

export { initialValues };
