import * as pathToRegexp from "path-to-regexp";
import qs from "qs";

const compile = (path) => {
  const compiled = pathToRegexp.compile(path);

  return (params = {}) => {
    const { query } = params;
    let result = compiled(params);

    if (query) {
      result += `?${qs.stringify(query)}`;
    }

    return result;
  };
};

const r = (path, children) => {
  // Ensure consistency across all routes (ends with /)
  if (!path.endsWith("/")) {
    path = `${path}/`;
  }

  const route = compile(path);
  route.path = path;

  if (children) {
    const keys = Object.keys(children);

    keys.forEach((name) => {
      route[name] = children[name];
    });
  }

  return route;
};

const UUID = ".*"; // TODO: we will enable for all because FireStore do not have the same uid struc
const options = (path = "") => `/stock-options/:stockId(${UUID})/${path}`;
const selectNewStockIntake = (path = "") =>
  `/new-stock-intake/:stockId(${UUID})/${path}`;
const selectQuickUpdate = (path = "") =>
  `/quick-update/:stockId(${UUID})/${path}`;
const selectRegisterALead = (path = "") =>
  `/register-a-lead/:leadId(${UUID})/${path}`;
const selectOnBoarding = (path = "") =>
  `/onboarding-request/:onBoardingId(${UUID})/${path}`;

export default {
  root: r("/"),

  // --------------
  // Login
  // --------------
  login: r("/login"),

  // --------------
  // UnAutorize
  // --------------
  unAutorize: r("/unautorize"),

  // --------------
  // Inventory
  // --------------
  inventory: r("/inventory"),

  // --------------
  // QuickUpdate
  // --------------
  defaultQuickUpdate: r("/quick-update"),
  quickUpdate: r(selectQuickUpdate(), {
    modify: r(selectQuickUpdate("modify")),
  }),

  // --------------
  // New Stock Intake
  // --------------
  defaultNewStockIntake: r("/new-stock-intake"),
  newStockIntake: r(selectNewStockIntake(), {
    modify: r(selectNewStockIntake("modify")),
  }),

  // --------------
  // Register A Lead
  // --------------
  defaultRegisterALead: r("/register-a-lead"),
  registerALeadDashboard: r("/register-a-lead-dashboard"),
  registerALead: r(selectRegisterALead(), {
    modify: r(selectRegisterALead("modify")),
  }),

  // --------------
  // Onboarding Request
  // --------------
  defaultOnboardingRequest: r("/onboarding-request"),
  onBoardingRequest: r(selectOnBoarding(), {
    details: r(selectOnBoarding("details")),
  }),

  // --------------
  // Follow up
  // --------------
  followUp: r("/follow-up"),

  // --------------
  // Covid Log Book
  // --------------
  covidLogBook: r("/covid-log-book"),

  // --------------
  // Email Signature Generator
  // --------------
  emailSignatureGenerator: r("/email-signature-generator"),

  // --------------
  // StockOptions -- Discontinue
  // --------------
  defaultStockOptions: r("/stock-options"),
  stockOptions: r(options(), {
    modify: r(options("modify")),
  }),

  // --------------
  // Inventory Movement Request
  // --------------
  inventoryMovementRequest: r("/inventory-movement-request"),

  // --------------
  // Admin
  // --------------
  adminUserManagement: r("/admin/user-management"),
  adminUserActivity: r("/admin/user-activity"),
  adminHeatMap: r("/admin/heat-map"),
  adminEmailManagement: r("/admin/email-management"),
  adminReleaseNotes: r("/release-notes"),
};

export function pathNameToToolsName(string) {
  const map = {
    login: "Login page",
    unautorize: "Unautorize",

    "admin/user-management": "Admin - User Management",
    "admin/user-activity": "Admin - User Activity",
    "/admin/heat-map": "Admin - Heat Map",
    "/admin/email-management": "Admin - Email Management",
    "/release-notes": "Release Notes",

    "inventory-movement-request": "Inventory Movement Request",

    inventory: "Inventory",

    "quick-update": "Quick update",
    "new-stock-intake": "New Stock Intake",

    // This need to be before register-a-lead
    "register-a-lead-dashboard": "Register a Lead - Results",
    "register-a-lead": "Register a Lead",

    "onboarding-request": "Onboarding Request",

    "follow-up": "Follow Up",

    "email-signature-generator": "Email Signature Generator",
  };

  for (var key in map) {
    if (string.match(new RegExp(key, "i"))) return map[key];
  }

  return "Toronto Auto Group";
}
