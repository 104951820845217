const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.secondary,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  error: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    minHeight: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
});

export default styles;
