const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.secondary,
  },

  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    marginLeft: theme.spacing(2),
  },

  nested: {
    paddingLeft: theme.spacing(4),
  },

  list: {
    width: "100%",
  },
});

export default styles;
