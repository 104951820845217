import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import React from "react";

export default function Logout({ handleRedirect, logout, classes }) {
  return (
    <Paper>
      <Grid item align="center" xs={12}>
        <List component="div" disablePadding className={classes.list}>
          <ListItem
            button
            name="Logout"
            onClick={logout}
            className={classes.nested}
          >
            <ListItemText primary="Logout" />
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
          </ListItem>
        </List>
      </Grid>
    </Paper>
  );
}
