import "date-fns";

import {
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  Typography,
} from "@mui/material";
import MaterialUIFormikDatePicker from "common/MaterialUIFormikDatePicker";
import { FastField } from "formik";
import { RadioGroup, TextField } from "formik-mui";
import React from "react";

import { gender, yesNoConstants } from "../../constants";

export default function OnboardingRequestFormBasicInfo() {
  return (
    <React.Fragment>
      <Grid item align="left" xs={12}>
        <Typography variant="h6">Basic Info</Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          required
          component={TextField}
          name="firstName"
          label="First name"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          required
          component={TextField}
          name="lastName"
          label="Last name"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          component={TextField}
          name="address"
          label="Address"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MaterialUIFormikDatePicker name="birthday" label="Birthday" />
      </Grid>
      <Grid item xs={12} sm={6} align="left">
        <InputLabel>Gender ?*</InputLabel>
        <FastField
          required
          component={RadioGroup}
          name="gender"
          label="Gender ?"
          variant="outlined"
        >
          {gender.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.value}
              control={<Radio />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} sm={6} align="left">
        <InputLabel>
          Already worked for TORONTO AUTO GROUP OF COMPANIES or any sister
          company ?
        </InputLabel>
        <FastField
          required
          component={RadioGroup}
          name="workForTAG"
          label="Already worked for TGC or any sister company ?"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.value}
              control={<Radio />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FastField
          fullWidth
          multiline
          rows={4}
          component={TextField}
          name="previousEmployer"
          label="Previous employer"
          variant="outlined"
        />
      </Grid>
    </React.Fragment>
  );
}
