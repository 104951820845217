const styles = () => ({
  backdrop: {
    zIndex: 999,
  },
  paper: {
    padding: 60,
  },
});

export default styles;
