import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { useHistory } from "react-router-dom";
import r from "routes";
import { versions } from "versions";

export default function TransportTools({ handleRedirect, classes }) {
  const history = useHistory();

  function handleInventoryMovementRequest() {
    history.push(r.inventoryMovementRequest.path);
    handleRedirect();
  }

  return (
    <Grid item align="center" xs={12}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <DriveEtaIcon />
          <Typography className={classes.heading}>Transport</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List component="div" disablePadding className={classes.list}>
            <ListItem
              button
              name={
                "Inventory movement request (" +
                versions["inventory-movement-request"].version +
                ")"
              }
              onClick={handleInventoryMovementRequest}
              className={classes.nested}
            >
              <ListItemIcon>
                <DoneAllIcon />
              </ListItemIcon>
              <ListItemText
                primary="Inventory movement request"
                secondary={versions["inventory-movement-request"].version}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
