import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import r from "routes";

function PrivateRoute({
  component: Component,
  permission,
  currentUser,
  ...rest
}) {
  function permissionControle(props) {
    if (currentUser) {
      if (
        currentUser.permission.customClaims.includes(permission) ||
        permission === undefined
      ) {
        return <Component {...props} />;
      } else {
        return (
          <Redirect
            to={{
              pathname: r.unAutorize.path,
              state: { from: props.location },
            }}
          />
        );
      }
    } else {
      return (
        <Redirect
          to={{
            pathname: r.login.path,
            state: { from: props.location },
          }}
        />
      );
    }
  }

  return <Route {...rest} render={(props) => permissionControle(props)} />;
}

const mapStateToProps = (state) => ({
  currentUser: state.user,
});

export default connect(mapStateToProps)(PrivateRoute);
