import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  Button,
  Container,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ModalButton from "common/ModalButton";
import PermissionWrapper from "common/PermissionWrapper";
import SnackbarAlert from "common/SnackbarAlert";
import Handlebars from "handlebars";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { users } from "services/firebase";

import emailTemplate from "./email-signature-template.html.js";
import styles from "./EmailSignatureGenerator.css.js";

const useStyles = makeStyles(styles);
const template = Handlebars.compile(emailTemplate.source);

function EmailSignatureGenerator({ currentUser }) {
  const azureUser = currentUser.permission.azureUser;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(azureUser);
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    async function setAllUserInformation() {
      const snapshot = await users.get();
      const documents = [];
      snapshot.forEach((doc) => {
        const document = { [doc.data().email]: doc.data() };
        documents.push(document);
      });

      documents.sort(function (a, b) {
        let a_email = a[Object.keys(a)].email;
        let b_email = b[Object.keys(b)].email;

        return a_email.localeCompare(b_email);
      });

      setUsersList(documents);
    }

    setAllUserInformation();
  }, []);

  function handleCopyToClipboard() {
    const emailSignatureWithValue = template(userWithFormat);
    navigator.clipboard.writeText(emailSignatureWithValue);
    setOpen(true);
  }

  function handleSelectedUserChange(event) {
    const key = event.target.value;
    const index = usersList.findIndex(
      (element) => Object.keys(element)[0] === key
    );

    let user = usersList[index][key].azureUser;

    // TODO We need to revisite the array structure
    setSelectedUser({ ...user, email: key });
  }

  const userWithFormat = {
    full_name: selectedUser.givenName + " " + selectedUser.surname,
    job_title: selectedUser.jobTitle,
    office_name: selectedUser.officeLocation || "",
    department: selectedUser.department || "",
    street_address: selectedUser.streetAddress || "2380 Lawrence Ave E.",
    city: selectedUser.city || "Scarborough",
    postal_code: selectedUser.postalCode || "M1P 2R5",
    phone_number: selectedUser.mobilePhone,
  };

  // ATTENTION : This is using a public bucket in Google Cloud.
  // Don't delete the image in the bucket if the url in the image have not been change.
  // At: https://console.cloud.google.com/storage/browser/tag-email-signature;tab=objects?forceOnBucketsSortingFiltering=false&hl=fr&project=car-gestion-system&prefix=&forceOnObjectsSortingFiltering=false
  const emailSignatureWithValue = template(userWithFormat);

  return (
    <React.Fragment>
      <Container className={classes.root}>
        <Grid container alignItems="stretch" spacing={2}>
          <PermissionWrapper permission={"admin"}>
            <Grid item xs={12}>
              <Select
                fullWidth
                id="user-email-selector"
                variant="outlined"
                defaultValue={azureUser.email}
                value={selectedUser.email || ""}
                onChange={handleSelectedUserChange}
              >
                {usersList.map(function (user) {
                  const email = user[Object.keys(user)].email;
                  return (
                    <MenuItem key={email} value={email}>
                      {email}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </PermissionWrapper>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<FileCopyIcon />}
              onClick={handleCopyToClipboard}
            >
              Copy
            </Button>
          </Grid>
          <Grid item xs={12}>
            <ModalButton
              buttonText="Visualize the signature"
              title="Visualize the signature"
            >
              {(handleClose) => (
                <div
                  dangerouslySetInnerHTML={{ __html: emailSignatureWithValue }}
                />
              )}
            </ModalButton>
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              autoFocus
              disabled
              fullWidth
              value={emailSignatureWithValue}
              variant="outlined"
              name="email-signature-code"
            />
          </Grid>
        </Grid>
      </Container>
      <SnackbarAlert
        open={open}
        onClose={() => setOpen(false)}
        severity="success"
        message={"Copy to clipboard !"}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user,
});

export default connect(mapStateToProps)(EmailSignatureGenerator);
