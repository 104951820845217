import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import clsx from "clsx";
import PermissionWrapper from "common/PermissionWrapper";
import SnackbarAlert from "common/SnackbarAlert";
import Logout from "pages/Logout";
import UpdateBanner from "pages/MenuBar/UpdateBanner";
import AccountingTools from "pages/Tools/AccountingTools";
import AdminTools from "pages/Tools/AdminTools";
import GeneralTools from "pages/Tools/GeneralTools";
import InventoryTools from "pages/Tools/InventoryTools";
import OperationsTools from "pages/Tools/OperationsTools";
import SalesTools from "pages/Tools/SalesTools";
import ServiceTools from "pages/Tools/ServiceTools";
import ShortcutsTools from "pages/Tools/ShortcutsTools";
import TAGFinancialTools from "pages/Tools/TAGFinancialTools";
import TransportTools from "pages/Tools/TransportTools";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import r, { pathNameToToolsName } from "routes";
import { auth } from "services/firebase";

const drawerWidth = 400;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  list: {
    width: "100%",
  },
  fullList: {
    width: drawerWidth,
  },
  drawer: {
    [theme.breakpoints.up("xs")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexShrink: 0,
    },
  },
}));

export default function MenuBar() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  function logout() {
    auth.signOut();
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleHomeButton = () => {
    history.push(r.root.path);
    handleDrawerClose();
  };

  return (
    <React.Fragment>
      <UpdateBanner />
      <AppBar position="static">
        <Toolbar>
          <Box
            display="flex"
            justifyContent="left"
            textAlign="center"
            width="100%"
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <Box
              display="flex"
              flexGrow={1}
              alignItems="center"
              justifyContent="center"
              marginLeft="-42px"
            >
              <Typography variant="h6" onClick={handleHomeButton}>
                {pathNameToToolsName(history.location.pathname)}
              </Typography>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        anchor="left"
        open={open}
        onClick={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Box display="flex" alignItems="center">
            <IconButton onClick={handleDrawerClose} size="large">
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
            <Typography
              variant="h6"
              className={classes.title}
              onClick={() => history.push(r.root.path)}
            >
              Toronto Auto Group
            </Typography>
          </Box>
        </div>

        <Box m={2}>
          <ShortcutsTools
            handleAlert={handleOpenAlert}
            handleRedirect={handleDrawerClose}
            classes={classes}
          />
        </Box>

        <Box m={2}>
          <GeneralTools
            handleAlert={handleOpenAlert}
            handleRedirect={handleDrawerClose}
            classes={classes}
          />
        </Box>

        <Box m={2}>
          <InventoryTools
            handleAlert={handleOpenAlert}
            handleRedirect={handleDrawerClose}
            classes={classes}
          />
        </Box>

        <Box m={2}>
          <SalesTools
            handleAlert={handleOpenAlert}
            handleRedirect={handleDrawerClose}
            classes={classes}
          />
        </Box>

        <Box m={2}>
          <TAGFinancialTools
            handleAlert={handleOpenAlert}
            handleRedirect={handleDrawerClose}
            classes={classes}
          />
        </Box>

        <Box m={2}>
          <ServiceTools
            handleAlert={handleOpenAlert}
            handleRedirect={handleDrawerClose}
            classes={classes}
          />
        </Box>

        <Box m={2}>
          <OperationsTools
            handleAlert={handleOpenAlert}
            handleRedirect={handleDrawerClose}
            classes={classes}
          />
        </Box>

        <Box m={2}>
          <AccountingTools
            handleAlert={handleOpenAlert}
            handleRedirect={handleDrawerClose}
            classes={classes}
          />
        </Box>

        <Box m={2}>
          <PermissionWrapper permission={"admin"}>
            <TransportTools
              handleRedirect={handleDrawerClose}
              classes={classes}
            />
          </PermissionWrapper>
        </Box>

        <Box m={2}>
          <PermissionWrapper permission={"admin"}>
            <AdminTools
              handleAlert={handleOpenAlert}
              handleRedirect={handleDrawerClose}
              classes={classes}
            />
          </PermissionWrapper>
        </Box>

        <Box m={2}>
          <Logout
            logout={logout}
            handleRedirect={handleDrawerClose}
            classes={classes}
          />
        </Box>
      </Drawer>

      <SnackbarAlert
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        severity="error"
        message={
          "Sorry there is no tools to display. Ask your system administrator for more information"
        }
      />
    </React.Fragment>
  );
}
