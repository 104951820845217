const styles = (theme) => ({
  margin: {
    marginBottom: theme.spacing(1),
    display: "block",
  },
  chipWrap: {
    marginBottom: theme.spacing(1),
    display: "block",
    textAlign: "center",
  },
  chip: {
    margin: theme.spacing(1),
  },
  halfWidth: {
    width: "50%",
  },
  formLabel: {
    backgroundColor: "#fff",
  },
});

export default styles;
