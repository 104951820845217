import SearchIcon from "@mui/icons-material/Search";
import {
  CircularProgress,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import ColorIconText from "common/ColorIconText";
import { useDebounce } from "common/hooks/useDebounce.js";
import MD5 from "crypto-js/md5";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import r from "routes";
import { database } from "services/firebase";

export default function QuickUpdateSearchInput() {
  const [data, setData] = useState();
  const [searchValue, setSearchValue] = useState();

  useEffect(() => {
    database
      .ref("inventorySync/")
      .once("value")
      .then(function (snapshot) {
        var obj = snapshot.val();
        var array = [];
        Object.keys(obj).forEach(function (key) {
          array.push(obj[key]);
        });
        setData(array);
      });
  }, []);

  const handleTextInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <React.Fragment>
      <TextField
        fullWidth
        label="Inventory Quick Search"
        variant="outlined"
        onChange={handleTextInputChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <BasicTable data={data} searchValue={searchValue} />
    </React.Fragment>
  );
}

function BasicTable({ data, searchValue }) {
  const [elementToShow, setElementToShow] = useState();
  const debouncedSearchTerm = useDebounce(searchValue, 750);
  const history = useHistory();

  const filterDataBySearchAndFilters = useCallback((data, searchKeyword) => {
    return data.filter((entry) => {
      // eslint-disable-next-line max-len
      const title = `${entry.lotNo} ${entry.vehicleMake} ${entry.vehicleModel} ${entry.vehicleYear} ${entry.stock} ${entry.vin} ${entry.color}`.toLowerCase();
      const isIncludesKeyword = title.includes(searchKeyword.toLowerCase());
      return isIncludesKeyword;
    });
  }, []);

  const performSearchAndFiltering = useCallback(
    (keyword = "") => {
      const filtered = filterDataBySearchAndFilters(data, keyword);
      const element = filtered.filter((_, idx) => idx < 6);

      setElementToShow(element);
    },
    [data]
  );

  // Update when data change
  useEffect(() => {
    if (data) {
      if (debouncedSearchTerm) {
        performSearchAndFiltering(debouncedSearchTerm);
      } else {
        performSearchAndFiltering();
      }
    }
  }, [performSearchAndFiltering, debouncedSearchTerm, data]);

  function handleOnClick(data) {
    // eslint-disable-next-line new-cap
    const stockId = MD5(data.stock + data.vin).toString();
    history.push(
      r.quickUpdate.modify({
        stockId: stockId,
      })
    );
  }

  if (data === undefined || !elementToShow) {
    return <CircularProgress />;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Lot No.</b>
            </TableCell>
            <TableCell>
              <b>Stock</b>
            </TableCell>
            <TableCell>
              <b>Vehicle</b>
            </TableCell>
            <TableCell>
              <b>VIN</b>
            </TableCell>
            <TableCell>
              <b>Exterior Color</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {elementToShow.map((row) => (
            <TableRow
              hover
              key={row.vin}
              onClick={(event) => handleOnClick(row)}
            >
              <TableCell>{row.lotNo}</TableCell>
              <TableCell>{row.stock}</TableCell>
              <TableCell>{row.vehicle}</TableCell>
              <TableCell>{row.vin}</TableCell>
              <TableCell>
                <ColorIconText color={row.color} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
