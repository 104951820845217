import {
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  Typography,
} from "@mui/material";
import { FastField } from "formik";
import { RadioGroup, TextField } from "formik-mui";
import React from "react";

import { yesNoConstants } from "../constants.js";

export default function RegisterALeadForm({ isSubmitting, disabledInput }) {
  return (
    <React.Fragment>
      <Grid item align="left" xs={12}>
        <Typography variant="h6">Informations</Typography>
        <Divider />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          required
          disabled={disabledInput}
          component={TextField}
          name="firstName"
          label="First name"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          required
          disabled={disabledInput}
          component={TextField}
          name="lastName"
          label="Last name"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <FastField
          fullWidth
          required
          disabled={disabledInput}
          component={TextField}
          name="cellPhoneNumber"
          label="Cell phone number"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          disabled={disabledInput}
          component={TextField}
          name="email"
          label="Client email"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6} align="left">
        <InputLabel>Interested after COVID-19?</InputLabel>
        <FastField
          required
          disabled={disabledInput}
          component={RadioGroup}
          name="interestedAfterCOVID"
          label="Interested after COVID-19?"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.value}
              disabled={isSubmitting || disabledInput}
              control={<Radio />}
            />
          ))}
        </FastField>
      </Grid>

      <Grid item xs={12} sm={12}>
        <FastField
          fullWidth
          multiline
          rows={4}
          disabled={disabledInput}
          component={TextField}
          name="comments"
          label="Comments"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <FastField
          fullWidth
          required
          disabled={disabledInput}
          component={TextField}
          name="entryBy"
          label="Entry By:"
          variant="outlined"
        />
      </Grid>
    </React.Fragment>
  );
}
