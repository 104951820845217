import ClearIcon from "@mui/icons-material/Clear";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import r from "routes";

export default function UpdateBanner() {
  const [cookies, setCookie, removeCookie] = useCookies([
    "tag_web_app_banner_last_updated_time",
  ]);

  // Make the page render on cookies change
  useEffect(() => {}, [cookies.tag_web_app_banner_last_updated_time]);

  function shouldShow() {
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const lastDeployementDate = new Date(
      process.env.REACT_APP_LAST_DEPLOY_DATE
    );

    if (!cookies.tag_web_app_banner_last_updated_time) {
      return now - lastDeployementDate < oneDay;
    } else {
      // Check if the cookie is old
      const cookieDate = new Date(cookies.tag_web_app_banner_last_updated_time);
      if (lastDeployementDate > cookieDate) {
        removeCookie("tag_web_app_banner_last_updated_time");
        return true;
      }
      return false;
    }
  }

  function closeBanner() {
    if (!cookies.tag_web_app_banner_last_updated_time) {
      const now = new Date();
      setCookie("tag_web_app_banner_last_updated_time", now, {
        path: "/",
      });
    }
  }

  if (shouldShow()) {
    return (
      <Box
        sx={{
          "& > :not(style)": {
            width: "100%",
            margin: 0,
            backgroundColor: "white",
          },
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignContent="center"
          height="40px"
          onClick={closeBanner}
        >
          <span />
          <Typography alignSelf="center">
            🎉 We just released new features{" "}
            <Link to={r.adminReleaseNotes.path}>(see the changelog)</Link> 🎉
          </Typography>
          <IconButton onClick={closeBanner}>
            <ClearIcon />
          </IconButton>
        </Box>
      </Box>
    );
  } else {
    return <React.Fragment />;
  }
}
