import MaterialTable from "@material-table/core";
import { Box, Button, Container } from "@mui/material";
import MD5 from "crypto-js/md5";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import r from "routes";
import { database } from "services/firebase";

export default function RegisterLeadList() {
  const [data, setData] = useState();
  const history = useHistory();

  function handleStockOptions(data) {
    // eslint-disable-next-line new-cap
    const stockId = MD5(data.stock + data.vin).toString();
    history.push(
      r.stockOptions.modify({
        stockId: stockId,
      })
    );
  }

  useEffect(() => {
    database
      .ref("inventorySync/")
      .once("value")
      .then(function (snapshot) {
        var obj = snapshot.val();
        var array = [];
        Object.keys(obj).forEach(function (key) {
          array.push(obj[key]);
        });
        setData(array);
      });
  }, []);

  return (
    <Box component="span" m={5}>
      <Container>
        <Box component="span" m={5}>
          <div style={{ maxWidth: "100%" }}>
            <MaterialTable
              options={{
                draggable: false,
                search: true,
                sorting: true,
                pageSize: 20,
              }}
              columns={[
                { title: "Stock", field: "stock" },
                { title: "Vehicle", field: "vehicle" },
                { title: "VIN", field: "vin" },
                {
                  title: "Stock Options",
                  field: "stock",
                  render: (rowData) => (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleStockOptions(rowData)}
                    >
                      Stock Options
                    </Button>
                  ),
                },
              ]}
              data={data}
              title="Inventory"
            />
          </div>
        </Box>
      </Container>
    </Box>
  );
}
