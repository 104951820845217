import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

export default function AccountingTools({
  handleRedirect,
  handleAlert,
  classes,
}) {
  return (
    <Grid item align="center" xs={12}>
      <Accordion defaultExpanded onClick={handleAlert} name="Accounting">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} disabled>
          <AccountBalanceWalletIcon />
          <Typography className={classes.heading}>Accounting</Typography>
        </AccordionSummary>
        <AccordionDetails />
      </Accordion>
    </Grid>
  );
}
