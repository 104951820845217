// TODO : We should split this in diffrent sections and inclure the router to the components

import FeedBackModel from "common/FeedBackModel";
import PrivateRoute from "common/PrivateRoute";
import PublicRoute from "common/PublicRoute";
import AdminEmailManagement from "pages/Admin/AdminEmailManagement";
import AdminHeatMap from "pages/Admin/AdminHeatMap";
import AdminReleaseNotes from "pages/Admin/AdminReleaseNotes";
import AdminUserActivity from "pages/Admin/AdminUserActivity";
import AdminUserManagement from "pages/Admin/AdminUserManagement";
import CheckIn from "pages/CheckIn";
import CovidLogBook from "pages/CovidLogBook";
import EmailSignatureGenerator from "pages/EmailSignatureGenerator";
import FollowUp from "pages/FollowUp";
import Inventory from "pages/Inventory";
import Login from "pages/Login";
import MenuBar from "pages/MenuBar";
import OnboardingRequest from "pages/OnboardingRequest";
import QuickUpdate from "pages/QuickUpdate";
import RegisterALead from "pages/RegisterALead";
import RegisterALeadDashboard from "pages/RegisterALead/RegisterALeadDashboard";
import StockOptions from "pages/StockOptions";
import Dashboard from "pages/StockOptions/StockOptionsDashboard";
import Tools from "pages/Tools";
import InventoryMovementRequest from "pages/Transport/InventoryMovementRequest";
import UnAutorize from "pages/UnAuthorize";
import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import r from "routes";

function Home({ currentUser }) {
  // TODO This is a hack for now. Did not have the time to fix this
  if (currentUser === "vide") {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      {currentUser ? <MenuBar /> : <React.Fragment />}
      <Switch>
        <PublicRoute exact path={r.login.path} component={Login} />

        <Route exact path={r.covidLogBook.path} component={CovidLogBook} />
        <Route exact path={r.unAutorize.path} component={UnAutorize} />

        <PrivateRoute
          exact
          path={r.inventory.path}
          permission="staff"
          component={Inventory}
        />
        <PrivateRoute exact path={r.root.path} component={Tools} />

        <PrivateRoute
          exact
          path={r.defaultNewStockIntake.path}
          permission="staff"
          component={CheckIn}
        />
        <PrivateRoute
          exact
          path={r.newStockIntake.modify.path}
          permission="staff"
          component={CheckIn}
        />

        <PrivateRoute
          exact
          permission="staff"
          path={r.defaultQuickUpdate.path}
          component={QuickUpdate}
        />
        <PrivateRoute
          exact
          permission="staff"
          path={r.quickUpdate.modify.path}
          component={QuickUpdate}
        />

        <PrivateRoute
          exact
          permission="staff"
          path={r.defaultRegisterALead.path}
          component={RegisterALead}
        />
        <PrivateRoute
          exact
          permission="staff"
          path={r.registerALeadDashboard.path}
          component={RegisterALeadDashboard}
        />
        <PrivateRoute
          exact
          permission="staff"
          path={r.registerALead.modify.path}
          component={RegisterALead}
        />

        <PrivateRoute
          exact
          permission="staff"
          path={r.defaultStockOptions.path}
          component={Dashboard}
        />
        <PrivateRoute
          exact
          permission="staff"
          path={r.stockOptions.modify.path}
          component={StockOptions}
        />

        <PrivateRoute
          exact
          permission="staff"
          path={r.defaultOnboardingRequest.path}
          component={OnboardingRequest}
        />
        <PrivateRoute
          exact
          permission="staff"
          path={r.onBoardingRequest.details.path}
          component={OnboardingRequest}
        />

        <PrivateRoute
          exact
          path={r.emailSignatureGenerator.path}
          permission="staff"
          component={EmailSignatureGenerator}
        />

        <PrivateRoute
          exact
          permission="staff"
          path={r.inventoryMovementRequest.path}
          component={InventoryMovementRequest}
        />

        <PrivateRoute
          exact
          permission="admin"
          path={r.followUp.path}
          component={FollowUp}
        />

        <PrivateRoute
          exact
          permission="admin"
          path={r.adminUserManagement.path}
          component={AdminUserManagement}
        />

        <PrivateRoute
          exact
          permission="admin"
          path={r.adminUserActivity.path}
          component={AdminUserActivity}
        />

        <PrivateRoute
          exact
          permission="admin"
          path={r.adminHeatMap.path}
          component={AdminHeatMap}
        />

        <PrivateRoute
          exact
          permission="admin"
          path={r.adminEmailManagement.path}
          component={AdminEmailManagement}
        />

        <PrivateRoute
          exact
          permission="staff"
          path={r.adminReleaseNotes.path}
          component={AdminReleaseNotes}
        />
      </Switch>
      <FeedBackModel title="Give a feedback" />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user,
});

export default connect(mapStateToProps)(Home);
