import Image from "@jy95/material-ui-image";
import { Container, Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo from "assets/need-a-car-logo.png";
import BackDropLoading from "common/BackDropLoading";
import SnackbarAlert from "common/SnackbarAlert";
import { Formik } from "formik";
import phone from "phone";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { covidLogBookCollection } from "services/firebase";
import * as Yup from "yup";

import { dealerList, initalValue } from "./constants";
import styles from "./CovidLogBook.css";
import CovidLogBookForm from "./CovidLogBookForm";
const useStyles = makeStyles(styles);

function CovidLogBook() {
  const classes = useStyles();
  const location = useLocation();

  const [snackbarMessage, setSnackbarMessage] = useState({});
  const [initalInformation, setInitalInformation] = useState(initalValue);

  useEffect(() => {
    let dealerValue = qs.parse(location.search, { ignoreQueryPrefix: true })
      .dealer;

    dealerValue = dealerList.find(
      (element) => element.replace(/\s/g, "") === dealerValue
    );

    if (!dealerValue) {
      dealerValue = "Thunder Bay";
    }

    setInitalInformation({ ...initalInformation, dealer: dealerValue });
  }, [setInitalInformation, location]);

  const validateValues = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string()
      .email()
      .when("promotionalCheckbox", {
        is: true,
        then: Yup.string().required("Must enter a email address"),
      }),
    symptomsCheckbox: Yup.boolean().oneOf([true], "Mandatory"),
    promotionalCheckbox: Yup.boolean(),
    phoneNumber: Yup.string()
      .test(
        "test-phone-number",
        "You need to put a valide phone number.",
        (phoneNumber) => (phoneNumber ? phone(phoneNumber, "")[0] : true)
      )
      .when("email", {
        is: "",
        then: Yup.string().required(
          "Must enter a email address or a phone number."
        ),
      })
      .required(),
    dealer: Yup.string().required(),
  });

  async function handleSend(values, { setSubmitting, resetForm }) {
    setSubmitting(true);

    try {
      await saveCOVIDLogToBD(values);
    } catch (error) {
      console.error(error);
      setSnackbarMessage({
        message: "We can not save your information for now. Try again later.",
        severity: SnackbarAlert.error,
      });
    }

    let dealerValue = qs.parse(location.search, { ignoreQueryPrefix: true })
      .dealer;
    dealerValue = dealerList.find(
      (element) => element.replace(/\s/g, "") === dealerValue
    );
    resetForm({ ...initalInformation, dealer: dealerValue });

    setSnackbarMessage({
      message: "We successfully sent your information. Thank you.",
      severity: SnackbarAlert.success,
    });

    setSubmitting(false);
  }

  return (
    <Container className={classes.root}>
      <Paper className={classes.paper} elevation={3} padding="dense">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Image
              src={logo}
              imageStyle={{ height: "190px", width: "auto" }}
              style={{
                paddingTop: "160px",
                width: "100%",
                marginBottom: "20px",
              }}
            />
          </Grid>

          <Formik
            initialValues={initalInformation}
            onSubmit={handleSend}
            validationSchema={validateValues}
            enableReinitialize={true}
          >
            {({ isSubmitting, handleSubmit }) => (
              <React.Fragment>
                <CovidLogBookForm
                  isSubmitting={isSubmitting}
                  handleSubmit={handleSubmit}
                />

                <BackDropLoading open={isSubmitting} />
              </React.Fragment>
            )}
          </Formik>
        </Grid>
      </Paper>

      <SnackbarAlert
        open={snackbarMessage.message !== undefined}
        message={snackbarMessage.message}
        severity={snackbarMessage.severity}
        onClose={() => setSnackbarMessage({})}
      />
    </Container>
  );

  function saveCOVIDLogToBD(values) {
    const docName = new Date().toISOString();
    const docRef = covidLogBookCollection.doc(docName);
    return docRef.set(values);
  }
}

export default CovidLogBook;
