import { combineReducers, createStore } from "redux";

import { user } from "./reducers";

const reducers = combineReducers({
  user,
});

const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export { store, reducers };
