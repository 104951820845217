const sunRoofConstants = [
  {
    value: "NO",
    label: "NO",
  },
  {
    value: "YES",
    label: "YES",
  },
  {
    value: "PANORAMIC",
    label: "PANORAMIC",
  },
];

const yesNoConstants = [
  {
    value: "YES",
    label: "YES",
  },
  {
    value: "NO",
    label: "NO",
  },
];

export { sunRoofConstants, yesNoConstants };
