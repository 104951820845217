import FeedbackIcon from "@mui/icons-material/Feedback";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Fab from "@mui/material/Fab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles, useTheme } from "@mui/styles";
import { useFormik } from "formik";
import React, { useState } from "react";
import {
  browserName,
  fullBrowserVersion,
  osName,
  osVersion,
} from "react-device-detect";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { pathNameToToolsName } from "routes";
import { firebase } from "services/firebase";
import * as Yup from "yup";

const styles = (theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 999,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles(styles);

function FeedBackModel({ title, currentUser }) {
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  let fullName = "";
  let email = "";
  let location = "";
  if (currentUser) {
    location = currentUser.permission.azureUser.officeLocation;
    fullName = currentUser.displayName;
    email = currentUser.email;
  }

  const initialValues = {
    fullName: fullName,
    email: email,
    location: location,
    comments: "",
    browserVersion: fullBrowserVersion,
    browserName: browserName,
    osName: osName,
    osVersion: osVersion,
    currentTime: new Date().toLocaleString(),
    page: pathNameToToolsName(history.location.pathname),
  };

  const validateValues = Yup.object({
    fullName: Yup.string().required(),
    email: Yup.string().required(),
    comments: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validateValues,
    onSubmit: handleSend,
  });

  function feedBackModelForm() {
    if (!formik.isSubmitting) {
      return (
        <React.Fragment>
          <TextField
            fullWidth
            required
            variant="outlined"
            margin="dense"
            label="Full name"
            name="fullName"
            onChange={formik.handleChange}
            value={formik.values.fullName}
            error={formik.errors.fullName ? true : false}
            helperText={formik.errors.fullName}
          />
          <TextField
            fullWidth
            required
            variant="outlined"
            margin="dense"
            label="Email"
            type="email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.errors.email ? true : false}
            helperText={formik.errors.email}
          />
          <TextField
            fullWidth
            required
            multiline
            variant="outlined"
            margin="dense"
            label="Comments"
            name="comments"
            onChange={formik.handleChange}
            value={formik.values.comments}
            error={formik.errors.comments ? true : false}
            helperText={formik.errors.comments}
          />
        </React.Fragment>
      );
    } else {
      return (
        <Grid item align="center" xs={12}>
          <CircularProgress />
        </Grid>
      );
    }
  }

  return (
    <React.Fragment>
      <Fab
        name="feedback-fab"
        color="primary"
        onClick={handleClickOpen}
        className={classes.fab}
        aria-label="edit"
      >
        <FeedbackIcon />
      </Fab>

      <Dialog
        fullWidth
        maxWidth="lg"
        fullScreen={fullScreen}
        onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => handleClose()}>
          {title}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Did you encounter any issue? Think that something should be
            different ? Please let us know!
          </DialogContentText>
          {feedBackModelForm()}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="secondary">
            Cancel
          </Button>
          <Button
            name="submit"
            type="submit"
            color="primary"
            disabled={formik.isSubmitting}
            onClick={formik.handleSubmit}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  async function handleSend(values, { setSubmitting, resetForm }) {
    setSubmitting(true);
    await sendEmail(values);
    setSubmitting(false);

    resetForm();
    handleClose();
  }

  function sendEmail(values) {
    var sendFeedBackEmail = firebase
      .functions()
      .httpsCallable("sendFeedBackEmail");

    return sendFeedBackEmail(values);
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user,
});

export default connect(mapStateToProps)(FeedBackModel);
