import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BackDropLoading from "common/BackDropLoading";
import SnackbarAlert from "common/SnackbarAlert";
import MD5 from "crypto-js/md5";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { auth, database, firebase } from "services/firebase";
import * as Yup from "yup";

import { initialValues } from "./constants";
import styles from "./RegisterALead.css.js";
import RegisterALeadForm from "./RegisterALeadForm";

const useStyles = makeStyles(styles);

function RegisterALead({ currentUser }) {
  const classes = useStyles();
  const { leadId } = useParams();
  const location = useLocation();
  const [errorMessage, setMessageError] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState({});
  const [formData, setFormData] = useState();
  const [isJustEditMode, setIsJustEditMode] = useState();

  const validateValues = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    cellPhoneNumber: Yup.string().required(),
    entryBy: Yup.string().required(),
  });

  useEffect(() => {
    // check if the user is admin. Admin can see all the register lead
    if (currentUser.permission.customClaims.includes("admin") && leadId) {
      database
        .ref("registerALead")
        .once("value")
        .then(function (snapshot) {
          var obj = snapshot.val();
          if (obj) {
            // Loop into the user
            Object.keys(obj).forEach(function (userID) {
              const lead = obj[userID];

              // Loop into the lead
              if (lead) {
                Object.keys(lead).forEach(function (leadKey) {
                  lead["userID"] = userID;
                  if (leadKey === leadId) {
                    setFormData(lead[leadKey]);
                    setIsJustEditMode(location.state.mode);
                    return;
                  }
                });
              }
            });
          }
        });
    } else {
      database
        .ref("registerALead/" + currentUser.uid + "/" + leadId)
        .once("value")
        .then(function (snapshot) {
          const data = snapshot.val();

          if (data) {
            setFormData(data);
            setIsJustEditMode(location.state.mode);
          } else {
            // This is a new form
            setFormData({
              ...initialValues,
              entryBy: currentUser.displayName,
            });
            setIsJustEditMode(false);
          }
        });
    }
  }, [currentUser, leadId, location]);

  if (formData === undefined || isJustEditMode === undefined) {
    return <BackDropLoading open={true} />;
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={formData}
        onSubmit={save}
        validationSchema={validateValues}
      >
        {({ values, isSubmitting }) => (
          <Form autoComplete="on">
            <Container className={classes.root}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  {errorMessage ? (
                    <Paper className={classes.error}>
                      <Typography variant="body1">{errorMessage}</Typography>
                    </Paper>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </Grid>

                <RegisterALeadForm
                  disabledInput={isJustEditMode}
                  isSubmitting={isSubmitting}
                ></RegisterALeadForm>

                <Grid item xs={12} sm={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || isJustEditMode}
                  >
                    SEND
                  </Button>
                </Grid>

                <BackDropLoading open={isSubmitting} />

                <SnackbarAlert
                  open={snackbarMessage.message !== undefined}
                  message={snackbarMessage.message}
                  severity={snackbarMessage.severity}
                  onClose={() => setSnackbarMessage({})}
                />
              </Grid>
            </Container>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );

  async function save(
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) {
    values.time = new Date().toString();
    values.user = {
      uid: auth.currentUser.uid,
      displayName: auth.currentUser.displayName,
      email: auth.currentUser.email,
    };

    await sendEmail(values);
    await saveInDataBase(values);

    setSubmitting(false);
    setSnackbarMessage({ message: "Lead sent !", severity: "success" });
    resetForm();
  }

  function sendEmail(values) {
    var sendRegisterALeadEmail = firebase
      .functions()
      .httpsCallable("sendRegisterALeadEmailCallable");

    return sendRegisterALeadEmail(values);
  }

  function saveInDataBase(values) {
    const firstName = values.firstName;
    const lastName = values.lastName;
    const cellPhoneNumber = values.cellPhoneNumber;

    values.isEmailSend = false;

    database
      .ref(
        "registerALead/" +
          currentUser.uid +
          "/" +
          // eslint-disable-next-line new-cap
          MD5(firstName + lastName + cellPhoneNumber)
      )
      .set(values, function (error) {
        if (error) {
          console.error(error);
          setMessageError(error);
        }
      });
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user,
});

export default connect(mapStateToProps)(RegisterALead);
