import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { colorsList } from "assets/colorsList.json.js";
import React from "react";

import styles from "./ColorIconText.css.js";

const useStyles = makeStyles(styles);

export default function ColorIconText({ color }) {
  const classes = useStyles();
  const currentColor =
    color && colorsList.find((colorData) => colorData.name === color);

  return (
    <Box>
      {color && color.length > 0 && (
        <React.Fragment>
          {currentColor && (
            <Box
              alt={color}
              className={classes.colorIcon}
              style={{
                backgroundColor: currentColor.hex,
              }}
            />
          )}
          {color}
        </React.Fragment>
      )}
    </Box>
  );
}
