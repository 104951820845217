import DoneAllIcon from "@mui/icons-material/DoneAll";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ReceiptIcon from "@mui/icons-material/Receipt";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";

export default function TAGFinancialTools({
  handleRedirect,
  handleAlert,
  classes,
}) {
  return (
    <Grid item align="center" xs={12}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <ReceiptIcon />
          <Typography className={classes.heading}>
            <Tooltip title="TORONTO AUTO GROUP OF COMPANIES">
              <span>TGC </span>
            </Tooltip>
            Financial
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List component="div" disablePadding className={classes.list}>
            <ListItem
              button
              name="Decision form (0.0.0)"
              onClick={handleAlert}
              className={classes.nested}
            >
              <ListItemIcon>
                <DoneAllIcon />
              </ListItemIcon>
              <ListItemText primary="Decision form" secondary={"0.0.0"} />
            </ListItem>

            <ListItem
              button
              name="Decision Log (0.0.0)"
              onClick={handleAlert}
              className={classes.nested}
            >
              <ListItemIcon>
                <DoneAllIcon />
              </ListItemIcon>
              <ListItemText primary="Decision Log" secondary={"0.0.0"} />
            </ListItem>

            <ListItem
              button
              name="Delivery Release form (0.0.0)"
              onClick={handleAlert}
              className={classes.nested}
            >
              <ListItemIcon>
                <LocalShippingIcon />
              </ListItemIcon>
              <ListItemText
                primary="Delivery Release form"
                secondary={"0.0.0"}
              />
            </ListItem>

            <ListItem
              button
              name="Delivery Release log (0.0.0)"
              onClick={handleAlert}
              className={classes.nested}
            >
              <ListItemIcon>
                <LocalShippingIcon />
              </ListItemIcon>
              <ListItemText
                primary="Delivery Release log"
                secondary={"0.0.0"}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
