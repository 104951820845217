import MaterialTable from "@material-table/core";
import SendIcon from "@mui/icons-material/Send";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MD5 from "crypto-js/md5";
import firebase from "firebase/app";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import r from "routes";
import { database, firestore, quickUpdateCollection } from "services/firebase";

const ActivitiesTypesEnum = {
  QUICK_UPDATE: "Quick update",
  NEW_STOCK_INTAKE: "New-stock-intake",
  REGISTER_A_LEAD: "Register-a-lead",
};

export default function UserActivityDashboard() {
  const [activities, setActivities] = useState();
  const [progress, setProgress] = useState(0);
  const [isSendingArray, setIsSendingArray] = useState([]);
  const history = useHistory();

  const loadQuickUpdate = useCallback(async () => {
    const numberOfItems = 100;
    const sendArray = new Array(numberOfItems).fill(0);

    return new Promise(async (resolve) => {
      const snapshot = await quickUpdateCollection
        .orderBy("time", "desc")
        .get();

      var docArray = [];
      snapshot.forEach((doc) => {
        var obj = doc.data();
        docArray.push(obj);
      });

      var returnArray = [];
      for (let i = 0; i < numberOfItems; i++) {
        const obj = docArray[i];

        if (obj["car_uid"]) {
          await firestore
            .collectionGroup(obj["car_uid"])
            .get()
            .then((temp) => {
              var array = [];
              temp.forEach((doc) => {
                array.push(doc.data());
              });

              let quickUpdateData = array[array.length - 1];

              returnArray.push({
                user: {
                  displayName: obj["user_name"],
                  // TODO: This can be a problem in future because we only check the
                  // first collection a set time.
                  // If there is more than 1 request at the same second that can be a problem.
                },
                stockId: quickUpdateData.stock,
                vehicleName: quickUpdateData.vehicle,
                vin: quickUpdateData.vin,
                isEmailSend: quickUpdateData.isEmailSend,
                tools: ActivitiesTypesEnum.QUICK_UPDATE,
                uid: obj["car_uid"],
                time: obj["time"],
              });
              setProgress((returnArray.length / numberOfItems) * 100);
            });
        }
      }

      setIsSendingArray(sendArray);
      resolve(returnArray);
    });
  }, []);

  const loadNewStockIntake = useCallback(async () => {
    return new Promise((resolve) => {
      database
        .ref("newStockIntake")
        .once("value")
        .then(function (snapshot) {
          var obj = snapshot.val();
          var array = [];
          Object.keys(obj).forEach(function (key) {
            if (obj[key]["time"]) {
              obj[key]["tools"] = ActivitiesTypesEnum.NEW_STOCK_INTAKE;
              array.push(obj[key]);
            }
          });
          resolve(array);
        });
    });
  }, []);

  const loadRegisterALead = useCallback(async () => {
    return new Promise((resolve) => {
      database
        .ref("registerALead")
        .once("value")
        .then(function (snapshot) {
          var obj = snapshot.val();
          var array = [];
          Object.keys(obj).forEach(function (userID) {
            const lead = obj[userID];

            // Loop into the lead
            if (lead) {
              Object.keys(lead).forEach(function (key) {
                if (lead[key]["time"]) {
                  lead["userID"] = userID;
                  lead[key]["tools"] = ActivitiesTypesEnum.REGISTER_A_LEAD;
                  array.push(lead[key]);
                }
              });
            }
          });
          resolve(array);
        });
    });
  }, []);

  function handleInspectOnClick(data) {
    if (data.tools === ActivitiesTypesEnum.QUICK_UPDATE) {
      window.open("/quick-update/" + data.uid + "/modify", "_blank");
    } else if (data.tools === ActivitiesTypesEnum.NEW_STOCK_INTAKE) {
      // eslint-disable-next-line new-cap
      const stockId = MD5(data.stock + data.vin).toString();
      window.open("/new-stock-intake/" + stockId + "/modify", "_blank");
    } else if (data.tools === ActivitiesTypesEnum.REGISTER_A_LEAD) {
      // eslint-disable-next-line new-cap
      const leadId = MD5(
        data.firstName + data.lastName + data.cellPhoneNumber
      ).toString();
      history.push(
        r.registerALead.modify({
          leadId: leadId,
        }),
        { mode: true }
      );
    }
  }

  function handleSendOnClick(data, index) {
    // Add loading
    setIsSendingArray((prevState) => {
      const dataCopy = [...prevState];
      dataCopy[index] = true;
      return dataCopy;
    });

    if (data.tools === ActivitiesTypesEnum.QUICK_UPDATE) {
      new Promise((resolve) => {
        var sendUnsendQuickUpdateEmail = firebase
          .functions()
          .httpsCallable("sendUnsendQuickUpdateEmail");

        sendUnsendQuickUpdateEmail({
          id: data.uid,
        }).then((response) => {
          console.log("response", response);

          // Update email send so we disable the button
          setActivities((prevState) => {
            const dataCopy = [...prevState];
            dataCopy[index].isEmailSend = true;
            return dataCopy;
          });

          // Remove loading
          setIsSendingArray((prevState) => {
            const dataCopy = [...prevState];
            dataCopy[index] = false;
            return dataCopy;
          });

          resolve(response);
        });
      });
    } else if (data.tools === ActivitiesTypesEnum.NEW_STOCK_INTAKE) {
      new Promise((resolve) => {
        var sendUnsendNewStockIntakeEmail = firebase
          .functions()
          .httpsCallable("sendUnsendNewStockIntakeEmail");

        // eslint-disable-next-line new-cap
        const stockId = MD5(data.stock + data.vin).toString();

        sendUnsendNewStockIntakeEmail({
          id: stockId,
        }).then((response) => {
          // Update email send so we disable the button
          setActivities((prevState) => {
            const dataCopy = [...prevState];
            dataCopy[index].isEmailSend = true;
            return dataCopy;
          });

          // Remove loading
          setIsSendingArray((prevState) => {
            const dataCopy = [...prevState];
            dataCopy[index] = false;
            return dataCopy;
          });
          resolve(response);
        });
      });
    }
  }

  useEffect(() => {
    async function fetchData() {
      const quickUpdateData = await loadQuickUpdate();
      const newStockIntakeData = await loadNewStockIntake();
      const registerALeadData = await loadRegisterALead();

      const mergeArray = registerALeadData.concat(
        quickUpdateData.concat(newStockIntakeData)
      );

      setActivities(mergeArray);
    }
    fetchData();
  }, [loadQuickUpdate, loadNewStockIntake, loadRegisterALead]);

  let data = [];
  if (activities) {
    data = activities.sort((a, b) => {
      return new Date(b.time).getTime() - new Date(a.time).getTime();
    });
  }

  return (
    <React.Fragment>
      <Paper style={{ padding: 20 }}>
        <Box paddingTop={1}>
          <Typography variant="h5">User Activity</Typography>
          <br />
          <LinearProgress variant="determinate" value={progress} />
          <div style={{ maxWidth: "100%" }}>
            <MaterialTable
              isLoading={activities === undefined}
              options={{
                search: true,
                sorting: true,
                pageSize: 10,
                draggable: false,
                exportButton: true,
                cellStyle: {
                  paddingTop: 5,
                  paddingBottom: 5,
                },
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  paddingTop: 0,
                  paddingBottom: 0,
                },
              }}
              columns={[
                { title: "Tools name", field: "tools" },
                { title: "VIN", field: "vin" },
                { title: "Name", field: "vehicleName" },
                {
                  title: "User",
                  field: "user",
                  render: (rowData) => {
                    if (rowData.user) {
                      return rowData.user.displayName;
                    } else {
                      return "";
                    }
                  },
                },
                {
                  title: "Time",
                  field: "time",
                  render: (rowData) => {
                    const options = {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    };
                    var data = new Date(rowData.time);
                    return data.toLocaleDateString("en-US", options);
                  },
                },
                {
                  title: "Email Sended",
                  field: "isEmailSend",
                  render: (rowData) => {
                    const index = rowData.tableData.id;
                    if (isSendingArray[index]) {
                      return <CircularProgress size={20} />;
                    }

                    return (
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={
                          rowData.isEmailSend ||
                          rowData.tools === ActivitiesTypesEnum.REGISTER_A_LEAD
                        }
                        onClick={() => handleSendOnClick(rowData, index)}
                      >
                        <SendIcon fontSize="small" />
                      </Button>
                    );
                  },
                },
                {
                  title: "Inspect",
                  field: "tools",
                  render: (rowData) => (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleInspectOnClick(rowData)}
                    >
                      <VisibilityIcon fontSize="small" />
                    </Button>
                  ),
                },
              ]}
              data={data}
              title=""
            />
          </div>
        </Box>
      </Paper>
    </React.Fragment>
  );
}
