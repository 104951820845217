const dealerList = [
  "Trenton",
  "Scarborough",
  "Oshawa",
  "Sudbury",
  "Thunder Bay",
];

const initalValue = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  dealer: "",
  promotionalCheckbox: false,
  symptomsCheckbox: false,
};

export { dealerList, initalValue };
