import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import DailyReportDashboard from "./DailyReportDashboard";
import styles from "./UserActivity.css.js";
import UserActivityDashboard from "./UserActivityDashboard";

const useStyles = makeStyles(styles);

export default function AdminPanel() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container className={classes.root}>
        <Grid container spacing={2}>
          <Grid item align="center" xs={12}>
            <DailyReportDashboard />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item align="center" xs={12}>
            <UserActivityDashboard />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
