const initialValues = {
  vin: "",
  stock: "",
  make: "",
  model: "",
  color: "",
  year: "",
  location: "",
  destination: "",
  timeline: "",
  unitCondition: "",
  entryBy: "",
  comments: "",
  vehicleDateInStock: "",
};

const destinationConstants = [
  {
    value: "NAC Toronto",
  },
  {
    value: "NAC Sudbury",
  },
  {
    value: "NAC Thunder Bay",
  },
  {
    value: "NAC Trenton",
  },
  {
    value: "NAC Oshawa",
  },
  {
    value: "TAS Toronto",
  },
  {
    value: "TAS Sudbury",
  },
  {
    value: "NL New Liskeard",
  },
  {
    value: "Other",
  },
];

const timelineConstants = [
  {
    value: "ASAP its sold",
  },
  {
    value: "Normal",
  },
  {
    value: "When ressources available",
  },
];

const unitConditionConstants = [
  {
    value: "Start and move",
  },
  {
    value: "Doesn’t start",
  },
];

export {
  initialValues,
  destinationConstants,
  timelineConstants,
  unitConditionConstants,
};
