import { Button, Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BackDropLoading from "common/BackDropLoading";
import SnackbarAlert from "common/SnackbarAlert";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { auth, buisnessRequestsCollection, firebase } from "services/firebase";
import * as Yup from "yup";

import { initialValues } from "./constants";
import styles from "./OnboardingRequest.css.js";
import OnboardingRequestForm from "./OnboardingRequestForm";

const useStyles = makeStyles(styles);

function OnboardingRequest({ currentUser }) {
  const classes = useStyles();
  const { onBoardingId } = useParams();
  const [open, setOpen] = useState(false);
  const [fromData, setFormData] = useState();

  const requiredField = "This field is required !";
  const validateValues = Yup.object().shape({
    firstName: Yup.string().required(requiredField),
    lastName: Yup.string().required(requiredField),
    gender: Yup.string().required(requiredField),

    hiringManager: Yup.string().required(requiredField),
    workingLocation: Yup.string().required(requiredField),
    position: Yup.string().required(requiredField),
    desiredStartDate: Yup.date().required(requiredField),
  });

  useEffect(() => {
    const fetchData = async () => {
      if (onBoardingId) {
        const doc = buisnessRequestsCollection.doc(onBoardingId);
        const request = await doc.get();

        if (!request.exists) {
          setFormData({
            ...initialValues,
            hiringManager: currentUser.displayName,
          });
        } else {
          const object = {
            id: request.id,
            ...request.data(),
          };
          setFormData(object);
        }
      } else {
        setFormData({
          ...initialValues,
          hiringManager: currentUser.displayName,
        });
      }
    };

    fetchData();
  }, [currentUser, onBoardingId]);

  if (fromData === undefined) {
    return <BackDropLoading open={true} />;
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={fromData}
        onSubmit={save}
        validationSchema={validateValues}
      >
        {({ values, isSubmitting }) => (
          <Form autoComplete="on">
            <Container className={classes.root}>
              <Grid container spacing={4}>
                <OnboardingRequestForm />

                <Grid item xs={12} sm={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    SEND
                  </Button>
                </Grid>

                <BackDropLoading open={isSubmitting} />

                <SnackbarAlert
                  open={open}
                  onClose={() => setOpen(false)}
                  severity="success"
                  message={"Request sent !"}
                />
              </Grid>
            </Container>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );

  async function save(values, { setSubmitting, resetForm }) {
    // Make sure that we can send the date to FirebaseDB
    if (values.birthdate === null) {
      values.birthdate = "";
    }
    if (values.desiredStartDate === null) {
      values.desiredStartDate = "";
    }

    values.time = new Date().toString();
    values.userUid = auth.currentUser.uid;
    values.hiringManagerUUID = currentUser.uid;

    try {
      await saveInDataBase(values);
      await sendEmail(values);
      setOpen(true);
      resetForm();
    } catch (error) {
      //setMessageError("Error with the server. Please try again later.");
      console.error(error);
    }

    setSubmitting(false);
    window.scrollTo(0, 0);
  }

  function sendEmail(values) {
    var sendOnBoardingRequestEmail = firebase
      .functions()
      .httpsCallable("sendOnBoardingRequestEmail");

    return sendOnBoardingRequestEmail(values);
  }

  function saveInDataBase(values) {
    const description =
      values.firstName +
      " " +
      values.lastName +
      " will start to work in" +
      values.desiredStartDate +
      " at " +
      values.workingLocation;

    values.isEmailSend = false;

    return buisnessRequestsCollection.add({
      type: "Onboarding",
      status: "In Progress",
      title:
        "Onboarding request for " + values.firstName + " " + values.lastName,
      description: description,
      ...values,
    });
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user,
});

export default connect(mapStateToProps)(OnboardingRequest);
