import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FastField } from "formik";
import React from "react";

export default function MaterialUIFormikDatePicker({ name, label }) {
  return (
    <FastField name={name}>
      {({ field, form, meta }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={label}
            value={meta.value}
            onChange={(newValue) => {
              form.setFieldValue(name, newValue);
            }}
            renderInput={(params) => (
              <TextField fullWidth name={name} {...params} />
            )}
          />
        </LocalizationProvider>
      )}
    </FastField>
  );
}
