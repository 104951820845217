import {
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  Typography,
} from "@mui/material";
import { FastField } from "formik";
import { RadioGroup } from "formik-mui";
import React from "react";

import { workspacesValues, yesNoConstants } from "../../constants";

export default function OnboardingRequestFormRessources() {
  const ressourcesArray = [
    {
      name: "emailAddress",
      label: "Email address ?",
    },
    {
      name: "domainAccess",
      label: "Domain access",
    },
    {
      name: "DMSAccess",
      label: "DMS access",
    },
    {
      name: "dedicatedPhoneNumber",
      label: "Dedicated phone number",
    },
    {
      name: "dedicated800Number",
      label: "Dedicated 1-800 number",
    },
    {
      name: "CRM",
      label: "CRM",
    },
    {
      name: "remoteAccess",
      label: "Remote access (Splashtop)",
    },
    {
      name: "dealerTrack",
      label: "DealerTrack",
    },
    {
      name: "vAuto",
      label: "vAuto",
    },
    {
      name: "alarmSystemCode",
      label: "Alarm system code",
    },
  ];

  return (
    <React.Fragment>
      <Grid item align="left" xs={12}>
        <Typography variant="h6">Ressources needed</Typography>
        <Divider />
      </Grid>
      {ressourcesArray.map((element, index) => (
        <Grid key={index} item xs={12} sm={6} align="left">
          <InputLabel>{element.label}</InputLabel>
          <FastField
            required
            component={RadioGroup}
            name={element.name}
            label={element.label}
            variant="outlined"
          >
            {yesNoConstants.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                label={option.value}
                control={<Radio />}
              />
            ))}
          </FastField>
        </Grid>
      ))}

      <Grid item xs={12} sm={6} align="left">
        <InputLabel>Dedicated work space ?</InputLabel>
        <FastField
          required
          component={RadioGroup}
          name="workSpace"
          label="DMS access ?"
          variant="outlined"
        >
          {workspacesValues.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.value}
              control={<Radio />}
            />
          ))}
        </FastField>
      </Grid>
    </React.Fragment>
  );
}
