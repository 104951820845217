export const colorsList = [
  {
    name: "RED",
    hex: "#FF0000",
  },
  {
    name: "WHITE",
    hex: "#FFFFFF",
  },
  {
    name: "BLUE",
    hex: "#0000FF",
  },
  {
    name: "GREY",
    hex: "#808080",
  },
  {
    name: "BLACK",
    hex: "#000000",
  },
  {
    name: "SILVER",
    hex: "#c0c0c0",
  },
  {
    name: "PURPLE",
    hex: "#800080",
  },
  {
    name: "BROWN",
    hex: "#a52a2a",
  },
  {
    name: "WINE",
    hex: "#722f37",
  },
  {
    name: "BEIGE",
    hex: "#f5f5dc",
  },
  {
    name: "BLUE.DARK",
    hex: "#00008b",
  },
  {
    name: "MAROON",
    hex: "#800000",
  },
  {
    name: "BRONZE",
    hex: "#cd7f32",
  },
  {
    name: "GOLD",
    hex: "#ffd700",
  },
  {
    name: "GREEN",
    hex: "#00ff00",
  },
  {
    name: "ORANGE",
    hex: "#ffa500",
  },
  {
    name: "GREY.MED",
    hex: "#808080",
  },
  {
    name: "RED.MED",
    hex: "#801b1b",
  },
  {
    name: "VERMILION",
    hex: "#e34234",
  },
  {
    name: "PEARL",
    hex: "#eae0c8",
  },
  {
    name: "BLUE.LIGHT",
    hex: "#add8e6",
  },
  {
    name: "GREY.DARK",
    hex: "#a9a9a9",
  },
  {
    name: "RED.DARK",
    hex: "#8b0000",
  },
];
