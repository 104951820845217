import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { useHistory } from "react-router-dom";
import r from "routes";
import { versions } from "versions";

export default function OperationsTools({ handleRedirect, classes }) {
  const history = useHistory();

  function handleOnBoardingRequest() {
    history.push(r.defaultOnboardingRequest.path);
    handleRedirect();
  }

  function handleFollowUp() {
    history.push(r.followUp.path);
    handleRedirect();
  }

  return (
    <Grid item align="center" xs={12}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AssignmentTurnedInIcon />
          <Typography className={classes.heading}>Operations</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List component="div" disablePadding className={classes.list}>
            <ListItem
              button
              name={
                "Onboarding Request (" +
                versions["onboarding-request"].version +
                ")"
              }
              onClick={handleOnBoardingRequest}
              className={classes.nested}
            >
              <ListItemIcon>
                <AssignmentIndIcon />
              </ListItemIcon>
              <ListItemText
                primary="Onboarding Request"
                secondary={versions["onboarding-request"].version}
              />
            </ListItem>

            <ListItem
              button
              name={"Follow-up (" + versions["follow-up"].version + ")"}
              onClick={handleFollowUp}
              className={classes.nested}
            >
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Follow-up"}
                secondary={versions["follow-up"].version}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
