import MaterialTable from "@material-table/core";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Hidden from "@mui/material/Hidden";
import { makeStyles } from "@mui/styles";
import BackDropLoading from "common/BackDropLoading";
import { useWidth } from "common/hooks/useWidth";
import { useWindowResize } from "common/hooks/useWindowResize";
import MaterialTableResponsive, {
  MaterialTableTypesEnum,
} from "common/MaterialTableResponsive";
import SnackbarAlert from "common/SnackbarAlert";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { database } from "services/firebase";

import getInventoryColumns from "./GetInventoryColumns";
import styles from "./Inventory.css.js";
import InventoryFilter from "./InventoryFilter";
import { filter } from "./InventoryFilter/constants";

const useStyles = makeStyles(styles);

function Inventory() {
  const width = useWidth();
  const classes = useStyles();
  const location = useLocation();
  const [filteredData, setFilteredData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [filterState, setFilterState] = useState(filter);
  const { height } = useWindowResize();

  useEffect(() => {
    setLoading(true);
    database
      .ref("inventorySync/")
      .once("value")
      .then(function (snapshot) {
        var obj = snapshot.val();
        var array = [];
        if (obj) {
          Object.keys(obj).forEach(function (key) {
            array.push(obj[key]);
          });
        }
        setData(array);
        setLoading(false);
      });
  }, [location]);

  function getPageSize() {
    if (width !== "sm" || width !== "xs") {
      return 100; // Desktop
    } else {
      return 50; // Mobile
    }
  }

  function getPageSizeArray() {
    if (width !== "sm" || width !== "xs") {
      return [50, 100, 500, 1000]; // Desktop
    } else {
      return [20, 50, 100, 1000]; // Mobile
    }
  }

  if (loading || data === null) {
    return <BackDropLoading open={true} />;
  }

  if (filteredData === null && data) {
    setFilteredData(data);
  }

  return (
    <Box component="span">
      <Container maxWidth="1920px" spacing={4} style={{ marginTop: 12 }}>
        <Box component="div">
          <Hidden mdDown>
            <Grid item align="center" xs={12}>
              <InventoryFilter
                state={filterState}
                handleFilterChange={(filter) => setFilterState(filter)}
                handleClose={() => {}}
                setFilteredData={setFilteredData}
                data={data}
              />
            </Grid>
          </Hidden>
        </Box>
        <Box component="div" marginTop={3}>
          <Hidden mdUp>
            <MaterialTableResponsive
              columns={[
                { title: "LotNo", field: "lotNo" },
                { title: "Stock", field: "stock" },
                { title: "Vehicle Make", field: "vehicleMake" },
                { title: "Vehicle Model", field: "vehicleModel" },
                { title: "Vehicle Year", field: "vehicleYear" },
                { title: "Status", field: "vehicleStatus" },
                { title: "Recall Pending", field: "recallPending" },
                { title: "Vehicle Mileage", field: "kmReading" },
                { title: "Exterior Color", field: "color" },
                { title: "Interior Color", field: "interiorColor" },
                { title: "Cost", field: "vehicleTotalCost" },
                { title: "Transmission", field: "trans" },
                { title: "Retail Price", field: "price" },
                { title: "Web Price", field: "webPrice" },
                { title: "VIN", field: "vin" },
                { title: "Web URL", field: "webURL" },
                { title: "Date Safety", field: "dateSafety" },
                { title: "Last battery charge", field: "lastBatteryCharge" },
                { title: "Date Pictures", field: "datePictures" },
                { title: "Date Other", field: "dateOther" },
                { title: "Days", field: "days" },
                { title: "Claim ?", field: "accident" },
                { title: "Vehicle declarations", field: "vehicleDeclarations" },
                { title: "Claim cost", field: "accidentCost" },
                { title: "Pending?", field: "pending" },
              ]}
              data={filteredData}
              title="Inventory"
              type={MaterialTableTypesEnum.INVENTORY}
            />
          </Hidden>

          <Hidden mdDown>
            <div style={{ maxWidth: "100%", maxHeight: "650px" }}>
              <MaterialTable
                options={{
                  draggable: false,
                  search: true,
                  sorting: true,
                  filtering: true,
                  pageSize: getPageSize(),
                  pageSizeOptions: getPageSizeArray(),
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                  maxBodyHeight: height - 330,
                  rowStyle: (rowData) => ({
                    fontSize: "0.8em",
                    padding: "0 !important",
                    backgroundColor:
                      "YES" === rowData.pending ? "yellow" : "white",
                  }),
                  cellStyle: {
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                }}
                components={{
                  FilterRow: (rowProps) => {
                    const { columns, onFilterChanged } = rowProps;
                    return (
                      <>
                        <tr>
                          {columns.map((col) => {
                            if (col.field) {
                              return (
                                <td>
                                  <TextField
                                    margin="dense"
                                    sx={{ padding: 1, fontSize: "0.6em" }}
                                    onChange={(e) =>
                                      onFilterChanged(
                                        col.tableData.id,
                                        e.target.value
                                      )
                                    }
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <FilterListIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                    variant="standard"
                                  />
                                </td>
                              );
                            } else {
                              return <React.Fragment></React.Fragment>;
                            }
                          })}
                        </tr>
                      </>
                    );
                  },
                }}
                columns={getInventoryColumns(classes)}
                data={filteredData}
                title=""
              />
              <Typography
                className={classes.updatedAt}
                variant="caption"
                component="span"
              >
                Last update : {new Date(data[0].time).toString()}
              </Typography>
            </div>
          </Hidden>
        </Box>
      </Container>
      <SnackbarAlert
        open={open}
        onClose={() => setOpen(false)}
        severity="success"
        message={"Copy to clipboard !"}
      />
    </Box>
  );
}
export default Inventory;
