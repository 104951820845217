import { Button, Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BackDropLoading from "common/BackDropLoading";
import SnackbarAlert from "common/SnackbarAlert";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  firebase,
  inventoryMovementRequestCollection,
} from "services/firebase";
import * as Yup from "yup";

import { initialValues } from "./constants.js";
import styles from "./InventoryMovementRequest.js";
import InventoryMovementRequestForm from "./InventoryMovementRequestForm";

const useStyles = makeStyles(styles);

function InventoryMovementRequest({ currentUser }) {
  const classes = useStyles();
  const [snackbarMessage, setSnackbarMessage] = useState({});
  const [formData] = useState({
    ...initialValues,
    entryBy: currentUser.displayName,
  });

  const validateValues = Yup.object().shape({
    vin: Yup.string().required(),
    entryBy: Yup.string().required(),
  });

  return (
    <React.Fragment>
      <Formik
        initialValues={formData}
        onSubmit={save}
        validationSchema={validateValues}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form autoComplete="on">
            <Container className={classes.root}>
              <Grid container spacing={4}>
                <InventoryMovementRequestForm
                  setFieldValue={setFieldValue}
                  isSubmitting={isSubmitting}
                />

                <Grid item xs={12} sm={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    SEND
                  </Button>
                </Grid>

                <BackDropLoading open={isSubmitting} />

                <SnackbarAlert
                  open={snackbarMessage.message !== undefined}
                  message={snackbarMessage.message}
                  severity={snackbarMessage.severity}
                  onClose={() => setSnackbarMessage({})}
                />
              </Grid>
            </Container>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );

  async function save(values, { resetForm }) {
    var now = new Date(
      new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
      })
    );
    now = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    const time = now.toISOString().slice(0, -1);

    values.time = time;
    values.user = {
      uid: currentUser.uid,
      displayName: currentUser.displayName,
      email: currentUser.email,
    };

    try {
      await saveInDataBase(values);
      await sendEmail(values);
    } catch (error) {
      setSnackbarMessage({
        message: error.message,
        severity: "error",
      });
    }

    setSnackbarMessage({
      message: "Inventory movement request send !",
      severity: "success",
    });
    resetForm();
  }

  function sendEmail(values) {
    var sendInventoryMovementRequestEmail = firebase
      .functions()
      .httpsCallable("sendInventoryMovementRequestEmail");

    return sendInventoryMovementRequestEmail(values);
  }

  function saveInDataBase(values) {
    const docName = new Date().toISOString();

    values.isEmailSend = false;

    const docRef = inventoryMovementRequestCollection.doc(docName);
    return docRef.set(values);
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user,
});

export default connect(mapStateToProps)(InventoryMovementRequest);
