import {
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  Typography,
} from "@mui/material";
import MaterialUIFormikDatePicker from "common/MaterialUIFormikDatePicker";
import { FastField } from "formik";
import { RadioGroup, TextField } from "formik-mui";
import React from "react";

import { payParameters } from "../../constants";

export default function OnboardingRequestFormPositionDetails() {
  return (
    <React.Fragment>
      <Grid item align="left" xs={12}>
        <Typography variant="h6">Position details</Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          required
          component={TextField}
          name="hiringManager"
          label="Hiring Manager"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          required
          component={TextField}
          name="workingLocation"
          label="Working Location"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          required
          component={TextField}
          name="position"
          label="Position"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6} align="left">
        <InputLabel>Pay Parameters</InputLabel>
        <FastField
          component={RadioGroup}
          name="payParameters"
          label="Pay Parameters ?"
          variant="outlined"
        >
          {payParameters.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.value}
              control={<Radio />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <MaterialUIFormikDatePicker
          name="desiredStartDate"
          label="Desired Start Date"
        />
      </Grid>
    </React.Fragment>
  );
}
