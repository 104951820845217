import CallMadeIcon from "@mui/icons-material/CallMade";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";

function ShortcutsTools({ currentUser, classes }) {
  function handleTorontoAutoGroupClick() {
    const url = "https://www.torontoauto.group/en";
    var win = window.open(url, "_blank");
    win.focus();
  }

  function handleNeedACarClick() {
    const url = "https://www.needacar.ca/en";
    var win = window.open(url, "_blank");
    win.focus();
  }

  function handlSudburyClick() {
    const url = "https://sudbury.needacar.ca/en";
    var win = window.open(url, "_blank");
    win.focus();
  }

  function handlOshawaClick() {
    const url = "https://oshawa.needacar.ca/en";
    var win = window.open(url, "_blank");
    win.focus();
  }

  function handlThunberBayClick() {
    const url = "https://thunderbay.needacar.ca/en";
    var win = window.open(url, "_blank");
    win.focus();
  }

  function handlTrentonClick() {
    const url = "https://trenton.needacar.ca/en";
    var win = window.open(url, "_blank");
    win.focus();
  }

  function handlNewLiskeardClick() {
    const url = "https://www.needacar.ca/en";
    var win = window.open(url, "_blank");
    win.focus();
  }

  return (
    <Grid item align="center" xs={12}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <ClearAllIcon />
          <Typography className={classes.heading}>Shortcuts</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List component="div" disablePadding className={classes.list}>
            <ListItem
              button
              onClick={handleTorontoAutoGroupClick}
              name={"Toronto auto group"}
              className={classes.nested}
            >
              <ListItemIcon>
                <CallMadeIcon />
              </ListItemIcon>
              <ListItemText primary={"Toronto auto group"} />
            </ListItem>

            <ListItem
              button
              onClick={handleNeedACarClick}
              name={"Need a car"}
              className={classes.nested}
            >
              <ListItemIcon>
                <CallMadeIcon />
              </ListItemIcon>
              <ListItemText primary={"Need a car"} />
            </ListItem>

            <ListItem
              button
              onClick={handlSudburyClick}
              name={"Need a car - Sudbury"}
              className={classes.nested}
            >
              <ListItemIcon>
                <CallMadeIcon />
              </ListItemIcon>
              <ListItemText primary={"Need a car - Sudbury"} />
            </ListItem>

            <ListItem
              button
              onClick={handlOshawaClick}
              name={"Need a car - Oshawa"}
              className={classes.nested}
            >
              <ListItemIcon>
                <CallMadeIcon />
              </ListItemIcon>
              <ListItemText primary={"Need a car - Oshawa"} />
            </ListItem>

            <ListItem
              button
              onClick={handlThunberBayClick}
              name={"Need a car - Thunder Bay"}
              className={classes.nested}
            >
              <ListItemIcon>
                <CallMadeIcon />
              </ListItemIcon>
              <ListItemText primary={"Need a car - Thunder Bay"} />
            </ListItem>

            <ListItem
              button
              onClick={handlTrentonClick}
              name={"Need a car - Trenton"}
              className={classes.nested}
            >
              <ListItemIcon>
                <CallMadeIcon />
              </ListItemIcon>
              <ListItemText primary={"Need a car - Trenton"} />
            </ListItem>

            <ListItem
              button
              onClick={handlNewLiskeardClick}
              name={"Need a car - New Liskeard"}
              className={classes.nested}
            >
              <ListItemIcon>
                <CallMadeIcon />
              </ListItemIcon>
              <ListItemText primary={"Need a car - New Liskeard"} />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user,
});

export default connect(mapStateToProps)(ShortcutsTools);
