import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import React from "react";

export default function ServiceTools({ handleRedirect, handleAlert, classes }) {
  return (
    <Grid item align="center" xs={12}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <FormatListBulletedIcon />
          <Typography className={classes.heading}>Service</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List component="div" disablePadding className={classes.list}>
            <ListItem
              button
              name="RO Picture & conditions upload tools (0.0.0)"
              onClick={handleAlert}
              className={classes.nested}
            >
              <ListItemIcon>
                <AddAPhotoIcon />
              </ListItemIcon>
              <ListItemText
                primary="RO Picture & conditions upload tools"
                secondary={"0.0.0"}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
