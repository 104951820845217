import React from "react";

import OnboardingRequestFormBasicInfo from "./OnboardingRequestFormBasicInfo";
import OnboardingRequestFormPositionDetails from "./OnboardingRequestFormPositionDetails";
import OnboardingRequestFormRessources from "./OnboardingRequestFormRessources";

export default function OnboardingRequestForm() {
  return (
    <React.Fragment>
      <OnboardingRequestFormBasicInfo />
      <OnboardingRequestFormPositionDetails />
      <OnboardingRequestFormRessources />
    </React.Fragment>
  );
}
