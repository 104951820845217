import {
  Checkbox,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { storage } from "services/firebase";

import picturesDescriptionConstant from "./constants";
import UploadAndPictureButton from "./UploadAndPictureButton";

export default function StocksOptionsPhotosForm({
  handleFileListSend,
  stockId,
}) {
  const [fileList, setFileList] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(0);
  const [pictureDescriptionList, setPicturesDescriptionList] = useState([]);

  useEffect(() => {
    async function getStorageImage() {
      var pictureArray = JSON.parse(
        JSON.stringify(picturesDescriptionConstant)
      );

      // Get all the image in the storage
      var listRef = storage.ref("images").child(stockId);
      var res = await listRef.listAll();

      const items = res.items;
      for (const i in items) {
        const itemRef = items[i];
        let downloadImage = await itemRef.getDownloadURL();
        const isDamage = itemRef.name.includes("DOMMAGE");
        let name = itemRef.name.split(".")[0].replace("-DOMMAGE", "");

        const index = pictureArray.map((object) => object.name).indexOf(name);
        pictureArray[index].photoURL = downloadImage;
        pictureArray[index].isDamage = isDamage;
      }

      setPicturesDescriptionList(pictureArray);
    }

    if (forceRefresh === 0) {
      if (stockId) {
        getStorageImage();
      } else {
        setPicturesDescriptionList(picturesDescriptionConstant);
      }
    }
  }, [stockId, forceRefresh]);

  function uploadPhoto(file, name) {
    if (file) {
      var array = [];
      fileList.forEach((f) => array.push(f));

      const index = pictureDescriptionList
        .map((object) => object.name)
        .indexOf(name);

      array.push({
        [name]: file,
        damage: pictureDescriptionList[index].isDamage,
      });
      setFileList(array);
      handleFileListSend(array);
    }
  }

  function handleCheckboxChange(name, index) {
    console.log("name", name);
    console.log("index", index);

    // Update fileList if we use the checkbox after
    fileList.forEach((element, i) => {
      const fileName = Object.keys(element)[0];
      if (fileName === name) {
        fileList[i].damage = !fileList[i].damage;
      }
    });
    setFileList(fileList);

    let tempArray = pictureDescriptionList;
    tempArray[index].isDamage = !tempArray[index].isDamage;
    setPicturesDescriptionList(tempArray);
    setForceRefresh(forceRefresh + 1);
  }

  if (pictureDescriptionList === []) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <React.Fragment>
      <Grid item align="left" xs={12}>
        <Typography variant="h6">Photos</Typography>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontSize: "16px", fontWeight: "700" }}>
                  Postion
                </TableCell>
                <TableCell
                  style={{ fontSize: "16px", fontWeight: "700" }}
                  align="center"
                >
                  Photo
                </TableCell>
                <TableCell
                  style={{ fontSize: "16px", fontWeight: "700" }}
                  align="right"
                >
                  Damage
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pictureDescriptionList.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">
                    <UploadAndPictureButton
                      key={index + row.photoURL}
                      index={index}
                      name={row.name}
                      photoURL={row.photoURL}
                      uploadPhoto={uploadPhoto}
                    ></UploadAndPictureButton>
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <Checkbox
                      key={index}
                      checked={row.isDamage}
                      onChange={() => handleCheckboxChange(row.name, index)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </React.Fragment>
  );
}