import "./index.css";

import { ThemeProvider } from "@mui/material/styles";
import { createGenerateClassName, StylesProvider } from "@mui/styles";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Handlebars from "handlebars";
import React, { Suspense } from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import initStoreCurrentUser from "store/initializers/initStoreCurrentUser.js";
import BackDropLoading from "common/BackDropLoading";

import MyAppWithTimout from "./MyAppWithTimout";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store";
import { theme } from "./theme";

require("dotenv").config();

Sentry.init({
  dsn:
    "https://cc41ce09a11f47a6b1153cdf31a2b051@o1251595.ingest.sentry.io/6417150",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const generateClassName = createGenerateClassName({
  productionPrefix: "c",
});

Handlebars.registerHelper("eq", function () {
  const args = Array.prototype.slice.call(arguments, 0, -1);
  return args.every(function (expression) {
    return args[0] === expression;
  });
});

initStoreCurrentUser(store).then(() => {
  ReactDOM.render(
    <Suspense fallback={<div>Loading ~~~</div>}>
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          <StylesProvider generateClassName={generateClassName}>
            <CookiesProvider>
              <MyAppWithTimout />
            </CookiesProvider>
          </StylesProvider>
        </ThemeProvider>
      </ReduxProvider>
    </Suspense>,
    document.getElementById("root")
  );
});

ReactDOM.render(
  <BackDropLoading open={true} />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
