const styles = () => ({
  clampLinesCustom: {
    textAlign: "center",
  },
  updatedAt: {
    position: "relative",
    top: "-20px",
    left: "5px",
  },
});

export default styles;
