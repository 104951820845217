const styles = (theme) => ({
  root: {
    minWidth: 275,
    marginTop: 10,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  actions: {
    display: "flex",
    float: "right",
  },
  formControl: {
    flexDirection: "row",
  },
  selectRoot: {
    maxHeight: 40,
  },
  select: {
    maxHeight: 40,
  },
});

export default styles;
