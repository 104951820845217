import CommuteIcon from "@mui/icons-material/Commute";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import r from "routes";
import { versions } from "versions";

export default function InventoryTools({
  handleRedirect,
  handleAlert,
  classes,
}) {
  const history = useHistory();

  function handleNewStockIntake() {
    history.push(r.defaultNewStockIntake.path);
    handleRedirect();
  }

  function handleInventory() {
    history.push(r.inventory.path);
    handleRedirect();
  }

  return (
    <Grid item align="center" xs={12}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <DirectionsCarIcon />
          <Typography className={classes.heading}>Inventory</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List component="div" disablePadding className={classes.list}>
            <ListItem
              button
              name={"Inventory List (" + versions["inventory"].version + ")"}
              onClick={handleInventory}
              className={classes.nested}
            >
              <ListItemIcon>
                <CommuteIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Inventory List"}
                secondary={versions["inventory"].version}
              />
            </ListItem>
            <ListItem
              button
              name={
                "New stock intake (" +
                versions["new-stock-intake"].version +
                ")"
              }
              onClick={handleNewStockIntake}
              className={classes.nested}
            >
              <ListItemIcon>
                <VerifiedUserIcon />
              </ListItemIcon>
              <ListItemText
                primary={"New stock intake"}
                secondary={versions["new-stock-intake"].version}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
