import ReplayIcon from "@mui/icons-material/Replay";
import { Button, Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BackDropLoading from "common/BackDropLoading";
import ModalButton from "common/ModalButton";
import SnackbarAlert from "common/SnackbarAlert";
import MD5 from "crypto-js/md5";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import r from "routes";
import { auth, database, firebase, storage } from "services/firebase";
import * as Yup from "yup";

import { initialValues } from "../StockOptions/constants";
import {
  StocksOptionsOptionsForm,
  StocksOptionsOthersForm,
  StocksOptionsPhotosForm,
  StocksOptionsPowerTrainForm,
  StocksOptionsValidationForm,
  StocksOptionsVehiculeForm,
} from "../StockOptions/StockOptionsForm";
// eslint-disable-next-line max-len
import styles from "./CheckIn.css.js";
import CheckInDashboard from "./CheckInDashboard";

const useStyles = makeStyles(styles);

function NewStockIntake() {
  const history = useHistory();
  const classes = useStyles();
  const { stockId } = useParams();
  const [fileList, setFileList] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState({});
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [data, setData] = useState();

  useEffect(() => {
    if (stockId) {
      database
        .ref("inventorySync/" + stockId)
        .once("value")
        .then(function (snapshot) {
          const d = snapshot.val();

          if (d) {
            if (d.lastBatteryCharge === "") {
              d.lastBatteryCharge = null;
            }
            if (d.lastDriven === "") {
              d.lastDriven = null;
            }
            if (d.pictureDoneDate === "") {
              d.pictureDoneDate = null;
            }

            setData(d);
          } else {
            // Check if the that is from a past form in QuickUpdate
            database
              .ref("newStockIntake/" + stockId)
              .once("value")
              .then(function (snapshot) {
                let quickUpdateData = snapshot.val();

                if (quickUpdateData) {
                  if (quickUpdateData.lastBatteryCharge === "") {
                    quickUpdateData.lastBatteryCharge = null;
                  }
                  if (quickUpdateData.lastDriven === "") {
                    quickUpdateData.lastDriven = null;
                  }
                  if (quickUpdateData.pictureDoneDate === "") {
                    quickUpdateData.pictureDoneDate = null;
                  }

                  setData(quickUpdateData);
                } else {
                  setData(initialValues);
                }
              });
          }
        });
    } else {
      setData(initialValues);
    }
  }, [stockId]);

  const validateValues = Yup.object().shape({
    vin: Yup.string()
      .required()
      .matches(/^((([a-hj-zA-NPR-Z0-9]){8})?|([a-hj-zA-NPR-Z0-9]{17})?)$/, {
        message: "You VIN need to have 8 or 17 caractor and no i, O or Q",
        excludeEmptyString: true,
      }),
  });

  function handleNewBlankForm() {
    history.push(r.defaultNewStockIntake.path);
  }

  function handleFinalizeSend() {
    setLoadingProgress(0);
    history.push(r.root.path);
  }

  if (data === undefined) {
    return <BackDropLoading open={true} />;
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={data}
        onSubmit={save}
        enableReinitialize={true}
        validationSchema={validateValues}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form autoComplete="on">
            <Container className={classes.root}>
              <Grid container spacing={4}>
                <Grid item align="center" sm={6} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={handleNewBlankForm}
                    className={classes.button}
                    startIcon={<ReplayIcon />}
                  >
                    Reset form
                  </Button>
                </Grid>

                <Grid item align="center" sm={6} xs={12}>
                  <ModalButton
                    buttonText="Pre-fill with available data"
                    title="New Stock Intake"
                  >
                    {(handleClose) => (
                      <CheckInDashboard handleClose={handleClose} />
                    )}
                  </ModalButton>
                </Grid>

                <StocksOptionsVehiculeForm></StocksOptionsVehiculeForm>

                <StocksOptionsValidationForm
                  setFieldValue={setFieldValue}
                  isSubmitting={isSubmitting}
                ></StocksOptionsValidationForm>

                <StocksOptionsPhotosForm
                  stockId={stockId}
                  handleFileListSend={handleFileListSend}
                ></StocksOptionsPhotosForm>

                <Grid item xs={12} sm={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    SEND
                  </Button>
                </Grid>

                <StocksOptionsPowerTrainForm
                  isSubmitting={isSubmitting}
                ></StocksOptionsPowerTrainForm>

                <StocksOptionsOptionsForm
                  isSubmitting={isSubmitting}
                ></StocksOptionsOptionsForm>

                <StocksOptionsOthersForm
                  isSubmitting={isSubmitting}
                ></StocksOptionsOthersForm>

                <Grid item xs={12} sm={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    SEND
                  </Button>

                  <SnackbarAlert
                    open={snackbarMessage.message !== undefined}
                    message={snackbarMessage.message}
                    severity={snackbarMessage.severity}
                    onClose={() => setSnackbarMessage({})}
                  />
                </Grid>
              </Grid>
            </Container>
            <BackDropLoading
              open={
                loadingProgress > 0 && snackbarMessage.message === undefined
              }
              progress={loadingProgress}
              handleClose={() => {
                handleFinalizeSend();
              }}
            />
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );

  // TODO This have to be generalise
  function handleFileListSend(fileList) {
    setFileList(fileList);
  }

  async function save(
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) {
    setLoadingProgress(1);
    setSubmitting(true);

    if (values.lastDriven) {
      values.lastDriven = values.lastDriven.toString();
    } else {
      values.lastDriven = "";
    }

    if (values.lastBatteryCharge) {
      values.lastBatteryCharge = values.lastBatteryCharge.toString();
    } else {
      values.lastBatteryCharge = "";
    }

    if (values.pictureDoneDate) {
      values.pictureDoneDate = values.pictureDoneDate.toString();
    } else {
      values.pictureDoneDate = "";
    }

    values.time = new Date().toString();
    values.user = {
      uid: auth.currentUser.uid,
      displayName: auth.currentUser.displayName,
      email: auth.currentUser.email,
    };

    // Send error if we have a problem on the save procress
    try {
      const newValuesWithImage = await sendImageToBucket(values);
      setLoadingProgress(33);

      await saveInDataBase(newValuesWithImage);
      setLoadingProgress(66);

      await sendEmail(newValuesWithImage);
      setLoadingProgress(100);
    } catch (error) {
      console.log("Error on quick update save process : ", error);
      setSnackbarMessage({
        message: "Problem with your request, please try again later",
        severity: SnackbarAlert.error,
      });

      if (values.lastDriven === "") {
        values.lastDriven = null;
      }

      if (values.lastBatteryCharge === "") {
        values.lastBatteryCharge = null;
      }

      if (values.pictureDoneDate === "") {
        values.pictureDoneDate = null;
      }

      setSubmitting(false);
      return 0;
    }

    resetForm();
  }

  async function sendImageToBucket(values) {
    let id;
    let dowloadURLObject = {};
    const stockNumber = values.stock;
    const vin = values.vin;

    // This make possible to update a car with no stockNumber
    if (stockId) {
      id = stockId;
    } else {
      if (stockNumber) {
        // eslint-disable-next-line new-cap
        id = MD5(stockNumber + vin);
      } else {
        // eslint-disable-next-line new-cap
        id = MD5(vin);
      }
    }

    var metadata = {
      contentType: "image/png",
    };

    if (fileList) {
      await Promise.all(
        fileList.map(async (fileObject) => {
          const isDamage = fileObject.damage;
          const key = Object.keys(fileObject)[0];
          const name =
            Object.keys(fileObject)[0] + (isDamage ? "-DOMMAGE" : "");
          var storageRef = storage.ref("images/" + id + "/" + name + ".png");
          const snapshot = await storageRef.put(fileObject[key], metadata);
          const dowloadURL = await snapshot.ref.getDownloadURL();
          dowloadURLObject[key] = { url: dowloadURL, damage: isDamage };
        })
      );
    }
    values.photos = dowloadURLObject;
    return values;
  }

  function sendEmail(values) {
    var sendEmailDataCallable = firebase
      .functions()
      .httpsCallable("sendEmailDataCallable");

    values.type = "newStockIntake";
    return sendEmailDataCallable(values);
  }

  function saveInDataBase(values) {
    let id;
    const stockNumber = values.stock;
    const vin = values.vin;

    // This make possible to update a car with no stockNumber
    if (stockId) {
      id = stockId;
    } else {
      // eslint-disable-next-line new-cap
      id = MD5(stockNumber + vin);
    }

    values.isEmailSend = false;

    if (values.vehicleNeedHeavyRecon === "YES") {
      values.vehicleCurrentLocation = values.vehicleCurrentLocation + "-R";
    }

    database
      // eslint-disable-next-line new-cap
      .ref("newStockIntake/" + id)
      .set(values, function (error) {
        if (error) {
          console.error(error);
          setSnackbarMessage({
            message: error,
            severity: SnackbarAlert.error,
          });
        }
      });
  }
}
export default NewStockIntake;
