import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles, useTheme } from "@mui/styles";
import React, { useState } from "react";

import styles from "./ModalButton.css.js";
const useStyles = makeStyles(styles);

export default function ModalButton({
  title,
  buttonText,
  style,
  children,
  startIcon,
  name,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <React.Fragment>
      <Button
        fullWidth
        name={name || "local-fab"}
        variant="contained"
        color="primary"
        style={style}
        onClick={handleClickOpen}
        startIcon={startIcon || <SearchIcon />}
      >
        {buttonText}
      </Button>

      <Dialog
        fullWidth
        maxWidth="lg"
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent className={classes.fullSizeDialogContent} dividers>
          {children(handleClose)}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
