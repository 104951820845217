import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#BF2025",
    },
    secondary: {
      main: "#353535",
    },
    background: {
      default: {
        main: "white",
      },
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          marginBottom: "32px",
        },
      },
    },
  },
});
