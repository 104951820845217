import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/analytics";
import "firebase/performance";

import firebase from "firebase/app";

var config;
if (process.env.REACT_APP_IS_LOCALHOST === "true") {
  config = {
    apiKey: "AIzaSyChzll30kEMM47SlyRpYVASmdzMZHOoQrk",
    authDomain: "car-gestion-system-dev.firebaseapp.com",
    databaseURL: "https://car-gestion-system-dev.firebaseio.com",
    projectId: "car-gestion-system-dev",
    storageBucket: "car-gestion-system-dev.appspot.com",
    messagingSenderId: "213850664402",
    appId: "1:213850664402:web:c8405144ab6112824e2c0b",
    measurementId: "G-07TDXR1WBR",
  };
} else {
  config = {
    apiKey: "AIzaSyD6gT3gadTw9li1WCzawa50ynzZxIdLOW0",
    authDomain: "car-gestion-system.firebaseapp.com",
    databaseURL: "https://car-gestion-system.firebaseio.com",
    projectId: "car-gestion-system",
    storageBucket: "car-gestion-system.appspot.com",
    messagingSenderId: "55389638916",
    appId: "1:55389638916:web:8b91b79a8fe2db5e610051",
    measurementId: "G-W9EXWP82R2",
  };
}

firebase.initializeApp(config);
firebase.firestore().settings({ experimentalForceLongPolling: true });

if (process.env.REACT_APP_IS_LOCALHOST !== "true") {
  firebase.analytics();
  firebase.performance();
}

const auth = firebase.auth();
const database = firebase.database();
const firestore = firebase.firestore();
const storage = firebase.storage();
const users = firestore.collection("users");
const covidLogBookCollection = firestore.collection("covid_log_book");
const buisnessRequestsCollection = firestore.collection("business_requests");
const inventoryMovementRequestCollection = firestore.collection(
  "inventory_movement_requests"
);
const emailManagementConfig = firestore.collection("email_management_config");
const quickUpdateCollection = firestore.collection("quick_update");
const releaseCollection = firestore.collection("releases");
const functions = firebase.functions();

export {
  auth,
  database,
  storage,
  firestore,
  users,
  covidLogBookCollection,
  buisnessRequestsCollection,
  inventoryMovementRequestCollection,
  emailManagementConfig,
  quickUpdateCollection,
  releaseCollection,
  functions,
  firebase,
};
