import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Tooltip,
} from "@mui/material";
import React from "react";

import { abbreviationsMapper, enableFilter, filter } from "./constants";

export default function InventoryFilter({
  state,
  handleFilterChange,
  handleClose,
  data,
  setFilteredData,
}) {
  const handleChange = (event) => {
    if (event.target.name === "ALL" && event.target.checked) {
      handleFilterChange(filter);
    } else {
      handleFilterChange({
        ...state,
        [event.target.name]: event.target.checked,
        ALL: false,
      });
    }
  };

  function filtre() {
    let condition = Object.keys(state).filter(function (key) {
      return state[key];
    });

    let filtreData = data.filter(function (car) {
      if (condition.includes("ALL")) {
        return true;
      }

      return condition.includes(car.lotNo);
    });
    setFilteredData(filtreData);
    handleClose();
  }

  function findFilter(array) {
    let filter = [];
    array.forEach((element) => {
      let find = false;
      filter.forEach((e) => {
        if (e.value === element.lotNo) {
          find = true;
        }
      });
      if (!find) {
        if (enableFilter.includes(element.lotNo)) {
          // Find the label in the abbreviationsMapper
          let label = abbreviationsMapper[element.lotNo];

          filter.push({
            value: element.lotNo,
            label: label,
          });
        }
      }
    });

    filter.push({
      value: "ALL",
      label: "ALL",
    });
    return filter.sort();
  }

  return (
    <Box m={2}>
      <Box mb={2}>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            {findFilter(data).map((filter) => (
              <FormControlLabel
                key={filter.value}
                value={filter.value}
                label={
                  <Tooltip title={filter.label}>
                    <span>{filter.value}</span>
                  </Tooltip>
                }
                control={
                  <Checkbox
                    checked={state[String(filter.value)]}
                    onChange={handleChange}
                    name={filter.value}
                    color="primary"
                  />
                }
              />
            ))}
          </FormGroup>
        </FormControl>
      </Box>

      <Button
        fullWidth
        id="register-lead-search-button"
        variant="contained"
        color="primary"
        onClick={() => filtre()}
      >
        Set filter
      </Button>
    </Box>
  );
}
