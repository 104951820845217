import { SET_USER } from "store/actions/user";

export const initialState = "vide"; // TODO Change the implementation

export const user = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_USER:
      if (action.user === null) return null;
      else {
        return { ...action.user };
      }
    default:
      return state;
  }
};

export default user;
