const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.primary,
    position: "relative",
    overflow: "auto",
    marginBottom: "20px",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    marginBottom: theme.spacing(1),
    padding: 0,
  },
  subtitle: {
    lineHeight: "24px",
    fontWeight: 700,
    fontSize: 12,
    backgroundColor: "inherit",
  },
  title: {
    marginBottom: "20px",
    textAlign: "center",
  },
  margin: {
    marginBottom: theme.spacing(1),
    display: "block",
  },
  rowElement: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

export default styles;
