import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useState } from "react";
import { firebase } from "services/firebase";

export default function DailyReportDashboard() {
  const [report, setReport] = useState();

  const loadRapport = useCallback(async () => {
    var getDailyRapport = firebase.functions().httpsCallable("getDailyRapport");
    return getDailyRapport();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const rapport = await loadRapport();

      const array = [
        {
          toolsName: "Quick Update",
          numberOfActivity: rapport.data.numberQuickUpdate,
          numberOfUser: Object.keys(rapport.data.numberUserQuickUpdate).length,
        },
        {
          toolsName: "New stock intake",
          numberOfActivity: rapport.data.numberNewStockIntake,
          numberOfUser: Object.keys(rapport.data.numberUserNewStockIntake)
            .length,
        },
        {
          toolsName: "Register a lead",
          numberOfActivity: rapport.data.numberRegisterALead,
          numberOfUser: Object.keys(rapport.data.numberUserRegisterALead)
            .length,
        },
      ];

      setReport(array);
    }

    fetchData();
  }, [loadRapport]);

  if (!report) {
    return (
      <Paper style={{ padding: 20 }}>
        <Box paddingTop={1}>
          <Typography variant="h5">Yesterday Activity</Typography>
          <CircularProgress />
        </Box>
      </Paper>
    );
  }

  return (
    <React.Fragment>
      <Paper style={{ padding: 20 }}>
        <Box paddingTop={1}>
          <Typography variant="h5">Yesterday Activity</Typography>
          <br />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <b>Tools</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Number of activity</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Number of different User</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {report.map((row) => (
                  <TableRow key={row.toolsName}>
                    <TableCell align="center">{row.toolsName}</TableCell>
                    <TableCell align="center">{row.numberOfActivity}</TableCell>
                    <TableCell align="center">{row.numberOfUser}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </React.Fragment>
  );
}
