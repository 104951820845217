const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    //background: `url(${backgroundImage}) no-repeat center center fixed`,
    //backgroundSize: "cover",
    //backgroundColor: "rgba(0,0,0, 0.5)",
  },

  paper: {
    position: "relative",
    top: "20vh",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "520px",
    margin: "auto",
  },

  error: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    minHeight: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  link: {
    textDecoration: "none",
  },

  imageIcon: {
    height: "100%",
  },

  iconPlacement: {
    marginRight: theme.spacing(3),
  },
});
export default styles;
