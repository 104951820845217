import { Divider } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BackDropLoading from "common/BackDropLoading";
import React, { useEffect, useState } from "react";
import { releaseCollection } from "services/firebase";

export default function AdminReleaseNotes() {
  const [releaseNotes, setReleaseNotes] = useState([]);

  useEffect(() => {
    releaseCollection
      .where("isVisible", "==", true)
      .orderBy("created_at", "desc")
      .onSnapshot((snapshot) => {
        const notes = [];
        snapshot.forEach((doc) => {
          notes.push({ id: doc.id, ...doc.data() });
        });
        setReleaseNotes(notes);
      }, console.error);
  }, []);

  return (
    <React.Fragment>
      <Container>
        <Grid container spacing={2}>
          {releaseNotes.map((release) => (
            <Grid item align="left" xs={12} marginTop={4} key={release.id}>
              <Typography key={"title_" + release.id} variant="h4">
                {release.title}{" "}
              </Typography>
              <Typography key={"date_" + release.id} variant="caption">
                {release.created_at.toDate().toDateString()}{" "}
              </Typography>
              <ul>
                {release.description.map((description, index) => (
                  <li key={index}>{description}</li>
                ))}
              </ul>
              <Divider />
            </Grid>
          ))}
        </Grid>
      </Container>

      <BackDropLoading open={releaseNotes.length === 0} />
    </React.Fragment>
  );
}
