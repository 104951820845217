import Image from "@jy95/material-ui-image";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";

import styles from "./UploadAndPictureButton.css.js";
const useStyles = makeStyles(styles);

export default function UploadAndPictureButton({
  index,
  name,
  photoURL,
  uploadPhoto,
}) {
  const classes = useStyles();
  const [url, setURL] = useState(photoURL);

  const setPhoto = (event) => {
    let fileUpload = event.target.files;
    if (fileUpload) {
      if (FileReader && fileUpload && fileUpload.length) {
        var fr = new FileReader();
        fr.onload = function () {
          setURL(fr.result);
        };
        fr.readAsDataURL(fileUpload[0]);
      } else {
        console.error("File Reader is not supported");
      }
      uploadPhoto(fileUpload[0], name);
    }
  };

  return (
    <React.Fragment>
      {url !== "" ? (
        <Image
          src={url}
          imageStyle={{ height: "auto", width: "100%" }}
          style={{
            height: "auto",
            width: "100%",
          }}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}

      <input
        type="file"
        accept=".png"
        id={"button-file-" + index}
        style={{ display: "none" }}
        onChange={(event) => setPhoto(event)}
      />

      <label htmlFor={"button-file-" + index}>
        <Button
          component="span"
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<CloudUploadIcon />}
        >
          Upload
        </Button>
      </label>
    </React.Fragment>
  );
}
