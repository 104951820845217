import {
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  Typography,
} from "@mui/material";
import { FastField } from "formik";
import { RadioGroup, TextField } from "formik-mui";
import React from "react";

import {
  climateControleConstants,
  heatedSeatsConstants,
  interiorTypeConstants,
  passengersConstants,
  yesNoConstants,
} from "./constants.js";

export default function StocksOptionsOptionsForm({ isSubmitting }) {
  return (
    <React.Fragment>
      <Grid item align="left" xs={12}>
        <Typography variant="h6">Options</Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          component={TextField}
          name="interiorColor"
          label="Interior color"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Interior type</InputLabel>
        <FastField
          component={RadioGroup}
          name="interiorType"
          label="Interior type"
          variant="outlined"
        >
          {interiorTypeConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} sm={6} align="left">
        <InputLabel>Power Seat</InputLabel>
        <FastField
          component={RadioGroup}
          name="powerSeat"
          label="Power Seat"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} sm={6} align="left">
        <InputLabel>PASS</InputLabel>
        <FastField
          component={RadioGroup}
          name="pass"
          label="PASS"
          variant="outlined"
        >
          {passengersConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Back Up Camera</InputLabel>
        <FastField
          component={RadioGroup}
          name="backUpCamera"
          label="Back Up Camera"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>xmRadio</InputLabel>
        <FastField
          component={RadioGroup}
          name="xmRadio"
          label="xmRadio"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>NAV</InputLabel>
        <FastField
          component={RadioGroup}
          name="nav"
          label="NAV"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>WIFI Hot Spot</InputLabel>
        <FastField
          component={RadioGroup}
          name="wifiHotSpot"
          label="WIFI Hot Spot"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>blueTooth Audio</InputLabel>
        <FastField
          component={RadioGroup}
          name="blueToothAudio"
          label="blueTooth Audio"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Apple Car Play</InputLabel>
        <FastField
          component={RadioGroup}
          name="appleCarPlay"
          label="Apple Car Play"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Android Auto</InputLabel>
        <FastField
          component={RadioGroup}
          name="androidAuto"
          label="Android Auto"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Climate Controle</InputLabel>
        <FastField
          component={RadioGroup}
          name="climateControle"
          label="Climate Controle"
          variant="outlined"
        >
          {climateControleConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Heated Seats</InputLabel>
        <FastField
          component={RadioGroup}
          name="heatedSeats"
          label="Heated Seats"
          variant="outlined"
        >
          {heatedSeatsConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Heated Steering Wheel</InputLabel>
        <FastField
          component={RadioGroup}
          name="heatedSteeringWheel"
          label="Heated Steering Wheel"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Cooled Seats</InputLabel>
        <FastField
          component={RadioGroup}
          name="cooledSeats"
          label="Cooled Seats"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
    </React.Fragment>
  );
}
