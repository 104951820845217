import React, { Component } from "react";
import IdleTimer from "react-idle-timer";
import { auth } from "services/firebase";

import App from "./App";

export default class MyAppWithTimout extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onIdle = this._onIdle.bind(this);
  }

  render() {
    return (
      <div>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onIdle={this.onIdle}
          debounce={250}
          timeout={60000 * 30}
        />
        <App />
      </div>
    );
  }

  _onIdle(e) {
    console.log("last active", this.idleTimer.getLastActiveTime());

    auth.signOut();
    window.location.reload(true);
  }
}
