import { Box, Button, Tooltip, Typography } from "@mui/material";
import ColorIconText from "common/ColorIconText";
import React from "react";
import ClampLines from "react-clamp-lines";
import { auth } from "services/firebase";

import { abbreviationsMapper } from "../InventoryFilter/constants";

function handleWebURLCopy(url) {
  navigator.clipboard.writeText(url);
}

function customerSortForCost(a, b, key) {
  const aFloat = parseFloat(a[key]);
  const bFloat = parseFloat(b[key]);

  if (aFloat > bFloat) {
    return -1;
  }
  if (aFloat < bFloat) {
    return 1;
  }

  return 0;
}

function setDefaultColumnsValue(classes) {
  return [
    {
      title: "LotNo",
      field: "lotNo",
      render: (rowData) => (
        <Tooltip title={abbreviationsMapper[rowData.lotNo]}>
          <span>{rowData.lotNo}</span>
        </Tooltip>
      ),
    },
    { title: "Stock", field: "stock" },
    { title: "Vehicle Make", field: "vehicleMake" },
    { title: "Vehicle Model", field: "vehicleModel" },
    { title: "Vehicle Year", field: "vehicleYear" },
    { title: "Status", field: "vehicleStatus" },
    { title: "Recall Pending", field: "recallPending" },
    { title: "Vehicle Mileage", field: "kmReading" },
    {
      title: "Exterior Color",
      field: "color",
      render: (rowData) => <ColorIconText color={rowData.color} />,
    },
    {
      title: "Interior Color",
      field: "interiorColor",
      render: (rowData) => <ColorIconText color={rowData.interiorColor} />,
    },
    { title: "Transmission", field: "trans" },
    {
      title: "Retail Price",
      field: "price",
      render: (rowData) => (
        <Typography style={{ fontSize: "12px" }}>{rowData.price} $</Typography>
      ),
      customSort: (a, b) => {
        return customerSortForCost(a, b, "price");
      },
    },
    {
      title: "Web Price",
      field: "webPrice",
      render: (rowData) => (
        <Typography style={{ fontSize: "12px" }}>
          {rowData.webPrice} $
        </Typography>
      ),
      customSort: (a, b) => {
        return customerSortForCost(a, b, "webPrice");
      },
    },
    { title: "VIN", field: "vin" },
    {
      title: "Web URL",
      field: "webURL",
      filtering: false,
      sorting: false,
      render: (rowData) => (
        <React.Fragment>
          <Box display="flex">
            <Button
              fullWidth
              size="small"
              color="primary"
              variant="contained"
              style={{ marginRight: "5px" }}
              href={rowData.webURL}
              disabled={rowData.webURL === ""}
              target="_blank"
            >
              Go
            </Button>
            <Button
              fullWidth
              color="primary"
              size="small"
              variant="contained"
              disabled={rowData.webURL === ""}
              onClick={() => handleWebURLCopy(rowData.webURL)}
            >
              Copy
            </Button>
          </Box>
        </React.Fragment>
      ),
    },
    { title: "Date Safety", field: "dateSafety" },
    { title: "Last battery charge", field: "lastBatteryCharge" },
    { title: "Date Pictures", field: "datePictures" },
    { title: "Date Other", field: "dateOther" },
    { title: "Days", field: "days" },
    { title: "Claim ?", field: "accident" },
    {
      title: "Vehicle declarations",
      field: "vehicleDeclarations",
      render: (rowData) => (
        <ClampLines
          text={rowData.vehicleDeclarations}
          lines={1}
          moreText="More"
          lessText="Collapse"
          innerElement="p"
          className={classes.clampLinesCustom}
        />
      ),
    },
    {
      title: "Claim cost",
      field: "accidentCost",
      render: (rowData) => (
        <Typography style={{ fontSize: "12px" }}>
          {rowData.accidentCost} $
        </Typography>
      ),
      customSort: (a, b) => {
        return customerSortForCost(a, b, "accidentCost");
      },
    },
    { title: "Pending?", field: "pending" },
    {
      title: "Cost",
      field: "vehicleTotalCost",
      render: (rowData) => (
        <Typography style={{ fontSize: "12px" }}>
          {rowData.vehicleTotalCost} $
        </Typography>
      ),
      customSort: (a, b) => {
        return customerSortForCost(a, b, "vehicleTotalCost");
      },
    },
  ];
}

function getInventoryColumns(classes) {
  let columns = setDefaultColumnsValue(classes);
  const isUserAdmin = auth.currentUser.permission.customClaims.includes(
    "admin"
  );

  const otherColumnsName = [
    "androidAuto",
    "appleCarPlay",
    "backUpCamera",
    "bedOptions",
    "blockHeater",
    "blueToothAudio",
    "box",
    "cab",
    "climateControle",
    "cooledSeats",
    "cyl",
    "driveTrain",
    "engineType",
    "fogLights",
    "fuelType",
    "heatedSeats",
    "heatedSteeringWheel",
    "interiorType",
    "keyless",
    "nav",
    "notes",
    "packages",
    "passengers",
    "pictureDoneDate",
    "powerSeat",
    "pushToStart",
    "rearDiffLock",
    "remStrt",
    "sunRoof",
    "trailerBrakeControle",
    "trailerHitch",
    "webURL",
    "wifiHotSpot",
    "xmRadio",
  ];

  if (isUserAdmin) {
    for (const element of otherColumnsName) {
      columns.push({
        title: element,
        field: element,
      });
    }
  }

  return columns;
}
export default getInventoryColumns;
