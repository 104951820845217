import axios from "axios";
import https from "https";

const IP_ENDPOINT = "https://api.ipify.org?format=json";
const OFFICE_IP = ["209.91.147.58", "24.200.194.17"]; // 24.200.194.17 is Justin IP

async function getIP() {
  try {
    const agent = new https.Agent({
      rejectUnauthorized: false,
    });
    const json = await axios.get(IP_ENDPOINT, { httpsAgent: agent });
    const ip = json.data.ip;

    return ip;
  } catch (error) {
    console.error("Cannot get the IP with error : ", error);
    throw error;
  }
}

async function isOfficeIP() {
  try {
    const ip = await getIP();
    return OFFICE_IP.includes(ip);
  } catch (error) {
    console.error(error);
    return false;
  }
}

export { getIP, isOfficeIP };
