import { Snackbar as MaterialSnackBar, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import PropTypes from "prop-types";
import React from "react";

const propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  severity: PropTypes.string,
};

const defaultProps = {
  message: "",
  onClose: null,
  open: false,
  severity: "",
};

const SnackbarAlert = ({ severity, open, onClose, message }) => (
  <MaterialSnackBar
    autoHideDuration={6000}
    open={open}
    onClose={onClose}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
  >
    <Alert
      onClose={onClose}
      severity={severity || "info"}
      elevation={6}
      variant="filled"
    >
      <Typography variant="h6">{message}</Typography>
    </Alert>
  </MaterialSnackBar>
);

SnackbarAlert.propTypes = propTypes;
SnackbarAlert.defaultProps = defaultProps;

SnackbarAlert.success = "success";
SnackbarAlert.error = "error";

export default SnackbarAlert;
