const versions = {
  inventory: {
    version: "1.1.4",
    hash: "9045186471981b9fc2b84406a6164ced5a5b3dcb",
  },
  admin: {
    version: "1.0.0",
    hash: "184d47bdd7665d6acdc0f2e9910d21dfb536228f",
  },
  "quick-update": {
    version: "1.2.0",
    hash: "9045186471981b9fc2b84406a6164ced5a5b3dcb",
  },
  "new-stock-intake": {
    version: "1.2.1",
    hash: "9045186471981b9fc2b84406a6164ced5a5b3dcb",
  },
  "register-a-lead": {
    version: "1.0.0",
    hash: "184d47bdd7665d6acdc0f2e9910d21dfb536228f",
  },
  "register-a-lead-dashboard": {
    version: "1.0.2",
    hash: "184d47bdd7665d6acdc0f2e9910d21dfb536228f",
  },
  "onboarding-request": {
    version: "1.0.0",
    hash: "184d47bdd7665d6acdc0f2e9910d21dfb536228f",
  },
  "follow-up": {
    version: "1.0.0",
    hash: "184d47bdd7665d6acdc0f2e9910d21dfb536228f",
  },
  crm: {
    version: "1.0.0",
  },
  "user-activity": {
    version: "1.1.0",
  },
  "user-management": {
    version: "1.0.0",
  },
  "inventory-movement-request": {
    version: "1.0.0",
  },
  "admin-heat-map": {
    version: "1.0.0",
  },
  "admin-email-management": {
    version: "1.1.0",
  },
  "email-generator": {
    version: "1.1.0",
  },
};

export { versions };
