import LogRocket from "logrocket";
import { auth, users } from "services/firebase";
import { setUser } from "store/actions/user";

export default async function initStoreCurrentUser(store) {
  await auth.onAuthStateChanged(function (user) {
    if (user) {
      // Call database to get the user role
      let ref = users.doc(user.uid);
      ref
        .get()
        .then(async function (doc) {
          if (doc.exists) {
            user.permission = doc.data();

            // Identify the user for logRocket
            LogRocket.identify(user.uid, {
              name: user.fullName,
              email: user.email,
            });

            store.dispatch(setUser(user)); // TODO set to user when active login
          } else {
            store.dispatch(setUser(null));
          }
          return;
        })
        .catch(function (error) {
          store.dispatch(setUser(null));
          console.log("Error getting document:", error);
          return;
        });
    } else {
      store.dispatch(setUser(null));
      return;
    }
  });
}
