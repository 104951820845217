import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListIcon from "@mui/icons-material/List";
import React from "react";
import { useHistory } from "react-router-dom";
import r from "routes";
import { versions } from "versions";

export default function SalesTools({ handleRedirect, handleAlert, classes }) {
  const history = useHistory();

  function handleRegisterALead() {
    history.push(r.defaultRegisterALead.path);
    handleRedirect();
  }

  function handleRegisterALeadResult() {
    history.push(r.registerALeadDashboard.path);
    handleRedirect();
  }

  function handleCRMClick() {
    const url = "https://crmpro.sm360.ca/";
    var win = window.open(url, "_blank");
    win.focus();
  }

  return (
    <Grid item align="center" xs={12}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AttachMoneyIcon />
          <Typography className={classes.heading}>Sales</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List component="div" disablePadding className={classes.list}>
            <ListItem
              button
              name={
                "Register A Lead (" + versions["register-a-lead"].version + ")"
              }
              onClick={handleRegisterALead}
              className={classes.nested}
            >
              <ListItemIcon>
                <AssignmentTurnedInIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Register A Lead"}
                secondary={versions["register-a-lead"].version}
              />
            </ListItem>

            <ListItem
              button
              name={
                "Register A Lead - Results (" +
                versions["register-a-lead-dashboard"].version +
                ")"
              }
              onClick={handleRegisterALeadResult}
              className={classes.nested}
            >
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Register A Lead - Results"}
                secondary={versions["register-a-lead"].version}
              />
            </ListItem>

            <ListItem
              button
              name={"CRM (" + versions["crm"].version + ")"}
              onClick={handleCRMClick}
              className={classes.nested}
            >
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="CRM" secondary={versions["crm"].version} />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
