const vehicleCurrentLocationConstants = [
  {
    value: "TO",
    label: "TORONTO",
  },
  {
    value: "TR",
    label: "TRENTON",
  },
  {
    value: "SU",
    label: "SUDBURY",
  },
  {
    value: "OS",
    label: "OSHAWA",
  },
  {
    value: "TB",
    label: "THUNDER BAY",
  },
  {
    value: "IT",
    label: "IN TRANSIT",
  },
  {
    value: "TAT",
    label: "TOTAL AUTO TRANSPORT",
  },
  {
    value: "NL",
    label: "NEW LISKEARD",
  },
];

const startConstants = [
  {
    value: "YES",
    label: "YES",
  },
  {
    value: "NO",
    label: "NO",
  },
  {
    value: "NA",
    label: "NA",
  },
];

const numberOfKeys = [
  {
    value: "0",
    label: "0",
  },
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4+",
    label: "4+",
  },
];

export { vehicleCurrentLocationConstants, startConstants, numberOfKeys };
