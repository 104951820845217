const filter = {
  ".": false,
  AUA: false,
  IT: false,
  AUM: false,
  OS: false,
  SU: false,
  TH: false,
  TO: false,
  TR: false,
  NL: false,
  US01: false,
  US02: false,
  US03: false,
  US04: false,
  US05: false,
  US11: false,
  US2: false,
  US3: false,
  USP: false,
  USP3: false,
  ALL: true,
};

const enableFilter = ["OS", "SU", "TO", "TR", "TH", "NL"];

const abbreviationsMapper = {
  OS: "OSHAWA",
  SU: "SUDBURY",
  TO: "TORONTO",
  TR: "TRENTON",
  TH: "THUNDER BAY",
  NL: "NEW LISKEARD",
  TGC: "TGC",
  IT: "IN TRANSIT",
  TAT: "TOTAL AUTO TRANSPORT",
  AUM: "MANHEIM MILTON",
  NA: "NO LOCATION AS PER DMS",
  US: "SENT TO USA – NO DATA",
  US01: "SENT TO USA - JANUARY",
  US02: "SENT TO USA - FEBRUARY",
  US03: "SENT TO USA - MARCH",
  US04: "SENT TO USA - APRIL",
  US05: "SENT TO USA - MAY",
  US06: "SENT TO USA - JUNE",
  US07: "SENT TO USA - JULY",
  US08: "SENT TO USA - AUGUST",
  US09: "SENT TO USA - SEPTEMBER",
  US10: "SENT TO USA - OCTOBER",
  US11: "SENT TO USA - NOVEMBER",
  US12: "SENT TO USA - DECEMBER",
  "TO-R": "TORONTO - RECONDITIONING",
  "TH-R": "THUNDER BAY - RECONDITIONING",
  "SU-R": "SUDBURY - RECONDITIONING",
  "TR-R": "TRENTON - RECONDITIONING",
  "OS-R": "OSHAWA - RECONDITIONING",
  "IT-T": "IN TRANSIT - PICKED UP BY TOTAL AUTO TRANSPORT",
};

export { filter, enableFilter, abbreviationsMapper };
