import MaterialTable from "@material-table/core";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Hidden,
} from "@mui/material";
import ColorIconText from "common/ColorIconText";
import MaterialTableResponsive, {
  MaterialTableTypesEnum,
} from "common/MaterialTableResponsive";
import MD5 from "crypto-js/md5";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import r from "routes";
import { database } from "services/firebase";

export default function NewStockIntakeDashboard({ handleClose }) {
  const [data, setData] = useState();
  const history = useHistory();

  function handleOnClick(data) {
    // eslint-disable-next-line new-cap
    const stockId = MD5(data.stock + data.vin).toString();
    history.push(
      r.newStockIntake.modify({
        stockId: stockId,
      })
    );
    handleClose();
  }

  useEffect(() => {
    database
      .ref("inventorySync/")
      .once("value")
      .then(function (snapshot) {
        var obj = snapshot.val();
        var array = [];
        Object.keys(obj).forEach(function (key) {
          array.push(obj[key]);
        });
        setData(array);
      });
  }, []);

  if (data === undefined) {
    return (
      <Box align="center" component="span" m={0}>
        <Container>
          <CircularProgress />
        </Container>
      </Box>
    );
  }

  return (
    <Box component="span" m={0}>
      <Container>
        <Box component="span" m={0}>
          <Hidden mdUp>
            <MaterialTableResponsive
              columns={[
                { title: "Stock", field: "stock" },
                { title: "Vehicle", field: "vehicle" },
                { title: "VIN", field: "vin" },
                { title: "Exterior Color", field: "color" },
                { title: "New stock Intake", field: "stock" },
              ]}
              data={data}
              title="New stock Intake"
              type={MaterialTableTypesEnum.NEW_STOCK_INTAKE}
              handleOnClick={handleOnClick}
            />
          </Hidden>
        </Box>

        <Box component="span" m={0}>
          <Hidden mdDown>
            <div style={{ maxWidth: "100%" }}>
              <MaterialTable
                options={{
                  draggable: false,
                  search: true,
                  filtering: true,
                  sorting: true,
                  pageSize: 10,
                }}
                columns={[
                  { title: "Stock", field: "stock" },
                  { title: "Vehicle", field: "vehicle" },
                  { title: "VIN", field: "vin" },
                  {
                    title: "Exterior Color",
                    field: "color",
                    render: (rowData) => (
                      <ColorIconText color={rowData.color} />
                    ),
                  },
                  {
                    title: "New stock intake",
                    field: "stock",
                    filtering: false,
                    render: (rowData) => (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleOnClick(rowData)}
                      >
                        Go to this form
                      </Button>
                    ),
                  },
                ]}
                data={data}
                title=""
              />
            </div>
          </Hidden>
        </Box>
      </Container>
    </Box>
  );
}
