import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { Button, Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BackDropLoading from "common/BackDropLoading";
import SnackbarAlert from "common/SnackbarAlert";
import MD5 from "crypto-js/md5";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import r from "routes";
import {
  auth,
  database,
  firebase,
  firestore,
  quickUpdateCollection,
  storage,
} from "services/firebase";
import * as Yup from "yup";

import { initialValues } from "../StockOptions/constants";
import {
  StocksOptionsPhotosForm,
  StocksOptionsValidationForm,
  StocksOptionsVehiculeForm,
} from "../StockOptions/StockOptionsForm";
import styles from "./QuickUpdate.css.js";
import QuickUpdateSearchInput from "./QuickUpdateSearchInput";

const useStyles = makeStyles(styles);

function QuickUpdate() {
  const classes = useStyles();
  const history = useHistory();
  const { stockId } = useParams();
  const [fileList, setFileList] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState({});
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [data, setData] = useState();

  function compareTime(a, b) {
    const dateA = new Date(a.time);
    const dateB = new Date(b.time);

    if (dateA < dateB) {
      return 1;
    }
    if (dateA > dateB) {
      return -1;
    }
    return 0;
  }

  function setDateDefaultValue(data) {
    if (data.lastBatteryCharge === "" || data.lastBatteryCharge === "  /  /") {
      data.lastBatteryCharge = null;
    }
    if (data.lastDriven === "" || data.lastDriven === "  /  /") {
      data.lastDriven = null;
    }
    if (data.pictureDoneDate === "" || data.pictureDoneDate === "  /  /") {
      data.pictureDoneDate = null;
    }
    return data;
  }

  useEffect(() => {
    if (stockId) {
      database
        .ref("inventorySync/" + stockId)
        .once("value")
        .then(function (snapshot) {
          let d = snapshot.val();
          if (d) {
            d = setDateDefaultValue(d);
            setData(d);
          } else {
            // Check if the that is from a past form in QuickUpdate
            firestore
              .collectionGroup(stockId)
              .get()
              .then((temp) => {
                var array = [];
                temp.forEach((doc) => {
                  array.push(doc.data());
                });
                array.sort(compareTime);

                let quickUpdateData = array[0];
                if (quickUpdateData) {
                  quickUpdateData = setDateDefaultValue(quickUpdateData);
                }

                setData(quickUpdateData);
              });
          }
        });
    } else {
      setData(initialValues);
    }
  }, [stockId, history]);

  const validateValues = Yup.object().shape({
    vin: Yup.string()
      .required()
      .matches(/^((([a-hj-zA-NPR-Z0-9]){8})?|([a-hj-zA-NPR-Z0-9]{17})?)$/, {
        message: "You VIN need to have 8 or 17 caractor and no i, O or Q",
        excludeEmptyString: true,
      }),
  });

  function handleNewBlankForm() {
    history.push(r.defaultQuickUpdate.path);
  }

  function handleFinalizeSend() {
    setLoadingProgress(0);
    history.push(r.root.path);
  }

  if (data === undefined) {
    return <BackDropLoading open={true} />;
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={data}
        onSubmit={save}
        enableReinitialize={true}
        validationSchema={validateValues}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form autoComplete="on">
            <Container className={classes.root}>
              <Grid container spacing={4}>
                <Grid item align="center" sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleNewBlankForm}
                    className={classes.button}
                    startIcon={<DirectionsCarIcon />}
                  >
                    Select vehicle
                  </Button>
                </Grid>

                {!stockId ? (
                  <Grid item sm={12} xs={12}>
                    <QuickUpdateSearchInput />
                  </Grid>
                ) : null}

                {stockId ? (
                  <React.Fragment>
                    <StocksOptionsVehiculeForm></StocksOptionsVehiculeForm>

                    <StocksOptionsValidationForm
                      setFieldValue={setFieldValue}
                      isSubmitting={isSubmitting}
                    ></StocksOptionsValidationForm>

                    <StocksOptionsPhotosForm
                      stockId={stockId}
                      handleFileListSend={handleFileListSend}
                    ></StocksOptionsPhotosForm>

                    <Grid item xs={12} sm={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        SEND
                      </Button>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                <BackDropLoading
                  open={
                    loadingProgress > 0 && snackbarMessage.message === undefined
                  }
                  progress={loadingProgress}
                  handleClose={() => {
                    handleFinalizeSend();
                  }}
                />

                <SnackbarAlert
                  open={snackbarMessage.message !== undefined}
                  message={snackbarMessage.message}
                  severity={snackbarMessage.severity}
                  onClose={() => setSnackbarMessage({})}
                />
              </Grid>
            </Container>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );

  // TODO This have to be generalise
  function handleFileListSend(fileList) {
    setFileList(fileList);
  }

  async function save(
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) {
    setLoadingProgress(1);
    setSubmitting(true);

    if (values.lastDriven) {
      values.lastDriven = values.lastDriven.toString();
    } else {
      values.lastDriven = "";
    }

    if (values.lastBatteryCharge) {
      values.lastBatteryCharge = values.lastBatteryCharge.toString();
    } else {
      values.lastBatteryCharge = "";
    }

    if (values.pictureDoneDate) {
      values.pictureDoneDate = values.pictureDoneDate.toString();
    } else {
      values.pictureDoneDate = "";
    }

    var now = new Date(
      new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
      })
    );
    now = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    const time = now.toISOString().slice(0, -1);

    values.time = time;
    values.user = {
      uid: auth.currentUser.uid,
      displayName: auth.currentUser.displayName,
      email: auth.currentUser.email,
    };

    // Send error if we have a problem on the save procress
    try {
      const newValuesWithImage = await sendImageToBucket(values);
      setLoadingProgress(33);

      await saveInDataBase(newValuesWithImage);
      setLoadingProgress(66);

      await sendEmail(newValuesWithImage);
      setLoadingProgress(100);
    } catch (error) {
      console.log("Error on quick update save process : ", error);

      setSubmitting(false);
      setSnackbarMessage({
        message: "Problem with your request, please try again later",
        severity: SnackbarAlert.error,
      });

      values = setDateDefaultValue(values);
      return 0;
    }
    resetForm();
  }

  async function sendImageToBucket(values) {
    let id;
    let dowloadURLObject = {};
    const stockNumber = values.stock;
    const vin = values.vin;

    // This make possible to update a car with no stockNumber
    if (stockId) {
      id = stockId;
    } else {
      // eslint-disable-next-line new-cap
      id = MD5(stockNumber + vin);
    }

    var metadata = {
      contentType: "image/png",
    };

    if (fileList) {
      await Promise.all(
        fileList.map(async (fileObject) => {
          const isDamage = fileObject.damage;
          const key = Object.keys(fileObject)[0];
          const name =
            Object.keys(fileObject)[0] + (isDamage ? "-DOMMAGE" : "");
          var storageRef = storage.ref("images/" + id + "/" + name + ".png");
          const snapshot = await storageRef.put(fileObject[key], metadata);
          const dowloadURL = await snapshot.ref.getDownloadURL();
          dowloadURLObject[key] = { url: dowloadURL, damage: isDamage };
        })
      );
    }
    values.photos = dowloadURLObject;
    return values;
  }

  function sendEmail(values) {
    var sendEmailDataCallable = firebase
      .functions()
      .httpsCallable("sendEmailDataCallable");

    values.type = "quickUpdate";
    return sendEmailDataCallable(values);
  }

  function saveInDataBase(values) {
    return new Promise(async (resolve, reject) => {
      let id;
      const stockNumber = values.stock;
      const vin = values.vin;

      // This make possible to update a car with no stockNumber
      if (stockId) {
        id = stockId;
      } else {
        if (stockNumber) {
          // eslint-disable-next-line new-cap
          id = MD5(stockNumber + vin);
        } else {
          // eslint-disable-next-line new-cap
          id = MD5(vin);
        }
      }
      values.uid = id;
      values.isEmailSend = false;

      if (values.vehicleNeedHeavyRecon === "YES") {
        values.vehicleCurrentLocation = values.vehicleCurrentLocation + "-R";
      }

      try {
        // Save the information to Firestore
        await quickUpdateCollection
          .doc(values.time)
          .collection(id)
          .doc(values.user.email)
          .set(values);

        // Add information to date doc
        await quickUpdateCollection.doc(values.time).set({
          time: values.time,
          user_email: values.user.email,
          user_uid: values.user.uid,
          user_name: values.user.displayName,
          car_uid: id,
        });

        resolve();
      } catch (error) {
        console.log("Error on quick update save process : ", error);
        reject(error);
      }
    });
  }
}
export default QuickUpdate;
