import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

import styles from "./BackDropLoading.css.js";
const useStyles = makeStyles(styles);

function CircularProgressWithLabel(props) {
  if (props.value === 100) {
    return (
      <Box sx={{ position: "relative", display: "block", textAlign: "center" }}>
        <div>
          <CheckCircleOutlineIcon sx={{ color: "green", fontSize: "5em" }} />
        </div>
        <Typography variant="h4" component="div" color="text.secondary">
          {" "}
          Complete{" "}
        </Typography>
        <Button
          sx={{ marginTop: "1em" }}
          variant="contained"
          onClick={() => props.handleClose()}
        >
          Close
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function BackDropLoading({ open, progress, handleClose }) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Paper className={classes.paper}>
        {progress ? (
          <CircularProgressWithLabel
            variant="determinate"
            value={progress}
            handleClose={() => handleClose()}
          />
        ) : (
          <CircularProgress color="inherit" />
        )}
      </Paper>
    </Backdrop>
  );
}
