import Image from "@jy95/material-ui-image";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Icon,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import microsoftLogo from "assets/Login/Microsoft_logo.svg";
import logo from "assets/logo.png";
import React, { useState } from "react";
import { connect } from "react-redux";
import { auth, firebase, users } from "services/firebase";
import { setUser } from "store/actions/user";

import styles from "./Login.css";
const useStyles = makeStyles(styles);

function Login({ dispatch }) {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  async function saveUser(data) {
    setLoading(true);
    await delay(10000); // Let the backgroup function do the job

    // Call database to get the user role
    let ref = users.doc(data.user.uid);
    ref
      .get()
      .then(function (doc) {
        if (doc.exists) {
          data.user.permission = doc.data();
          dispatch(setUser(data.user));
        } else {
          saveUser(data); // Retry to wait for the firestore
        }
      })
      .catch(function (error) {
        dispatch(setUser(null));
        console.log("Error getting document:", error);
      });
  }

  async function loginWithMicrosoft() {
    setLoading(true);
    var provider = new firebase.auth.OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      tenant: "12a41f89-3e38-4f00-b5cd-1df641167352", // This is the tenantId of the client
    });
    await auth
      .signInWithPopup(provider)
      .then(async function (data) {
        await saveUser(data);
        return data;
      })
      .catch(function (error) {
        setErrorMessage(error.message);
        return error;
      });
  }

  if (loading && !errorMessage) {
    return (
      <form
        label="loginForm"
        className={classes.root}
        noValidate
        autoComplete="on"
      >
        <Container className={classes.root}>
          <Paper className={classes.paper} elevation={3} padding="dense">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h4">Login</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  We are setting up your account. Can take up to 15 seconds
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CircularProgress />
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </form>
    );
  }

  return (
    <form
      label="loginForm"
      className={classes.root}
      noValidate
      autoComplete="on"
    >
      <Container className={classes.root}>
        <Paper className={classes.paper} elevation={3} padding="dense">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Image
                src={logo}
                imageStyle={{ height: "200px", width: "auto" }}
                style={{
                  paddingTop: "180px",
                  width: "100%",
                }}
              />
            </Grid>

            <Grid item xs={12}>
              {errorMessage ? (
                <Paper className={classes.error}>
                  <Typography variant="body1">{errorMessage}</Typography>
                </Paper>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                id="loginWithMicrosoft"
                variant="contained"
                color="primary"
                onClick={loginWithMicrosoft}
              >
                <Icon className={classes.iconPlacement}>
                  <img
                    alt="microsoft-login"
                    className={classes.imageIcon}
                    src={microsoftLogo}
                  />
                </Icon>
                {"Connect with Microsoft"}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </form>
  );
}
export default connect()(Login);
