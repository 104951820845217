const styles = (theme) => ({
  colorIcon: {
    width: "20px",
    height: "20px",
    display: "inline-block",
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: "10px",
    verticalAlign: "middle",
  },
});

export default styles;
