import React from "react";
import { connect } from "react-redux";

function PermissionWrapper({ currentUser, permission, children }) {
  if (currentUser) {
    if (
      currentUser.permission.customClaims.includes(permission) ||
      permission === undefined
    ) {
      return <React.Fragment>{children} </React.Fragment>;
    }
  }

  return <React.Fragment />;
}

const mapStateToProps = (state) => ({
  currentUser: state.user,
});

export default connect(mapStateToProps)(PermissionWrapper);
