import "date-fns";

import {
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  Typography,
} from "@mui/material";
import { FastField } from "formik";
import { RadioGroup, TextField } from "formik-mui";
import React from "react";

import { sunRoofConstants, yesNoConstants } from "./constants.js";

export default function StocksOptionsOthersForm({ isSubmitting }) {
  return (
    <React.Fragment>
      <Grid item align="left" xs={12}>
        <Typography variant="h6">Others</Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Fog Lights</InputLabel>
        <FastField
          component={RadioGroup}
          name="fogLights"
          label="Fog Lights"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Rem Strt</InputLabel>
        <FastField
          component={RadioGroup}
          name="remStrt"
          label="Rem Strt"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Sun Roof</InputLabel>
        <FastField
          component={RadioGroup}
          name="sunRoof"
          label="Sun Roof"
          variant="outlined"
        >
          {sunRoofConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Trailer brake controle</InputLabel>
        <FastField
          component={RadioGroup}
          name="trailerBrakeControle"
          label="Trailer brake controle"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Trailer hitch</InputLabel>
        <FastField
          component={RadioGroup}
          name="trailerHitch"
          label="Trailer hitch"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Push to start</InputLabel>
        <FastField
          component={RadioGroup}
          name="pushToStart"
          label="Push to start"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Keyless</InputLabel>
        <FastField
          component={RadioGroup}
          name="keyless"
          label="Keyless"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          component={TextField}
          name="cab"
          label="Cab"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          component={TextField}
          name="box"
          label="Box"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          component={TextField}
          name="bedOptions"
          label="Bed Options"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FastField
          fullWidth
          component={TextField}
          name="other"
          label="Other"
          variant="outlined"
        />
      </Grid>
    </React.Fragment>
  );
}
