import "date-fns";

import {
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  Typography,
} from "@mui/material";
import { FastField } from "formik";
import { RadioGroup, TextField } from "formik-mui";
import React from "react";

import {
  CYLConstants,
  driveTrainConstants,
  fuelTypeConstants,
  transConstants,
  yesNoConstants,
} from "./constants.js";

export default function StocksOptionsPowerTrainForm({ isSubmitting }) {
  return (
    <React.Fragment>
      <Grid item align="left" xs={12}>
        <Typography variant="h6">Power Train</Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          component={TextField}
          name="engineType"
          label="Engine Type"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Rear DiffLock</InputLabel>
        <FastField
          component={RadioGroup}
          name="rearDiffLock"
          label="Rear DiffLock"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Block Heater</InputLabel>
        <FastField
          component={RadioGroup}
          name="blockHeater"
          label="Block Heater"
          variant="outlined"
        >
          {yesNoConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Drive Train</InputLabel>
        <FastField
          component={RadioGroup}
          name="driveTrain"
          label="Drive Train"
          variant="outlined"
        >
          {driveTrainConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Trans</InputLabel>
        <FastField
          component={RadioGroup}
          name="trans"
          label="Trans"
          variant="outlined"
        >
          {transConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>Fuel Type</InputLabel>
        <FastField
          component={RadioGroup}
          name="fuelType"
          label="Fuel Type"
          variant="outlined"
        >
          {fuelTypeConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={6}>
        <InputLabel>CYL</InputLabel>
        <FastField
          component={RadioGroup}
          name="cyl"
          label="CYL"
          variant="outlined"
        >
          {CYLConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
    </React.Fragment>
  );
}
