const styles = (theme) => ({
  root: {
    height: "100%",
    margin: 0,
    padding: 0,
  },
  map: {
    height: "100%",
  },
});

export default styles;
