import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

import styles from "./ChipFiltersResponsive.css.js";
const useStyles = makeStyles(styles);

export default function ChipFiltersResponsive(params) {
  const {
    filtersList,
    setFiltersList,
    filterFields,
    fieldFilterToAdd,
    valueFilterToAdd,
    setValueFilterToAdd,
    setFieldFilterToAdd,
  } = params;

  const classes = useStyles();

  return (
    <Box m={1} id={"chipFilters"} className={classes.chipWrap}>
      <Box m={1} className={classes.margin}>
        <Grid container justifyContent="center" spacing={1}>
          <Grid item className={classes.halfWidth}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel className={classes.formLabel}>
                Select field
              </InputLabel>
              <Select
                autoWidth
                size="small"
                value={fieldFilterToAdd}
                onChange={(event) => setFieldFilterToAdd(event.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {filterFields.map((field, index) => (
                  <MenuItem key={index} value={field.field}>
                    {field.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item className={classes.halfWidth}>
            <TextField
              fullWidth
              variant="outlined"
              label="Filter value"
              size="small"
              name={"filter-value"}
              value={valueFilterToAdd}
              onChange={(event) => setValueFilterToAdd(event.target.value)}
            />
          </Grid>
        </Grid>
      </Box>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        name={"add-filter"}
        disabled={valueFilterToAdd === "" || fieldFilterToAdd === ""}
        onClick={() => {
          setFiltersList((current_filtersList) => {
            return [
              ...current_filtersList,
              {
                field: fieldFilterToAdd,
                fieldText: filterFields.find(
                  (field) => field.field === fieldFilterToAdd
                ).title,
                value: valueFilterToAdd,
              },
            ];
          });
          setValueFilterToAdd("");
          setFieldFilterToAdd("");
        }}
      >
        Add filter
      </Button>
      <Box m={1}>
        {filtersList.map((filter, index) => (
          <Chip
            key={index}
            label={`${filter.fieldText}: ${filter.value}`}
            className={classes.chip}
            color="primary"
            onDelete={() =>
              setFiltersList((current_filtersList) =>
                current_filtersList.filter(
                  (currentFilter) => currentFilter.field !== filter.field
                )
              )
            }
          />
        ))}
      </Box>
    </Box>
  );
}
