const source = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml">
    <head>
        <title>*|MC:SUBJECT|*</title>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
        <!--[if !mso]><!--><meta http-equiv="X-UA-Compatible" content="IE=edge"/><!--<![endif]-->
        <meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0;"/>
        <meta name="x-apple-disable-message-reformatting"/>
        <meta name="format-detection" content="telephone=no, address=no, email=no, date=no"/>
        <!--[if (gte mso 9)|(IE)]><style type="text/css">table {border-collapse:collapse !important; mso-table-lspace:0pt; mso-table-rspace:0pt; mso-border-alt:0px;}</style><![endif]--><!--[if gte mso 9]><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml><![endif]-->
        <!-- <link href="css/main.css" rel="stylesheet"/> -->
        <style type="text/css">
    * {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    }
    html, body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }
    body {
    width: 100% !important;
    min-width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    font-family: Arial, sans-serif;
    mso-line-height-rule: exactly;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    }
    body, table, td, a, li {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    }
    table {
    border-spacing: 0;
    }
    table, td {
    border-collapse: collapse;
    mso-table-lspace: 0pt;
    mso-table-rspace: 0pt;
    }
    td {
    word-break: normal;
    border-collapse: collapse;
    border-collapse: collapse !important;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    }
    a {
    text-decoration: none;
    }
    a img {
    border: none;
    }
    a, img, table {
    max-width: 100%;
    }
    img {
    height: auto !important;
    line-height: 100%;
    border: 0;
    text-decoration: none;
    outline: none;
    -ms-interpolation-mode: bicubic;
    }
    a[x-apple-data-detectors] {
    color: inherit !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    text-decoration: none !important;
    }
    u + #body a, #MessageViewBody a {
    color: inherit;
    text-decoration: none;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    }
    .webkit {
    max-width: 600px;
    margin: 0px auto;
    }
    #outlook a {
    padding: 0;
    }
    .ReadMsgBody {
    width: 100%;
    }
    .ExternalClass {
    width: 100%;
    }
    .ExternalClass * {
    line-height: 140%;
    }
    .backgroundTable {
    width: 100% !important;
    margin: 0px auto;
    padding: 0;
    }
    .appleLinks a {
    color: #000001 !important;
    text-decoration: none;
    text-decoration: none !important;
    }
    .outer {
    width: 100%;
    max-width: 600px;
    }
    @media screen and (max-width: 599px) {
    .db1 {
    width: 100% !important;
    display: block !important;
    padding: 0px;
    }
    .center {
        text-align: center;
    }
    .pt20 {
    padding-top: 20px !important;
    }
    .ac {
        display: table !important; 
        margin-left: auto !important; 
        margin-right: auto !important; 
        float: none !important;
    }
    }
        </style>
    </head>
    <body bgcolor="#ffffff" style="background-color:#ffffff; width:100% !important; min-width:100% !important; height:100% !important; table-layout:fixed; margin:0 !important; padding:0 !important; font-family:Arial,sans-serif; border:0px;" class="body" id="body">
    <!-- WRAPPER STARTS -->
    <!-- 600 STARTS --><table align="left" class="outer" border="0" cellpadding="0" cellspacing="0" width="100%" style="width:100%; max-width:600px;"><!--[if mso]><tr><td align="left"><table border="0" cellpadding="0" cellspacing="0" width="600"><![endif]--><tr><td align="left" valign="top">
    <table width="100%" style="width:100%;" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">


    <!-- HEADER STARTS -->
    <tr>
        <td>
            <table width="100%" style="width:100%;" align="center" border="0" cellspacing="0" cellpadding="0">
                <tr>
                    <td class="db1" width="130" valign="top"><!-- COLUMN_1 STARTS -->
                        <table class="ac2" align="left" border="0" cellspacing="0" cellpadding="0">
                            <tr>
                                <td>
                                    {{#if office_name}}
                                        {{#if (eq office_name 'Need A Car')}}
                                            <a href="https://www.torontoautogroup.ca" target="_blank" style="color:#ccb413; text-decoration:underline;"><font color="#ccb413" style="color:#ccb413; text-decoration:underline;"><img src="https://firebasestorage.googleapis.com/v0/b/car-gestion-system.appspot.com/o/email-images%2FColors%2FNAC.png?alt=media&token=10268c9b-af17-4c31-9c80-7d4a8da7925d" alt="" border="0" width="110" style="width:110px; max-width:110px; border:0; outline:none; text-decoration:underline; display:block; font-family:Arial,sans-serif; text-align:center; font-size:30px; color:#ccb413; font-weight:600;"/></font></a>
                                        {{else if (eq office_name 'T.G.C. - Toronto Auto Group of Companies')}}
                                            <a href="https://www.torontoautogroup.ca" target="_blank" style="color:#ccb413; text-decoration:underline;"><font color="#ccb413" style="color:#ccb413; text-decoration:underline;"><img src="https://firebasestorage.googleapis.com/v0/b/car-gestion-system.appspot.com/o/email-images%2FColors%2FTAG.png?alt=media&token=10268c9b-af17-4c31-9c80-7d4a8da7925d" alt="" border="0" width="110" style="width:110px; max-width:110px; border:0; outline:none; text-decoration:underline; display:block; font-family:Arial,sans-serif; text-align:center; font-size:30px; color:#ccb413; font-weight:600;"/></font></a>
                                        {{else if (eq office_name 'Total Auto Transport')}}
                                            <a href="https://www.torontoautogroup.ca" target="_blank" style="color:#ccb413; text-decoration:underline;"><font color="#ccb413" style="color:#ccb413; text-decoration:underline;"><img src="https://firebasestorage.googleapis.com/v0/b/car-gestion-system.appspot.com/o/email-images%2FColors%2FTAT.png?alt=media&token=10268c9b-af17-4c31-9c80-7d4a8da7925d" alt="" border="0" width="110" style="width:110px; max-width:110px; border:0; outline:none; text-decoration:underline; display:block; font-family:Arial,sans-serif; text-align:center; font-size:30px; color:#ccb413; font-weight:600;"/></font></a>
                                        {{else if (eq office_name 'Total Auto Finance')}}
                                            <a href="https://www.torontoautogroup.ca" target="_blank" style="color:#ccb413; text-decoration:underline;"><font color="#ccb413" style="color:#ccb413; text-decoration:underline;"><img src="https://firebasestorage.googleapis.com/v0/b/car-gestion-system.appspot.com/o/email-images%2FColors%2FTAF.png?alt=media&token=10268c9b-af17-4c31-9c80-7d4a8da7925d" alt="" border="0" width="110" style="width:110px; max-width:110px; border:0; outline:none; text-decoration:underline; display:block; font-family:Arial,sans-serif; text-align:center; font-size:30px; color:#ccb413; font-weight:600;"/></font></a>
                                        {{else if (eq office_name 'Titan Auto Parts')}}
                                            <a href="https://www.torontoautogroup.ca" target="_blank" style="color:#ccb413; text-decoration:underline;"><font color="#ccb413" style="color:#ccb413; text-decoration:underline;"><img src="https://firebasestorage.googleapis.com/v0/b/car-gestion-system.appspot.com/o/email-images%2FColors%2FTAP.png?alt=media&token=10268c9b-af17-4c31-9c80-7d4a8da7925d" alt="" border="0" width="110" style="width:110px; max-width:110px; border:0; outline:none; text-decoration:underline; display:block; font-family:Arial,sans-serif; text-align:center; font-size:30px; color:#ccb413; font-weight:600;"/></font></a>
                                        {{else if (eq office_name 'Total Auto Services')}}
                                            <a href="https://www.torontoautogroup.ca" target="_blank" style="color:#ccb413; text-decoration:underline;"><font color="#ccb413" style="color:#ccb413; text-decoration:underline;"><img src="https://firebasestorage.googleapis.com/v0/b/car-gestion-system.appspot.com/o/email-images%2FColors%2FTAS.png?alt=media&token=10268c9b-af17-4c31-9c80-7d4a8da7925d" alt="" border="0" width="110" style="width:110px; max-width:110px; border:0; outline:none; text-decoration:underline; display:block; font-family:Arial,sans-serif; text-align:center; font-size:30px; color:#ccb413; font-weight:600;"/></font></a>
                                        {{else if (eq office_name 'Total Auto Warranty')}}
                                            <a href="https://www.torontoautogroup.ca" target="_blank" style="color:#ccb413; text-decoration:underline;"><font color="#ccb413" style="color:#ccb413; text-decoration:underline;"><img src="https://firebasestorage.googleapis.com/v0/b/car-gestion-system.appspot.com/o/email-images%2FColors%2FTAW.png?alt=media&token=10268c9b-af17-4c31-9c80-7d4a8da7925d" alt="" border="0" width="110" style="width:110px; max-width:110px; border:0; outline:none; text-decoration:underline; display:block; font-family:Arial,sans-serif; text-align:center; font-size:30px; color:#ccb413; font-weight:600;"/></font></a>
                                        {{else}}
                                            <a href="https://www.torontoautogroup.ca" target="_blank" style="color:#ccb413; text-decoration:underline;"><font color="#ccb413" style="color:#ccb413; text-decoration:underline;"><img src="https://firebasestorage.googleapis.com/v0/b/car-gestion-system.appspot.com/o/email-images%2FColors%2FDefault.png?alt=media&token=10268c9b-af17-4c31-9c80-7d4a8da7925d" alt="" border="0" width="110" style="width:110px; max-width:110px; border:0; outline:none; text-decoration:underline; display:block; font-family:Arial,sans-serif; text-align:center; font-size:30px; color:#ccb413; font-weight:600;"/></font></a> 
                                        {{/if}}
                                    {{else}}
                                        <a href="https://www.torontoautogroup.ca" target="_blank" style="color:#ccb413; text-decoration:underline;"><font color="#ccb413" style="color:#ccb413; text-decoration:underline;"><img src="https://firebasestorage.googleapis.com/v0/b/car-gestion-system.appspot.com/o/email-images%2FColors%2FDefault.png?alt=media&token=10268c9b-af17-4c31-9c80-7d4a8da7925d" alt="" border="0" width="110" style="width:110px; max-width:110px; border:0; outline:none; text-decoration:underline; display:block; font-family:Arial,sans-serif; text-align:center; font-size:30px; color:#ccb413; font-weight:600;"/></font></a>
                                    {{/if}}
                                </td>
                            </tr>
                        </table>
                    </td><!-- COLUMN_1 ENDS -->
                    
                    <td class="db1" style="padding-top: 22px;" valign="top"><!-- COLUMN_2 STARTS -->
                        <table width="100%" style="width:100%;" align="center" border="0" cellspacing="0" cellpadding="0">
                            <tr><!-- TITLE STARTS -->
                                <td class="pt20" style="font-family:Arial,sans-serif; text-align:left; font-size:30px; font-weight: bold; color:#06090b; mso-line-height-rule:exactly; line-height:1.2;">
                                    {{ full_name }}
                                </td>
                            </tr><!-- TITLE ENDS -->


                            {{#if job_title}}
                            <tr><!-- SUB_TITLE STARTS -->
                                <td style="font-family:Arial,sans-serif; text-align:left; font-size:20px; color:#817e7d; mso-line-height-rule:exactly; line-height:1.6;">
                                    {{ job_title }}
                                </td>
                            </tr><!-- SUB_TITLE ENDS -->
                            {{/if}}



                            <tr><!-- INFO STARTS -->
                                <td style="padding-top:30px; font-family:Arial,sans-serif; text-align:left; font-size:20px; font-weight: bold; color:#06090b; mso-line-height-rule:exactly; line-height:1.6;">
                                    {{#if department}}
                                        {{ department }}
                                        <br/>
                                    {{/if}}
                                    <table align="left" border="0" cellspacing="0" cellpadding="0">
                                        <tr><!-- ROW STARTS -->
                                            <td valign="top" width="29" style="width:29px; padding-top: 10px;">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/car-gestion-system.appspot.com/o/email-images%2Flocation-dot-solid.svg?alt=media&token=84be0aec-4e62-47b1-8c65-27690f663145" alt="" border="0" width="16" style="width:16px; border:0; outline:none; text-decoration:none; display:block; font-family:Arial,sans-serif; text-align:center; font-size:18px; color:#06090b;"/>  
                                            </td>
                                            <td valign="top" style="padding-top: 10px; font-family:Arial,sans-serif; text-align:left; font-size:18px; font-weight:300; color:#06090b; mso-line-height-rule:exactly; line-height:19px;">
                                                <font color="#06090b" style="color:#06090b; text-decoration:none; text-decoration:none !important;">{{ street_address }}, {{ city }}, {{ postal_code }}</font>
                                            </td>
                                        </tr>
                                        {{#if phone_number}}
                                            <tr><!-- ROW STARTS -->
                                                <td valign="top" width="29" style="width:29px; padding-top: 10px;">
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/car-gestion-system.appspot.com/o/email-images%2Fphone-solid.svg?alt=media&token=1d36f1df-1aa7-4b03-a2a7-fa45d952e866" alt="" border="0" width="16" style="width:16px; border:0; outline:none; text-decoration:none; display:block; font-family:Arial,sans-serif; text-align:center; font-size:118px; color:#06090b;"/>  
                                                </td>
                                                <td valign="top" style="padding-top: 10px; font-family:Arial,sans-serif; text-align:left; font-size:18px; font-weight:300; color:#06090b; mso-line-height-rule:exactly; line-height:19px;">
                                                    <a href="tel:{{ phone_number }}" target="_blank" style="color:#000001; text-decoration:none;"><font color="#000001" style="color:#000001; text-decoration:none;">{{ phone_number }}</font></a>
                                                </td>
                                            </tr>
                                        {{/if}}
                                    </table>
                                </td>
                            </tr><!-- INFO ENDS -->



                            <tr><!-- SOCIAL STARTS -->
                                <td style="padding-top:30px;">
                                    <table align="left" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                            <td valign="bottom" width="118" style="width:118px;">
                                                <a href="https://www.torontoautogroup.ca" target="_blank" style="color:#000001; text-decoration:none;"><font color="#000001" style="color:#000001; text-decoration:none;"><img src="https://firebasestorage.googleapis.com/v0/b/car-gestion-system.appspot.com/o/email-images%2Flogo%402x.jpg?alt=media&token=188f25f5-d277-4120-a1d2-537c8934e6a0" alt="Toronto Auto group" border="0" width="104" style="width:104px; border:0; outline:none; text-decoration:none; display:block; font-family:Arial,sans-serif; text-align:center; font-size:16px; color:#000001;"/></font></a>
                                            </td>
                                            <td valign="bottom" width="23" style="width:23px;">
                                                <a href="https://www.instagram.com/torontoautogroup" target="_blank" style="color:#000001; text-decoration:none;"><font color="#000001" style="color:#000001; text-decoration:none;"><img src="https://firebasestorage.googleapis.com/v0/b/car-gestion-system.appspot.com/o/email-images%2Ficon-instagram%402x.jpg?alt=media&token=5650446a-3f81-4c2b-8538-278e9a13d221" alt="In" border="0" width="16" style="width:16px; border:0; outline:none; text-decoration:none; display:block; font-family:Arial,sans-serif; text-align:center; font-size:16px; color:#000001;"/></font></a>
                                            </td>
                                            <td valign="bottom" width="8" style="width:8px;">
                                                <a href="https://www.facebook.com/TorontoAutoGroup" target="_blank" style="color:#000001; text-decoration:none;"><font color="#000001" style="color:#000001; text-decoration:none;"><img src="https://firebasestorage.googleapis.com/v0/b/car-gestion-system.appspot.com/o/email-images%2Ficon-facebook%402x.jpg?alt=media&token=00d38e3f-82a3-42c5-9ae3-f05a36687265" alt="Fb" border="0" width="8" style="width:8px; border:0; outline:none; text-decoration:none; display:block; font-family:Arial,sans-serif; text-align:center; font-size:16px; color:#000001;"/></font></a>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr><!-- SOCIAL ENDS -->



                            <tr><!-- FOOTER STARTS -->
                                <td style="padding-top:15px;  font-family:Arial,sans-serif; text-align:left; font-size:15px; font-weight:300; color:#817e7d; text-transform: uppercase; mso-line-height-rule:exactly; line-height:1.6;">
                                    Toronto &nbsp;&bull;&nbsp; Trenton &nbsp;&bull;&nbsp; Thunder Bay &nbsp;&bull;&nbsp; Oshawa &nbsp;&bull;&nbsp; Sudbury
                                </td>
                            </tr><!-- FOOTER ENDS -->
                        </table>
                    </td><!-- COLUMN_2 ENDS -->
                </tr>
            </table>
        </td>
    </tr>
    <!-- HEADER ENDS -->


    </table></td></tr><!--[if mso]></table></td></tr><![endif]--></table><!-- 600 ENDS -->
    <!-- WRAPPER ENDS -->
    <div style="display:none; white-space:nowrap; font:15px courier; color:#ffffff;">
    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    </div>
    </body>
    </html>
`;

module.exports = { source };
