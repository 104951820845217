import CommentIcon from "@mui/icons-material/Comment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { blue, green, grey } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import r from "routes";
import { buisnessRequestsCollection } from "services/firebase";

import styles from "./FollowUpRow.css";

const statusToColor = {
  inprogress: blue[500],
  complete: green[500],
  blank: grey[500],
};

const statusMenu = ["Blank", "In Progress", "Complete"];

const useStyles = makeStyles(styles);
function FollowUpRow({ currentUser, data }) {
  const classes = useStyles();
  const history = useHistory();

  const [status, setStatus] = useState(data.status);
  const [expanded, setExpanded] = useState(false);

  const currentStatusColor =
    statusToColor[status.replace(/\s/g, "").toLowerCase()];

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  function handleDetailsRedirection(id) {
    history.push(
      r.onBoardingRequest.details({
        onBoardingId: id,
      })
    );
  }

  async function handleStatusChange(event) {
    const newStatus = event.target.value;

    const ref = buisnessRequestsCollection.doc(data.id);
    await ref.set(
      {
        status: newStatus,
      },
      { merge: true }
    );

    setStatus(newStatus);
  }

  return (
    <Card className={classes.root}>
      <CardActions className={classes.actions}>
        <FormControl className={classes.formControl}>
          <Select
            variant="outlined"
            value={status}
            onChange={(event) => handleStatusChange(event)}
            inputProps={{
              classes: {
                root: classes.selectRoot,
                select: classes.select,
              },
            }}
            style={{
              minWidth: 150,
              paddingTop: 3,
              paddingBottom: 3,
              color: currentStatusColor,
              borderColor: currentStatusColor,
            }}
          >
            {statusMenu.map(function (option) {
              const localStatusColor =
                statusToColor[option.replace(/\s/g, "").toLowerCase()];
              return (
                <MenuItem
                  key={option}
                  value={option}
                  style={{
                    color: localStatusColor,
                    borderColor: localStatusColor,
                    paddingTop: 3,
                    paddingBottom: 3,
                  }}
                >
                  {option}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </CardActions>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {data.type}
        </Typography>
        <Typography variant="h5" component="h2">
          {data.title}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {data.description}
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          size="small"
          variant="outlined"
          onClick={() => handleDetailsRedirection(data.id)}
        >
          Learn More
        </Button>

        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          size="large"
        >
          <CommentIcon />
        </IconButton>

        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          size="large"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}

export default FollowUpRow;
