const picturesDescriptionConstant = [
  {
    name: "FRONT-DRIVER",
    photoURL: "",
    isDamage: false,
  },
  {
    name: "REAR-DRIVER",
    photoURL: "",
    isDamage: false,
  },
  {
    name: "REAR-PASSENGER",
    photoURL: "",
    isDamage: false,
  },
  {
    name: "FRONT-PASSENGER",
    photoURL: "",
    isDamage: false,
  },
  {
    name: "CLUSTER (STARTED)",
    photoURL: "",
    isDamage: false,
  },
  {
    name: "INTERIOR FRONT DRIVER",
    photoURL: "",
    isDamage: false,
  },
  {
    name: "INTERIOR REAR DRIVER",
    photoURL: "",
    isDamage: false,
  },
  {
    name: "MISC-DAMAGE 1",
    photoURL: "",
    isDamage: false,
  },
  {
    name: "MISC-DAMAGE 2",
    photoURL: "",
    isDamage: false,
  },
  {
    name: "OTHER",
    photoURL: "",
    isDamage: false,
  },
];
export default picturesDescriptionConstant;
