const initialValues = {
  isFromCSV: false,

  firstName: "",
  lastName: "",
  cellPhoneNumber: "",
  email: "",
  interestedAfterCOVID: "",
  comments: "",
  entryBy: "",
};

const yesNoConstants = [
  {
    value: "YES",
  },
  {
    value: "NO",
  },
];

export { initialValues, yesNoConstants };
