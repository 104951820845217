import MaterialTable from "@material-table/core";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Hidden,
} from "@mui/material";
import { useWindowResize } from "common/hooks/useWindowResize";
import MaterialTableResponsive, {
  MaterialTableTypesEnum,
} from "common/MaterialTableResponsive";
import PermissionWrapper from "common/PermissionWrapper";
import MD5 from "crypto-js/md5";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import r from "routes";
import { database } from "services/firebase";

export const RegisterALeadMode = {
  SEE: true,
  EDIT: false,
};

function RegisterALeadDashboard({ currentUser }) {
  const [data, setData] = useState();
  const { height } = useWindowResize();
  const history = useHistory();
  const location = useLocation();

  function handleRegisterALead(data, mode) {
    // eslint-disable-next-line new-cap
    const leadId = MD5(
      data.firstName + data.lastName + data.cellPhoneNumber
    ).toString();
    history.push(
      r.registerALead.modify({
        leadId: leadId,
      }),
      {
        mode: mode,
      }
    );
  }

  useEffect(() => {
    // check if the user is admin. Admin can see all the register lead
    if (currentUser.permission.customClaims.includes("admin")) {
      database
        .ref("registerALead")
        .once("value")
        .then(function (snapshot) {
          var obj = snapshot.val();
          var array = [];
          if (obj) {
            // Loop into the user
            Object.keys(obj).forEach(function (userID) {
              const lead = obj[userID];

              // Loop into the lead
              if (lead) {
                Object.keys(lead).forEach(function (leadKey) {
                  lead["userID"] = userID;
                  array.push(lead[leadKey]);
                });
              }
            });
          }
          setData(array);
        });
    } else {
      database
        .ref("registerALead/" + currentUser.uid)
        .once("value")
        .then(function (snapshot) {
          var obj = snapshot.val();
          var array = [];
          if (obj) {
            Object.keys(obj).forEach(function (key) {
              array.push(obj[key]);
            });
          }
          setData(array);
        });
    }
  }, [currentUser, location]);

  if (data === undefined) {
    return (
      <Box align="center" component="span" m={0}>
        <Container>
          <CircularProgress />
        </Container>
      </Box>
    );
  }
  return (
    <Box component="span" m={1}>
      <Container>
        <Box component="span" m={0}>
          <Hidden mdUp>
            <Box style={{ marginBottom: 20 }}></Box>
            <MaterialTableResponsive
              columns={[
                { title: "Entry time", field: "currentTime" },
                { title: "First Name", field: "firstName" },
                { title: "Last Name", field: "lastName" },
                { title: "Cell", field: "cellPhoneNumber" },
                { title: "Email", field: "email" },
                { title: "Entry By", field: "entryBy" },
              ]}
              data={data}
              title="Register a lead - Result"
              type={MaterialTableTypesEnum.REGISTER_A_LEAD}
              handleOnClick={handleRegisterALead}
            />
          </Hidden>
        </Box>

        <Hidden mdDown>
          <Box component="span" m={5}>
            <div style={{ maxWidth: "100%" }}>
              <MaterialTable
                options={{
                  search: true,
                  sorting: true,
                  draggable: false,
                  pageSize: 20,
                  maxBodyHeight: height - 260,
                }}
                columns={[
                  { title: "Entry time", field: "currentTime" },
                  { title: "First Name", field: "firstName" },
                  { title: "Last Name", field: "lastName" },
                  { title: "Cell", field: "cellPhoneNumber" },
                  { title: "Email", field: "email" },
                  { title: "Entry By", field: "entryBy" },
                  {
                    title: "",
                    field: "firstName",
                    render: (rowData) => (
                      <React.Fragment>
                        <Box display="flex">
                          <PermissionWrapper permission="admin">
                            <Button
                              size="small"
                              color="primary"
                              variant="contained"
                              style={{ marginRight: "5px" }}
                              onClick={() =>
                                handleRegisterALead(
                                  rowData,
                                  RegisterALeadMode.EDIT
                                )
                              }
                            >
                              <EditIcon fontSize="small" />
                            </Button>
                          </PermissionWrapper>
                          <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={() =>
                              handleRegisterALead(
                                rowData,
                                RegisterALeadMode.SEE
                              )
                            }
                          >
                            <VisibilityIcon fontSize="small" />
                          </Button>
                        </Box>
                      </React.Fragment>
                    ),
                  },
                ]}
                data={data}
                title=""
              />
            </div>
          </Box>
        </Hidden>
      </Container>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user,
});

export default connect(mapStateToProps)(RegisterALeadDashboard);
