import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import PermissionWrapper from "common/PermissionWrapper";
import SnackbarAlert from "common/SnackbarAlert";
import LogRocket from "logrocket";
import React, { useState } from "react";
import { connect } from "react-redux";

//import AccountingTools from "./AccountingTools";
import AdminTools from "./AdminTools";
import GeneralTools from "./GeneralTools";
import InventoryTools from "./InventoryTools";
import OperationsTools from "./OperationsTools";
import SalesTools from "./SalesTools";
import ServiceTools from "./ServiceTools";
import ShortcutsTools from "./ShortcutsTools";
//import TAGFinancialTools from "./TAGFinancialTools";
import styles from "./Tools.css.js";
import TransportTools from "./TransportTools";

const useStyles = makeStyles(styles);

function Tools({ currentUser }) {
  const classes = useStyles();
  const [openAlert, setOpenAlert] = useState(false);

  // Active LogRocket only after login
  if (process.env.REACT_APP_IS_LOCALHOST === "false" && currentUser) {
    LogRocket.init("din25v/car-gestion-system");
  }

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  return (
    <React.Fragment>
      <Container className={classes.root}>
        <Grid container alignItems="stretch" spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h5" gutterBottom>
              👋 Hi, {currentUser.displayName} !
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <ShortcutsTools
              handleAlert={handleOpenAlert}
              handleRedirect={() => {}}
              classes={classes}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <GeneralTools
              handleAlert={handleOpenAlert}
              handleRedirect={() => {}}
              classes={classes}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InventoryTools
              handleAlert={handleOpenAlert}
              handleRedirect={() => {}}
              classes={classes}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <SalesTools
              handleAlert={handleOpenAlert}
              handleRedirect={() => {}}
              classes={classes}
            />
          </Grid>

          {/*<Grid item xs={12} md={6}>
            <TAGFinancialTools
              handleAlert={handleOpenAlert}
              handleRedirect={() => {}}
              classes={classes}
            />
              </Grid>*/}

          <Grid item xs={12} md={6}>
            <ServiceTools
              handleAlert={handleOpenAlert}
              handleRedirect={() => {}}
              classes={classes}
            />
          </Grid>

          <PermissionWrapper permission={"admin"}>
            <Grid item xs={12} md={6}>
              <OperationsTools
                handleAlert={handleOpenAlert}
                handleRedirect={() => {}}
                classes={classes}
              />
            </Grid>
          </PermissionWrapper>

          <Grid item xs={12} md={12}>
            <TransportTools
              handleAlert={handleOpenAlert}
              handleRedirect={() => {}}
              classes={classes}
            />
          </Grid>

          {/*<Grid item xs={12} md={12}>
            <AccountingTools
              handleAlert={handleOpenAlert}
              handleRedirect={() => {}}
              classes={classes}
            />
            </Grid>*/}

          <PermissionWrapper permission={"admin"}>
            <Grid item xs={12} md={12}>
              <AdminTools
                handleAlert={handleOpenAlert}
                handleRedirect={() => {}}
                classes={classes}
              />
            </Grid>
          </PermissionWrapper>
        </Grid>

        <SnackbarAlert
          open={openAlert}
          onClose={() => setOpenAlert(false)}
          severity="error"
          message={
            "Sorry there is no tools to display. Ask your system administrator for more information"
          }
        />
      </Container>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user,
});

export default connect(mapStateToProps)(Tools);
