import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import styles from "./UserManagement.css.js";
import UserManagementDashboard from "./UserManagementDashboard";

const useStyles = makeStyles(styles);

export default function AdminPanel() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container className={classes.root}>
        <Grid container spacing={2}>
          <Grid item align="center" xs={12}>
            <UserManagementDashboard />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
