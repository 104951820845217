import { Divider, Grid, Typography } from "@mui/material";
import { FastField } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";

export default function StocksOptionsVehiculeForm() {
  return (
    <React.Fragment>
      <Grid item align="left" xs={12}>
        <Typography variant="h6">Vehicle</Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          component={TextField}
          name="stock"
          label="Stock"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          required
          component={TextField}
          name="vin"
          label="VIN"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          component={TextField}
          name="vehicle"
          label="Vehicle name"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          component={TextField}
          name="color"
          label="Color"
          variant="outlined"
        />
      </Grid>
    </React.Fragment>
  );
}
