import { Button, Divider, Grid, MenuItem, Typography } from "@mui/material";
import { FastField, Field } from "formik";
import { CheckboxWithLabel, TextField } from "formik-mui";
import React from "react";

import { dealerList } from "../constants";

export default function CovidLogBookForm({ isSubmitting, handleSubmit }) {
  return (
    <React.Fragment>
      <Grid item align="left" xs={12}>
        <Typography variant="h6">
          Thank you for helping us to keep our customers and employees safe.
          Please be ensured that your personal informations will not be used for
          any business/marketing purposes unless you instruct us to do so. Use
          the feedback feature if you need to bring anything to our attention
        </Typography>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <FastField
          fullWidth
          required
          component={TextField}
          name="firstName"
          label="First name"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <FastField
          fullWidth
          required
          component={TextField}
          name="lastName"
          label="Last name"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12}>
        <FastField
          fullWidth
          required
          component={TextField}
          name="phoneNumber"
          label="Phone number"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12}>
        <FastField
          fullWidth
          component={TextField}
          name="email"
          label="Email"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12}>
        <FastField
          fullWidth
          required
          disabled
          select
          component={TextField}
          name="dealer"
          label="Dealer"
          variant="outlined"
        >
          {dealerList.map((dealer) => (
            <MenuItem key={dealer} value={dealer}>
              {dealer}
            </MenuItem>
          ))}
        </FastField>
      </Grid>

      <Grid item xs={12}>
        <Field
          component={CheckboxWithLabel}
          type="checkbox"
          name="promotionalCheckbox"
          color="primary"
          indeterminate={false} // This is set to true be default in formik
          Label={{ label: "Please send me promotional offers" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          required
          component={CheckboxWithLabel}
          type="checkbox"
          name="symptomsCheckbox"
          color="primary"
          indeterminate={false} // This is set to true be default in formik
          Label={{
            label:
              "I don’t have any fever illness or other COVID-19 related symptoms, and I didn’t travelled outside of Canada within the last 14 days or in contact with someone that had a positive COVID-19 test result",
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          SEND
        </Button>
      </Grid>
    </React.Fragment>
  );
}
