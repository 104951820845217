import { CircularProgress } from "@mui/material";
import Container from "@mui/material/Container";
import makeStyles from "@mui/styles/makeStyles";
import final_formatted from "assets/Admin/final_formatted.csv";
import { GoogleApiWrapper, HeatMap, Map } from "google-maps-react";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";

import styles from "./AdminHeatMap.css.js";

const useStyles = makeStyles(styles);

// TODO Pass this in envrionnement variable
const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

function AdminHeatMap({ google }) {
  const classes = useStyles();
  const [heatMapData, setHeatMapData] = useState();
  const zoom = 5;
  const center = { lat: 45.0, lng: -90.0 };
  const gradient = [
    "rgba(0, 255, 255, 0)",
    "rgba(0, 255, 255, 1)",
    "rgba(0, 191, 255, 1)",
    "rgba(0, 127, 255, 1)",
    "rgba(0, 63, 255, 1)",
    "rgba(0, 0, 255, 1)",
    "rgba(0, 0, 223, 1)",
    "rgba(0, 0, 191, 1)",
    "rgba(0, 0, 159, 1)",
    "rgba(0, 0, 127, 1)",
    "rgba(63, 0, 91, 1)",
    "rgba(127, 0, 63, 1)",
    "rgba(191, 0, 31, 1)",
    "rgba(255, 0, 0, 1)",
  ];

  useEffect(() => {
    // TODO We will do this when you will have finish
    /*const getCSVURL = async () => {
      var storageRef = firebase
        .storage()
        .refFromURL(
          "gs://car-gestion-system.appspot.com/csv/final_formatted.csv"
        );

      const url = await storageRef.getDownloadURL();
      return url;
    };*/

    const getCSVURL = async () => {
      return final_formatted;
    };

    const fetchData = async () => {
      let coors = [];

      const url = await getCSVURL();

      Papa.parse(url, {
        download: true,
        header: true,
        delimiter: ",",
        skipEmptyLines: true,
        error: function (error) {
          console.error("error", error); // TODO Make a Snackbar in the future
          return false;
        },
        complete: function (results) {
          const infos = results.data;
          const length = infos.length;
          for (let i = 0; i < length; i++) {
            try {
              const di = JSON.parse(results.data[i].GEOCODE.replace(/'/g, '"'));
              coors.push({
                lat: di.geometry.location.lat,
                lng: di.geometry.location.lng,
              });
            } catch (error) {
              // TODO: make sure to know what causes the exception and process those address too
              delete infos[i];
            }
          }

          setHeatMapData(coors);
        },
      });
    };

    fetchData();
  }, []);

  if (heatMapData === undefined) {
    return (
      <Container className={classes.root}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container className={classes.root}>
      <Map google={google} initialCenter={center} zoom={zoom}>
        <HeatMap
          gradient={gradient}
          opacity={1}
          positions={heatMapData}
          radius={5} // TODO Ajuste the heatmap
        />
      </Map>
    </Container>
  );
}

// eslint-disable-next-line new-cap
export default GoogleApiWrapper({
  apiKey: apiKey,
  libraries: ["visualization"],
})(AdminHeatMap);
