import Image from "@jy95/material-ui-image";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo from "assets/logo.png";
import React from "react";

import styles from "./UnAutorize.css";
const useStyles = makeStyles(styles);

function UnAutorize() {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Paper className={classes.paper} elevation={3} padding="dense">
        <Grid container spacing={3}>
          <Grid item align="center" xs={12}>
            <Image
              src={logo}
              imageStyle={{ height: "auto", width: "300px" }}
              style={{
                paddingTop: "160px",
                width: "300px",
              }}
            />
            <Typography variant="h4">UnAuthorize</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">
              Sorry there is no tools to display. Ask your system administrator
              for more information
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
export default UnAutorize;
