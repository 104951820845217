import CssBaseline from "@mui/material/CssBaseline";
import Home from "pages/Home";
import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

export default function App() {
  return (
    <Router>
      <CssBaseline />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <Route path={"/"} component={Home} />
    </Router>
  );
}
