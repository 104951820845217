import {
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  Typography,
} from "@mui/material";
import MaterialUIFormikDatePicker from "common/MaterialUIFormikDatePicker";
import { FastField } from "formik";
import { RadioGroup, TextField } from "formik-mui";
import React from "react";

import {
  numberOfKeys,
  startConstants,
  vehicleCurrentLocationConstants,
} from "./constants.js";

export default function StocksOptionsValidationForm({ isSubmitting }) {
  return (
    <React.Fragment>
      <Grid item align="left" xs={12}>
        <Typography variant="h6">Validation</Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          component={TextField}
          name="vehicleRealColor"
          label="Vehicle real color"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          component={TextField}
          name="currentKM"
          label="Current KM"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MaterialUIFormikDatePicker
          name="lastBatteryCharge"
          label="Last Battery Charge"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MaterialUIFormikDatePicker name="lastDriven" label="Last Driven" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          component={TextField}
          name="packages"
          label="Packages"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MaterialUIFormikDatePicker
          name="pictureDoneDate"
          label="Picture Done Date"
        />
      </Grid>
      <Grid item xs={12} align="left" sm={4}>
        <InputLabel>Vehicle Current Location</InputLabel>
        <FastField
          component={RadioGroup}
          name="vehicleCurrentLocation"
          label="Vehicle Current Location"
          variant="outlined"
        >
          {vehicleCurrentLocationConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={2}>
        <InputLabel>Need Heavy Recon</InputLabel>
        <FastField
          component={RadioGroup}
          name="vehicleNeedHeavyRecon"
          label="Vehicle Need Heavy Recon"
          variant="outlined"
        >
          {startConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={2}>
        <InputLabel>Start</InputLabel>
        <FastField
          component={RadioGroup}
          name="start"
          label="Start"
          variant="outlined"
        >
          {startConstants.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} align="left" sm={4}>
        <InputLabel>How many keys?</InputLabel>
        <FastField
          component={RadioGroup}
          name="How many keys?"
          label="How many keys?"
          variant="outlined"
        >
          {numberOfKeys.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={isSubmitting}
              control={<Radio disabled={isSubmitting} />}
            />
          ))}
        </FastField>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FastField
          fullWidth
          component={TextField}
          name="notes"
          label="Notes"
          multiline
          rows={4}
          variant="outlined"
        />
      </Grid>
    </React.Fragment>
  );
}
