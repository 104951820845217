import { CircularProgress, Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { buisnessRequestsCollection } from "services/firebase";

import FollowUpRow from "./FollowUpRow";

function FollowUp({ currentUser }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const requests = await buisnessRequestsCollection.get();

      let requestArray = [];
      requests.forEach((request) => {
        const object = {
          id: request.id,
          ...request.data(),
        };
        requestArray.push(object);
      });

      setData(requestArray);
    };

    fetchData();
  }, []);

  if (data === null) {
    return (
      <Container>
        <Grid item align="center">
          <CircularProgress />
        </Grid>
      </Container>
    );
  }

  return (
    <Container>
      <Grid container spacing={4}>
        {data.map(function (data) {
          return (
            <Grid key={data.id} item xs={12}>
              <FollowUpRow data={data} currentUser={currentUser} />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user,
});

export default connect(mapStateToProps)(FollowUp);
