const initialValues = {
  isFromCSV: false,

  // Basic Info
  firstName: "",
  lastName: "",
  address: "",
  birthdate: null,
  gender: "",
  workForTAG: "",
  previousEmployer: "",

  // Position details
  hiringManager: "",
  workingLocation: "",
  position: "",
  payParameters: "",
  desiredStartDate: null,

  // Ressources needed
  emailAddress: "",
  domainAccess: "",
  DMSAccess: "",
  workSpace: "",
  dedicatedPhoneNumber: "",
  dedicated800Number: "",
  CRM: "",
  remoteAccess: "",
  dealerTrack: "",
  vAuto: "",
  alarmSystemCode: "",

  // Ressources status
  // TODO recheck this if I can have other system. I don't like the implementation
  "status:emailAddress": "BLANK",
  "status:domainAccess": "BLANK",
  "status:DMSAccess": "BLANK",
  "status:workSpace": "BLANK",
  "status:dedicatedPhoneNumber": "BLANK",
  "status:dedicated800Number": "BLANK",
  "status:CRM": "BLANK",
  "status:remoteAccess": "BLANK",
  "status:dealerTrack": "BLANK",
  "status:vAuto": "BLANK",
  "status:alarmSystemCode": "BLANK",
};

const gender = [
  {
    value: "MAN",
  },
  {
    value: "WOMAN",
  },
  {
    value: "OTHER",
  },
];

const yesNoConstants = [
  {
    value: "YES",
  },
  {
    value: "NO",
  },
];

const payParameters = [
  {
    value: "Payroll",
  },
  {
    value: "Invoice",
  },
];

const workspacesValues = [
  {
    value: "Please supply the workspace designation",
  },
  {
    value: "100% OFFSITE",
  },
];

const followUpValues = [
  {
    value: "BLANK",
  },
  {
    value: "IN PROGRES ",
  },
  {
    value: "COMPLETED",
  },
];

export {
  initialValues,
  gender,
  yesNoConstants,
  payParameters,
  workspacesValues,
  followUpValues,
};
