import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import BackDropLoading from "common/BackDropLoading";
import SnackbarAlert from "common/SnackbarAlert";
import MD5 from "crypto-js/md5";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { database } from "services/firebase";
import * as Yup from "yup";

import { initialValues } from "./constants";
import {
  StocksOptionsOptionsForm,
  StocksOptionsOthersForm,
  StocksOptionsPowerTrainForm,
  StocksOptionsValidationForm,
  StocksOptionsVehiculeForm,
} from "./StockOptionsForm";
import styles from "./StocksOptions.css.js";

const useStyles = makeStyles(styles);

function RegisterLead() {
  const classes = useStyles();
  const { stockId } = useParams();
  const [errorMessage, setMessageError] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState({});
  const [data, setData] = useState();

  const validateValues = Yup.object().shape({
    stock: Yup.string().required(),
  });

  useEffect(() => {
    database
      .ref("inventorySync/" + stockId)
      .once("value")
      .then(function (snapshot) {
        const d = snapshot.val();

        if (d) {
          if (d.lastBatteryCharge === "" || d.lastBatteryCharge === "  /  /") {
            d.lastBatteryCharge = null;
          }
          if (d.lastDriven === "" || d.lastDriven === "  /  /") {
            d.lastDriven = null;
          }
          if (d.pictureDoneDate === "" || d.pictureDoneDate === "  /  /") {
            d.pictureDoneDate = null;
          }

          setData(d);
        } else {
          setData(initialValues);
        }
      });
  }, [stockId]);

  if (data === undefined) {
    return <BackDropLoading open={true} />;
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={data}
        onSubmit={save}
        validationSchema={validateValues}
      >
        {({ values, isSubmitting }) => (
          <Form autoComplete="on">
            <Container className={classes.root}>
              <Grid container spacing={4}>
                <Grid item align="center" xs={12}>
                  <Typography variant="h4">
                    Stock options & condition update
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  {errorMessage ? (
                    <Paper className={classes.error}>
                      <Typography variant="body1">{errorMessage}</Typography>
                    </Paper>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </Grid>

                <StocksOptionsVehiculeForm></StocksOptionsVehiculeForm>

                <StocksOptionsValidationForm
                  isSubmitting={isSubmitting}
                ></StocksOptionsValidationForm>

                <Grid item xs={12} sm={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    SEND
                  </Button>
                </Grid>

                <StocksOptionsPowerTrainForm
                  isSubmitting={isSubmitting}
                ></StocksOptionsPowerTrainForm>

                <StocksOptionsOptionsForm
                  isSubmitting={isSubmitting}
                ></StocksOptionsOptionsForm>

                <StocksOptionsOthersForm
                  isSubmitting={isSubmitting}
                ></StocksOptionsOthersForm>

                <Grid item xs={12} sm={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    SEND
                  </Button>
                  <BackDropLoading open={isSubmitting} />

                  <SnackbarAlert
                    open={snackbarMessage.message !== undefined}
                    message={snackbarMessage.message}
                    severity={snackbarMessage.severity}
                    onClose={() => setSnackbarMessage({})}
                  />
                </Grid>
              </Grid>
            </Container>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );

  async function save(
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) {
    setSubmitting(true);

    if (values.lastDriven) {
      values.lastDriven = values.lastDriven.toString();
    } else {
      values.lastDriven = "";
    }

    if (values.lastBatteryCharge) {
      values.lastBatteryCharge = values.lastBatteryCharge.toString();
    } else {
      values.lastBatteryCharge = "";
    }

    if (values.pictureDoneDate) {
      values.pictureDoneDate = values.pictureDoneDate.toString();
    } else {
      values.pictureDoneDate = "";
    }
    sendEmail(values, setSubmitting);
    saveInDataBase(values, setSubmitting, resetForm);
  }

  function sendEmail(values, setSubmitting) {
    setSubmitting(true);
    let config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    let url =
      "https://us-central1-car-gestion-system.cloudfunctions.net/sendEmailData";
    if (process.env.REACT_APP_IS_LOCALHOST === "true") {
      url =
        "https://us-central1-car-gestion-system-dev.cloudfunctions.net/sendEmailData";
    }

    axios
      .post(url, values, config)
      .then((res) => {
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
        console.error(err);
      });
  }

  function saveInDataBase(values, setSubmitting, resetForm) {
    const stockId = values.stock;
    const vin = values.vin;

    values.isEmailSend = false;

    database
      // eslint-disable-next-line new-cap
      .ref("carInventory/" + MD5(stockId + vin))
      .set(values, function (error) {
        if (error) {
          console.error(error);
          setSubmitting(false);
          setMessageError(error);
        } else {
          setSnackbarMessage({ message: "Car saved !", severity: "success" });
          setSubmitting(false);
          window.scrollTo(0, 0);
          resetForm();
        }
      });
  }
}
export default RegisterLead;
